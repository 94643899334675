import { Employee, RawEmployee } from "@/orders";

export const mockRawEmployee = (): RawEmployee[] => [
  {
    id: "aaaaaaaa-eeee-cccc-ee10-000000000001",
    code: "3334",
    name: "Rodrigo Manuel",
    lastName: "Cansado Simoes",
    function: "Chofer",
    urlPhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    urlSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imagePhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imageSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    workBadge: "000678",
    validationDocumentType: "01",
    validationDocumentValue: "09845673",
  },
  {
    id: "aaaaaaaa-eeee-cccc-ee10-000000000002",
    code: "4456",
    name: "Alejandro Franco",
    lastName: "Martinez Simoes",
    function: "Porta Valor",
    urlPhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    urlSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imagePhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imageSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    workBadge: "000234",
    validationDocumentType: "01",
    validationDocumentValue: "76534562",
  },
  {
    id: "aaaaaaaa-eeee-cccc-ee10-000000000003",
    code: "7899",
    name: "Wilfredo Luis",
    lastName: "Ferreyro Campos",
    function: "Custodio",
    urlPhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    urlSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imagePhoto: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    imageSignature:
      "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0000.png",
    workBadge: "000456",
    validationDocumentType: "01",
    validationDocumentValue: "64578903",
  },
];

export const mockEmployee = (data: RawEmployee[] = mockRawEmployee()): Employee[] =>
  data.map((item) => new Employee(item));
