
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PageRequest2 } from "@/services";
import { TransactionCosmos } from "@/entities";
import VueFilters from "@/vue-filters";
import {
  FieldPTable,
  FieldPTableType,
  iniPaginationPTable,
  PaginationPTableType,
  PTableType,
} from "@/common/components";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { customDebounce } from "@/common/utils";
import { IField } from "@/pages/transactionsCosmos/transactionsCosmosPage.type";
import VueMethods from "@/vue-methods";
import PPagination from "@/common/components/look/PPagination/PPagination.vue";
import { TransactionCosmosItem } from "@/entities/transactionCosmos/transactionCosmosItem";
import PButton from "@/common/components/look/PButton/PButton.vue";

@Component({
  name: "machine-grid",
  components: { PPagination, PButton },
  mixins: [VueFilters, DateTimeMixin, VueMethods],
})
export class TransactionCosmosGrid extends Vue {
  @Prop({ required: true }) download!: {
    fields: { field: string; label: string }[];
    translations: { field: string; translation: { [key: string]: string } }[];
    download: boolean;
  };
  @Watch("download", { immediate: true, deep: true })
  onChangeDownload(): void {
    if (this.download.download) this.downloadFileExport();
  }
  @Prop({ required: true }) config!: PTableType;
  @Watch("config", { immediate: true, deep: true })
  onChangeConfig(): void {
    this.pagination.pageSize = this.config.pagination.pageSize;
    this.updatePaginationBack();
  }
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIMachineFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    customDebounce(() => this.getTransactions(), 350);
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (this.sort.sortField != "") {
      if (
        this.paginationBack.sortFields[0].direction != (this.sort.sortDesc ? "DESC" : "ASC") ||
        this.paginationBack.sortFields[0].field != this.sort.sortField
      ) {
        this.paginationBack.sortFields[0].direction = this.sort.sortDesc ? "DESC" : "ASC";
        this.paginationBack.sortFields[0].field = this.sort.sortField;
        this.updateParams();
      }
    }
  }
  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "transactionDate",
  };
  filters_field: Record<string, unknown> = {};
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = this.pageable(this.pagination.pageSize);
  mapFilters: Record<string, string> = {
    servicePointIds: "servicePointIds",
    transactionDateEnd: "transactionDateEnd",
    transactionDateStart: "transactionDateStart",
    transactionTypes: "transactionTypes",
  };
  mapFields: Record<string, string> = {
    teller: "teller.teller",
    tellerName: "teller.name",
    tellerLogin: "teller.login",
  };
  transactionsItems: TransactionCosmosItem[] = [];
  paginationBack: PageRequest2 = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortFields: [
      {
        field: this.params["sortField"] as string,
        direction: this.params["sortWay"] as string,
      },
    ],
    filters: {},
  };

  camelCaseToWords(s: string) {
    if (!s) return s;
    const result = s.replace(/([A-Z])/g, " $1");
    return result
      .split(" ")
      .join("_")
      .toUpperCase()
      .replace(".", "_")
      .replace("AMOUNT_", "")
      .replace("DEVICE_DETAILS_", "");
  }

  mapIMachineFilterToRecord(): void {
    this.filters_field = {};
    if (!this.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!this.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field[this.mapFilters[key]] = this.filters[key];
        }
      });
    }
    this.updateParams();
  }

  viewTransation(item: TransactionCosmosItem): void {
    this.$emit("viewDetail", item);
  }

  updateParams(): void {
    this.params = this.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.camelCaseToWords(this.paginationBack.sortFields[0].field),
      this.paginationBack.sortFields[0].direction,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async getTransactions(): Promise<void> {
    if (!this.isObjectEmpty(this.params["filters"])) {
      this.transactionsItems = [];
      this.$services.transactions.fetchTransactionsCosmos(this.params).then((data) => {
        data.results.forEach((value) => {
          let multilines = false;
          while (value.transactionDetails.length > 0) {
            const item = new TransactionCosmosItem(value, multilines);
            value.transactionDetails.splice(0, 1);
            multilines = value.transactionDetails.length > 0;
            this.transactionsItems.push(item);
          }
        });
        this.pagination.totalElements = data.totalResults;
        this.$emit("numTransactions", this.transactionsItems);
      });
    }
  }

  downloadFileExport(): void {
    console.log("DOWNLOAD");
    const requestBody = { ...this.params };
    delete requestBody.limit;
    delete requestBody.offset;
    requestBody["fields"] = this.download.fields;
    requestBody["translations"] = this.download.translations;
    this.$services.transactions.fetchTransactionsCosmosFileExport(requestBody).then((file: File) => {
      this.$emit("fileDownloaded");
      const aElement = document.createElement("a");
      const url = URL.createObjectURL(file);
      aElement.href = url;
      aElement.download = file.name;
      aElement.click();
      window.URL.revokeObjectURL(url);
    });
  }

  isHidden(fi: FieldPTable): boolean {
    return fi.type == FieldPTableType.HIDDEN;
  }
  isShowable(fi: FieldPTable): boolean {
    if (this.isHidden(fi)) return false;
    return fi.show;
  }

  mapUserPreferenceKeysToTableKeys(key: string): string {
    if (this.mapFields[key]) {
      return this.mapFields[key];
    }
    return key;
  }

  get fields(): IField[] {
    const fields: IField[] = [];
    this.config.fields.forEach((value) => {
      if (this.isShowable(value)) {
        fields.push({
          key: value.key,
          label: value.label ? value.label : "",
          sortable: value.key != "actions",
          thClass: "table-header",
          tdClass: "table-cell-first table-cell",
          visible: true,
          aux: true,
        });
      }
    });
    return fields;
    // return [
    //   {
    //     key: "actions",
    //     label: "",
    //     sortable: false,
    //     thClass: "table-header table-header-checkbox",
    //     tdClass: "table-cell-first table-cell",
    //     visible: true,
    //     aux: true,
    //   },
    //   {
    //     key: "transactionDate",
    //     label: this.$t("transactions.field.transactionDate") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell-first table-cell",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "accountingDate",
    //     label: this.$t("transactions.field.accountingDate") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "actualId",
    //     label: this.$t("transactions.field.actualId") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "collectionId",
    //     label: this.$t("transactions.field.collectionId") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "servicePoint.code",
    //     label: this.$t("transactions.field.deviceId") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "servicePoint.name",
    //     label: this.$t("transactions.field.servicePointName") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "entity.name",
    //     label: this.$t("transactions.field.entityName") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "teller.teller",
    //     label: this.$t("transactions.field.tellerId") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "teller.login",
    //     label: this.$t("transactions.field.tellerLogin") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "teller.name",
    //     label: this.$t("transactions.field.tellerName") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionType",
    //     label: this.$t("transactions.field.transactionType") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionDetails.origin",
    //     label: this.$t("transactions.field.origin") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionDetails.amount.currency",
    //     label: this.$t("transactions.field.currency") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionDetails.amount.numItems",
    //     label: this.$t("transactions.field.numItems") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionDetails.amount.total",
    //     label: this.$t("transactions.field.amount") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "delegationCode",
    //     label: this.$t("transactions.field.delegation") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "channel",
    //     label: this.$t("transactions.field.channel") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "receiptNumber",
    //     label: this.$t("transactions.field.receiptNumber") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionDetails.amount.barCodes",
    //     label: this.$t("transactions.field.barCodes") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "strapSealCode",
    //     label: this.$t("transactions.field.strapSealCode") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "systemEntryDate",
    //     label: this.$t("transactions.field.systemEntryDate") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionRef",
    //     label: this.$t("transactions.field.transactionRef") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    //   {
    //     key: "transactionInfo",
    //     label: this.$t("transactions.field.transactionInfo") as string,
    //     sortable: true,
    //     class: "",
    //     thClass: "table-header",
    //     tdClass: "table-cell table-cell-middle",
    //     visible: true,
    //     aux: false,
    //     code: "",
    //   },
    // ];
  }
}

export default TransactionCosmosGrid;
