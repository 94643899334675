import { Denomination } from "@/orders/entities/denomination";
import { RawRequestedDenomination } from "@/orders/entities";

export class RequestedDenomination {
  readonly quantity: number;
  readonly total: number;
  readonly denomination: Denomination;

  constructor(data: RawRequestedDenomination) {
    this.quantity = data.quantity;
    this.total = data.total;
    this.denomination = new Denomination(data.denomination);
  }
}

export interface CreateRequestDenomination {
  denominationId: string;
  quantity: number;
}
