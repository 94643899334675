import { RawCenter } from "@/entities/center/center.types";

export class Center {
  readonly id: string;
  readonly code: string;
  readonly name: string;

  constructor(data: RawCenter | null) {
    this.id = data?.id || "";
    this.code = data?.code || "";
    this.name = data?.name || "";
  }
}
