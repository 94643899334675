import { RawAmountCosmos } from "@/entities/transactionCosmos/amountCosmos/amountCosmos.types";
import { AmountCosmos } from "@/entities/transactionCosmos/amountCosmos/amountCosmos";

export const mockRawAmountCosmos = (): RawAmountCosmos => {
  return {
    currency: "string",
    total: 0,
    numItems: 0,
    isValid: true,
    barCodes: ["string"],
  };
};

export const mockAmountCosmos = (data: RawAmountCosmos = mockRawAmountCosmos()): AmountCosmos => new AmountCosmos(data);
