
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "mini-label",
})
export class PMiniLabel extends Vue {
  @Prop({ required: false, default: "8px" }) readonly fontSize!: string;

  calcStyle(): string {
    return "font-size: " + this.fontSize;
  }

  constructor() {
    super();
  }
}
export default PMiniLabel;
