import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";

const CONNECTION_STRING = process.env.VUE_APP_APPLICATION_INSIGHTS_CONNECTION_STRING as string;
const ROLE_NAME = require("@/../package.json").name;
const VERSION = require("@/../package.json").version;

if (CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: CONNECTION_STRING.replace("\\", "") // Replace Jenkins slashes compatible with helm
        .replace("/\\", "/"), // Replace slashes for IngestionEndpoint
      cookieCfg: {
        enabled: false,
      },
    },
  });

  const telemetryInitializer = (envelope: ITelemetryItem) => {
    if (envelope.tags) {
      envelope.tags["ai.cloud.role"] = ROLE_NAME;
      envelope.tags["ai.application.ver"] = VERSION;
    }
  };

  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
}
