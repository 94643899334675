import { LegacyCodes, RawLegacyCodes } from "@/entities";

export const mockRawLegacyCodes = (): RawLegacyCodes => {
  return {
    actualId: "string",
    countryId: "string",
    customerId: "string",
    agencyId: "string",
    servicePtId: "string",
    deviceId: "string",
    delegacion: "string",
    teller: "string",
  };
};

export const mockLegacyCodes = (data: RawLegacyCodes = mockRawLegacyCodes()): LegacyCodes => new LegacyCodes(data);
