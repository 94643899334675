export interface PFormSelectNarrowTranslations {
  [key: string]: string;
  textSelectAnOption: string;
}
export interface LanguageDatepicker {
  language: string;
  translations: PFormSelectNarrowTranslations;
}
export const translations: LanguageDatepicker[] = [
  {
    language: "es",
    translations: {
      textSelectAnOption: "Seleccione una opción",
    },
  },
  {
    language: "en",
    translations: {
      textSelectAnOption: "Select an option",
    },
  },
  {
    language: "pt",
    translations: {
      textSelectAnOption: "Selecione uma opção",
    },
  },
  {
    language: "de",
    translations: {
      textSelectAnOption: "Wähle eine Option",
    },
  },
];
