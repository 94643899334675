/* eslint-disable @typescript-eslint/no-explicit-any */
export interface iTranslationsPPersonalizeTable {
  [key: string]: any;
  apply: string;
  cancel: string;
  orderTitle: string;
  fieldsTitle?: string;
  actions?: string;
}
export interface iLanguagePPersonalizeTable {
  language: string;
  translations: iTranslationsPPersonalizeTable;
}
export const translations: iLanguagePPersonalizeTable[] = [
  {
    language: "es",
    translations: {
      apply: "Aplicar",
      cancel: "Reiniciar",
      orderTitle: "Orden columnas",
      actions: "Acciones",
      selectAll: "Todos",
      min: "Limpiar"
    },
  },
  {
    language: "en",
    translations: {
      apply: "Apply",
      cancel: "Reset",
      orderTitle: "Column order",
      actions: "Actions",
      selectAll: "All",
      min: "Clear"
    },
  },
  {
    language: "pt",
    translations: {
      apply: "Aplicar",
      cancel: "Reiniciar",
      orderTitle: "Ordem das colunas",
      actions: "Ações",
      selectAll: "Todos",
      min: "Limpar"
    },
  },
  {
    language: "de",
    translations: {
      apply: "Anwenden",
      cancel: "Zurücksetzen",
      orderTitle: "Spaltenreihenfolge",
      actions: "Aktionen",
      selectAll: "Alle",
      min: "Löschen"
    },
  },
];
