
import PDropdown from "@/orders/components/Dropdown/Dropdown.vue";
import Utils from "../../Commons/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Commodity, Currency, OrderDTO, OrderPickupData } from "@/orders";
import { RequestedCurrencyData } from "@/orders/entities/requestedCurrency";
import Icon from "@/orders/components/Icon/index.vue";
import { IconX } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  components: {
    Icon,
    IconX,
    PDropdown,
  },
})
export default class StepPickup extends Vue {
  @Getter("getCommodities") commodities!: Commodity[];
  @Prop({ required: true }) value!: OrderPickupData;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  currencies: Currency[] = [];
  allCurrencies: Currency[] = [];
  currencyCode = "";
  quantity = 0;
  countTotalPickup = 0;
  comments = "";
  divisasTotales: RequestedCurrencyData[] = [];
  requestedCurrencies: RequestedCurrencyData[] = [];
  isChecked = false;
  addCurrencies = false;

  @Watch("requestedCurrencies", { deep: true })
  onRequestedCurrenciesChange() {
    this.onChangeQuantity();
  }

  onChangeQuantity(): void {
    this.divisasTotales = [];
    const sumasPorDivisa: any = {};
    this.requestedCurrencies.forEach((item) => {
      const code = item.currency.code;
      if (!sumasPorDivisa[code]) {
        sumasPorDivisa[code] = 0;
      }
      sumasPorDivisa[code] += Number(item.quantity);
    });

    Object.keys(sumasPorDivisa).forEach((code) => {
      const currency = this.allCurrencies.find((c) => c.code === code);
      if (currency) {
        this.divisasTotales.push({
          currency: currency,
          quantity: sumasPorDivisa[code],
        });
      }
    });

    this.value.requestedCurrencies = this.requestedCurrencies;
  }

  async created() {
    this.allCurrencies = await this.$services.order.fetchOrderCurrencies(this.notChangeOrder.country.code);
    this.currencies = this.allCurrencies;
    this.setupCurrencies();
  }

  setupCurrencies() {
    if (this.getPrincipalCurrency()) {
      this.currencyCode = this.getPrincipalCurrency();
      const newRequestedCurrencyData: RequestedCurrencyData = {
        currency: this.currencies.find((currency) => currency.code === this.currencyCode) as Currency,
        quantity: 0,
      };
      this.requestedCurrencies.push(newRequestedCurrencyData);
    }
  }

  getPrincipalCurrency(): string {
    const foundCurrency = this.currencies.find((currency) => currency.principalCurrency === true);
    return foundCurrency ? foundCurrency.code : "";
  }

  onChangeCheckUndeclared(): void {
    this.isChecked = !this.isChecked;
    if (!this.isChecked) {
      this.currencyCode = this.getPrincipalCurrency();
    } else {
      this.currencyCode = "";
      this.countTotalPickup = 0;
      this.quantity = 0;
    }
  }

  currencyFormat(amount: number) {
    return Utils.currencyFormat(amount);
  }

  get formatCurrencies(): string[] {
    return this.currencies.map((currency) => currency.code);
  }

  showCurrencies() {
    this.addCurrencies = true;
    this.updateAvailableCurrencies();
  }

  addNewItem() {
    if (this.currencyCode === "Seleccionar" || this.quantity === 0 || this.value.undeclaredAmount) {
      return;
    }
    const newRequestedCurrencyData: RequestedCurrencyData = {
      currency: this.currencies.find((currency) => currency.code === this.currencyCode) as Currency,
      quantity: this.quantity,
    };
    const exist = this.requestedCurrencies.find(
      (item) => item.currency.code === newRequestedCurrencyData.currency.code
    );
    if (exist) {
      const obj = this.requestedCurrencies[this.requestedCurrencies.indexOf(exist)];
      obj.quantity = Number(newRequestedCurrencyData.quantity) + Number(obj.quantity);
    } else {
      this.requestedCurrencies.push(newRequestedCurrencyData);
    }
    this.updateAvailableCurrencies();
    this.resetData();
  }

  onDeleteCurrencies(currencyCode: string) {
    this.requestedCurrencies = this.requestedCurrencies.filter((item) => item.currency.code !== currencyCode);
    const deletedCurrency = this.allCurrencies.find((currency) => currency.code === currencyCode);
    if (deletedCurrency) {
      this.currencies.push(deletedCurrency);
    }
    this.updateAvailableCurrencies();
  }

  updateAvailableCurrencies() {
    const requestedCurrencyCodes = new Set(this.requestedCurrencies.map((item) => item.currency.code));
    this.currencies = this.allCurrencies.filter((currency) => !requestedCurrencyCodes.has(currency.code));
  }

  resetData(): void {
    this.currencyCode = "Seleccionar";
    this.quantity = 0;
  }
}
