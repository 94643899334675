import { IRatingType, RatingType, RawRating } from "@/orders/entities";

export class Rating {
  readonly id: string;
  readonly ratingType: RatingType;
  readonly comments: string;

  constructor(data: RawRating) {
    this.id = data.id;
    this.ratingType = new RatingType(data.ratingType);
    this.comments = data.comments;
  }
}

export interface IRating {
  id: string;
  ratingType: IRatingType;
  comments: string;
}

export interface ICreateRating {
  orderId: string;
  ratingTypeId: string;
  comments: string;
}
