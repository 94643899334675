import { RawLogicCashToday } from "./logicCashToday.types";
import { LogicCashToday } from "./logicCashToday";

export const mockRawLogicCashTodays = (): RawLogicCashToday[] => [
  {
    aggregateId: "1ac1eac9-90b3-40e7-8b85-007db065aabc",
    name: "ZARA Lanzarote - Corner A",
    isEarlyValue: false,
    centerCode: "654845",
    centerName: "Tienda de Tenerife",
  },
];

export const mockLogicCashTodays = (data: RawLogicCashToday[] = mockRawLogicCashTodays()): LogicCashToday[] =>
  data.map((item) => new LogicCashToday(item));
