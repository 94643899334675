export interface TranslationsDatepicker {
  [key: string]: string;
  Mo: string;
  Tu: string;
  We: string;
  Th: string;
  Fr: string;
  Sa: string;
  Su: string;
  month_1: string;
  month_2: string;
  month_3: string;
  month_4: string;
  month_5: string;
  month_6: string;
  month_7: string;
  month_8: string;
  month_9: string;
  month_10: string;
  month_11: string;
  month_12: string;
}
export interface LanguageDatepicker {
  language: string;
  translations: TranslationsDatepicker;
}
export const translations: LanguageDatepicker[] = [
  {
    language: "es",
    translations: {
      Mo: "Lu",
      Tu: "Ma",
      We: "Mi",
      Th: "Ju",
      Fr: "Vi",
      Sa: "Sa",
      Su: "Do",
      month_1: "Enero",
      month_2: "Febrero",
      month_3: "Marzo",
      month_4: "Abril",
      month_5: "Mayo",
      month_6: "Junio",
      month_7: "Julio",
      month_8: "Agosto",
      month_9: "Septiembre",
      month_10: "Octubre",
      month_11: "Noviembre",
      month_12: "Diciembre",
    },
  },
  {
    language: "en",
    translations: {
      Mo: "Mo",
      Tu: "Tu",
      We: "We",
      Th: "Th",
      Fr: "Fr",
      Sa: "Sa",
      Su: "Su",
      month_1: "January",
      month_2: "February",
      month_3: "March",
      month_4: "April",
      month_5: "May",
      month_6: "June",
      month_7: "July",
      month_8: "August",
      month_9: "September",
      month_10: "October",
      month_11: "November",
      month_12: "December",
    },
  },
  {
    language: "pt",
    translations: {
      Mo: "Seg",
      Tu: "Ter",
      We: "Qua",
      Th: "Qui",
      Fr: "Sex",
      Sa: "Sáb",
      Su: "Dom",
      month_1: "Janeiro",
      month_2: "Fevereiro",
      month_3: "Março",
      month_4: "Abril",
      month_5: "Maio",
      month_6: "Junho",
      month_7: "Julho",
      month_8: "Agosto",
      month_9: "Setembro",
      month_10: "Outubro",
      month_11: "Novembro",
      month_12: "Dezembro",
    },
  },
  {
    language: "de",
    translations: {
      Mo: "Mo",
      Tu: "Di",
      We: "Mi",
      Th: "Do",
      Fr: "Fr",
      Sa: "Sa",
      Su: "So",
      month_1: "Januar",
      month_2: "Februar",
      month_3: "März",
      month_4: "April",
      month_5: "Mai",
      month_6: "Juni",
      month_7: "Juli",
      month_8: "August",
      month_9: "September",
      month_10: "Oktober",
      month_11: "November",
      month_12: "Dezember",
    },
  },
];
