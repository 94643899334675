import { Contract, RawCommodity } from "@/orders/entities";

export class Commodity {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly description: string;
  readonly citProductCode: string;
  readonly contract?: Contract;
  constructor(data: RawCommodity) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.description = data.description;
    this.citProductCode = data.citProductCode;
    this.contract = data.contract ? new Contract(data.contract) : undefined;
  }
}
