
// In this component it is complicated to be able to put a variant
import { Component, Prop, Model, Vue, Watch } from "vue-property-decorator";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PFormCheckBox extends Vue {
  @Model("input") readonly checked!: any;
  @Watch("checked")
  onChangeChecked(): void {
    this.isCheckedFun();
  }
  @Prop({ required: true }) id!: string;
  @Prop({ required: false, default: true }) value!: any;
  @Prop({ required: false, default: false }) uncheckedValue!: any;
  @Prop({ required: false }) label!: string;
  @Prop({ required: false, default: "p_form_checkbox_label" }) lclass!: string;
  @Prop({ required: false, default: false }) readOnly!: boolean;

  public isChecked: boolean;
  public defaultChecked: boolean;
  public showLabel: boolean;

  constructor() {
    super();
    this.isChecked = false;
    this.defaultChecked = false;
    this.showLabel = false;
  }
  mounted(): void {
    if (this.label === undefined) {
      this.showLabel = false;
    } else {
      this.showLabel = true;
    }
    this.isCheckedFun();
  }

  get giveMeClassReadOnly(): string {
    if (this.readOnly) {
      return "readOnly";
    } else {
      return "";
    }
  }

  change(): void {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.$emit("input", this.value);
      this.$emit("change", this.value);
    } else {
      this.$emit("input", this.uncheckedValue);
      this.$emit("change", this.uncheckedValue);
    }
  }
  isCheckedFun(): void {
    if (this.value === this.checked) {
      this.defaultChecked = true;
      this.isChecked = true;
    } else {
      this.defaultChecked = false;
      this.isChecked = false;
    }
  }
}

export default PFormCheckBox;
