import { CrewService } from "./crew.types";
import { Employee, Fleet, mockRawCrew, mockRawEmployee, RawEmployee, RawFleet } from "@/orders";
import { mockRawFleet } from "@/orders/entities/fleet/fleet.mock";
import { BACKEND_ORDERS_BASE_URL } from "@/services";
import Vue from "vue";
import { Crew, RawCrew } from "@/orders/entities/crew";

export class CrewServiceImpl implements CrewService {
  async fetchByIdentify(employeeId: string, countryCode: string): Promise<Employee[]> {
    const params = {
      params: {
        countryCode: countryCode,
        employeeCode: employeeId,
      },
    };
    const { data } = await Vue.$axios.get<RawCrew>(`${BACKEND_ORDERS_BASE_URL}/api/v1/crews/employee`, params);
    // const data = mockRawCrew();
    return data.employees.map((value) => new Employee(value));
  }

  async fetchByLicense(license: string, countryCode: string): Promise<Fleet> {
    const params = {
      params: {
        countryCode: countryCode,
        fleetPlate: license,
      },
    };
    const { data } = await Vue.$axios.get<RawCrew>(`${BACKEND_ORDERS_BASE_URL}/api/v1/crews/fleet`, params);
    // const data = mockRawCrew();
    return new Fleet(data.fleet);
  }

  async fetchByOrder(orderId: string): Promise<Crew> {
    const { data } = await Vue.$axios.get<RawCrew[]>(`${BACKEND_ORDERS_BASE_URL}/api/v1/crews/order/${orderId}`);
    return new Crew(data[0]);
  }
}
