
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import {
  PTableType,
  FieldPTable,
  EPTableAction,
  FieldPTableType,
  EFilterPTable,
  FilterFieldPTable,
  OrderFieldType,
} from "./pTable.type";
import { iTranslationsTable, translations } from "./pTable.translations";
import PPagination from "../PPagination/PPagination.vue";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import PFormInputPredictiveSearch from "../PFormInput/PFormInputPredictiveSearch.vue";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import PTableData from "./PTableData.vue";

@Component({
  components: {
    PPagination,
    PDropDownContainer,
    PFormInputPredictiveSearch,
    PFormCheckBox,
    PIcon,
    PTableData,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
export class PTable extends Vue {
  @Prop({ required: true }) value!: PTableType;
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.fieldsFormatter = this.fieldsFormatter.concat(this.value.fields);
    this.initTdPreviousValues();
  }
  @Prop({ required: true }) items!: any[];
  @Watch("items", { deep: true, immediate: true })
  onChangeItems(): void {
    if (!this.paginationServer) {
      this.valueLocal.pagination.totalElements = this.items.length;
    }
    this.filterInitValues();
  }
  @Prop({ required: false, default: "" }) tableClass!: string;
  @Prop({ required: false, default: false }) fullWidth!: boolean;
  @Prop({ required: false, default: false }) pagination!: boolean;
  @Prop({ required: false, default: false }) paginationServer!: boolean;
  @Prop({ required: false }) paginationLimit!: number;
  @Prop({ required: false }) haveSearch!: boolean;
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false }) translation!: iTranslationsTable;
  @Prop({ required: false, default: "" }) classOverflow!: "" | "overflow";
  @Watch("translation", { deep: true, immediate: true })
  onChangeTranslation(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  @Prop({ required: false, default: false }) biggerLastColumn!: boolean;
  @Ref("table") readonly table!: any;


  translator: iTranslationsTable = translations[0].translations;
  valueLocal: PTableType = {
    fields: [],
    defaultFields: [],
    pagination: {
      pageSize: 20,
      totalElements: 0,
      currentPage: 1,
    },
    order: {
      orderFields: [],
      groupedFields: [],
    },
  };
  fieldsFormatter: FieldPTable[] = []; //lo necesito porque sino pierdo las funciones de formatter
  tdPreviousValues: string[] = [];
  areData = false;
  ePTableAction = EPTableAction;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
    // necesito rastrear el mouse en toda la ventana para el resize de las columnas
    document.addEventListener.call(window, "mouseup", this.onMouseup);
  }
  onMouseleaveDropDown(): void {
  }
  getEmptyMessage(){
    return this.translator.empty
  }
  getField(field: any): FieldPTable | undefined {
    return this.value.fields.find((f) => f.key == field.key);
  }
  get fields(): FieldPTable[] {
    const fields = this.value.fields.filter((fie: FieldPTable) => this.isShowable(fie));
    return fields;
  }
  isShowable(field: FieldPTable): boolean {
    if (field.type == FieldPTableType.HIDDEN) return false;
    return field.show;
  }
  // ------ col-resize ini------
  @Ref("ruler") readonly ruler!: any;
  @Ref("colResize") readonly colResize!: any;
  isMousedown = false;
  fieldSelected: FieldPTable | undefined = undefined;
  rulerValue = "-";
  th: HTMLElement | undefined = undefined;
  columnWidth = 0;
  columnMinWidth = 0;
  columnPositionLeft = 0;

  onMousedown(event: any, field: FieldPTable): void {
    this.isMousedown = true;
    this.fieldSelected = this.getField(field);
    this.colResizeSetElement(event.pageX);
    this.colResizeSetVars(field);
  }
  async dblClick(field: FieldPTable): Promise<void> {
    this.fieldSelected = this.getField(field);
    this.colResizeSetVars(field);
    this.colResizeSetColumnWidth(this.columnMinWidth + "px");
    this.colResizeUnsetVars();
  }
  emitDblClick(item: any) {
    this.$emit("dbl-click", item);
  }
  onMouseup(event: any): void {
    if (this.isMousedown && this.th && this.fieldSelected) {
      this.colResizeUnsetElemet();
      let newWidth = this.colResize.getBoundingClientRect().right - this.columnPositionLeft + "px";
      this.colResizeSetColumnWidth(newWidth);
      this.colResizeUnsetVars();
    }
  }
  onMousemove(event: any): void {
    this.$nextTick(() => {
      if (this.isMousedown && this.th) {
        this.columnPositionLeft = this.th.getBoundingClientRect().left;
        let columnRightLimit = this.columnPositionLeft + this.columnMinWidth;
        let newLeft = columnRightLimit <= event.pageX ? event.pageX : columnRightLimit;
        this.colResize.style.left = newLeft + "px";
      }
    });
  }
  colResizeSetVars(field: FieldPTable): void {
    this.th = this.getHTMLElementByRef("ref_th_" + field.key);
    if (this.th) {
      this.columnWidth = this.th.offsetWidth;
      this.columnPositionLeft = this.th.getBoundingClientRect().left;
    }
    this.rulerValue = field.label || "---";
    this.$forceUpdate();
    this.$nextTick(() => {
      this.columnMinWidth = this.ruler.offsetWidth || 20;
      this.columnMinWidth += this.getThIconsWitdh(field);
      this.columnMinWidth += 5; // padding
    });
  }
  colResizeUnsetVars(): void {
    this.isMousedown = false;
    this.fieldSelected = undefined;
    this.th = undefined;
  }
  colResizeSetColumnWidth(newWidth: string): void {
    if (this.th && this.fieldSelected) {
      this.fieldSelected.width = newWidth;
      this.th.style.minWidth = newWidth;
      let tds = this.getHTMLElementsByRef("ref_td_container_" + this.fieldSelected.key);
      if (tds) tds.map((td: any) => (td.$el.style.width = newWidth));
      this.$forceUpdate();
    }
  }
  colResizeSetElement(pageX: number): void {
    this.colResize.style.top = this.table.getBoundingClientRect().top + "px";
    this.colResize.style.left = pageX + "px";
    this.colResize.style.height = this.table.offsetHeight + "px";
    this.colResize.style.backgroundColor = "#ffd102";
    this.colResize.style.boxShadow = "grey 0 0 10px 0";
  }
  colResizeUnsetElemet(): void {
    this.colResize.style.backgroundColor = "";
    this.colResize.style.boxShadow = "";
  }
  getHTMLElementsByRef(ref: string): HTMLElement[] | undefined {
    return this.$refs[ref] as HTMLElement[];
  }
  getHTMLElementByRef(ref: string): HTMLElement | undefined {
    let elementRef = this.getHTMLElementsByRef(ref);
    if (elementRef && elementRef.length > 0) return elementRef[0];
    return undefined;
  }
  getThIconsWitdh(field: FieldPTable): number {
    let dropDownElement = this.getHTMLElementByRef("ref_th_drop_down_" + field.key);
    let iconsElementiconsElement = this.getHTMLElementByRef("ref_th_icons_" + field.key);
    let width = 0;
    if (dropDownElement?.offsetWidth) width += dropDownElement.offsetWidth;
    if (iconsElementiconsElement?.offsetWidth) width += iconsElementiconsElement.offsetWidth;
    return width;
  }
  // ------ col-resize end ------
  // ----- filters ini -----
  eFilterPTable = EFilterPTable;
  filterFields: FilterFieldPTable[] = [];
  filterFieldsOrder: string[] = [];
  filterInitValues(): void {
    const fields = this.filterGetFieldsToFilter();
    this.filterFields = [];
    this.filterFieldsOrder = [];
    fields.map((field: FieldPTable) => {
      let filterField = { key: field.key, filter: EFilterPTable.ALL, itemValues: [] };
      this.filterFields.push(filterField);
      this.filterInitValuesField(field, filterField);
    });
  }
  filterInitValuesField(field: FieldPTable, filterField: any) {
    filterField.itemValues = [];
    this.filterGetOptionsByField(field, true).map((op: any) => {
      filterField.itemValues.push(op.value);
    });
  }
  filterGetFieldsToFilter(): FieldPTable[] {
    const returnTemp: FieldPTable[] = [];
    this.fields.map((field: FieldPTable) => {
      if (field.showFilterField) returnTemp.push(field);
    });
    return returnTemp;
  }
  filterUpdateByField(field: FieldPTable, value: string, checked: boolean): void {
    const filterField = this.filterGetFilterField(field);
    if (filterField) {
      if (checked) {
        if (!filterField.itemValues.includes(value)) filterField.itemValues.push(value);
        if (this.filterAreAllSelected(field)) {
          this.filterInitValuesField(field, filterField);
          filterField.filter = EFilterPTable.ALL;
        } else {
          filterField.filter = EFilterPTable.SOME;
        }
        this.filterUpdateFieldsOrder(field);
      } else {
        const index = filterField.itemValues.indexOf(value);
        if (index > -1) filterField.itemValues.splice(index, 1);
        if (filterField.itemValues.length == 0) filterField.filter = EFilterPTable.NONE;
        if (filterField.itemValues.length > 0) filterField.filter = EFilterPTable.SOME;
        this.filterUpdateFieldsOrder(field);
      }
    }
  }
  filterUpdateSelectAll(field: FieldPTable, checked: boolean): void {
    const filterField = this.filterGetFilterField(field);
    if (!filterField) return;
    if (checked) {
      filterField.filter = EFilterPTable.ALL;
      this.filterInitValuesField(field, filterField);
      this.filterUpdateFieldsOrder(field, false);
    } else {
      filterField.itemValues = [];
      filterField.filter = EFilterPTable.NONE;
      this.filterUpdateFieldsOrder(field);
    }
  }
  filterUpdateFieldsOrder(field: FieldPTable, add = true) {
    let key = field.key;
    if (add) {
      if (!this.filterFieldsOrder.includes(key)) this.filterFieldsOrder.push(key);
    } else {
      const index = this.filterFieldsOrder.indexOf(key);
      if (index > -1) this.filterFieldsOrder.splice(index, 1);
    }
  }
  /** @description returns the options of a field, applying filters, by default. Filters are applied in order up to the given field. */
  filterGetOptionsByField(field: FieldPTable, all = false): any[] {
    let returnTemp: any[] = [];
    const emptyValue = "(" + this.translator.filterEmpty + ")";
    let items = this.items.slice();
    // filtro aplicando los filtros en orden hasta el campo actual
    if (!all) {
      this.filterFieldsOrder.every((key: string) => {
        if (field.key == key) return false;
        const fieldTemp: any = this.valueLocal.fields.find((fi: FieldPTable) => fi.key === key);
        const filterField = this.filterGetFilterField(fieldTemp);
        if (filterField) {
          switch (filterField.filter) {
            case EFilterPTable.ALL:
              break;
            case EFilterPTable.NONE:
              items = [];
              break;
            case EFilterPTable.SOME:
              items = items.filter((item: any) => {
                const value = this.filterFormatFieldValue(fieldTemp, item);
                return filterField.itemValues.includes(value);
              });
          }
        }
      });
    }
    // agrego al resultado
    items.map((item: any) => {
      const val = this.filterFormatFieldValue(field, item);
      let text = val;
      text = text ? text : emptyValue;
      if (!returnTemp.find((o: any) => o.text == text)) {
        returnTemp.push({ text: text, value: val });
      }
    });
    returnTemp.sort((a: any, b: any) => {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0;
    });
    return returnTemp;
  }
  filterFormatFieldValue(field: FieldPTable, item: any): any {
    const fieldTemp: any = this.fieldsFormatter.find((fi: FieldPTable) => fi.key == field.key);
    if (fieldTemp?.formatterForFilter) {
      return this.formatFieldForFilter(field, item);
    } else {
      return this.formatField(field, item);
    }
  }
  filterIsFieldFiltered(field: FieldPTable): boolean {
    const filterField = this.filterGetFilterField(field);
    if (filterField && (filterField.filter == EFilterPTable.SOME || filterField.filter == EFilterPTable.NONE))
      return true;
    return false;
  }
  filterIsFieldSelected(field: FieldPTable, value: string): boolean {
    let returnTemp = false;
    const filterField = this.filterGetFilterField(field);
    if (!filterField) return returnTemp;
    switch (filterField.filter) {
      case EFilterPTable.ALL:
        returnTemp = true;
        break;
      case EFilterPTable.NONE:
        returnTemp = false;
        break;
      case EFilterPTable.SOME:
        returnTemp = filterField.itemValues.includes(value);
    }
    return returnTemp;
  }
  filterAreAllSelected(field: FieldPTable): boolean {
    const filterField = this.filterFields.find((f: any) => f.key == field.key);
    if (filterField) {
      if (filterField.filter == EFilterPTable.ALL) return true;
      const allValues = this.filterGetOptionsByField(field, true);
      if (allValues.length == filterField.itemValues.length) return true;
    }
    return false;
  }
  filterGetFilterField(field: FieldPTable): FilterFieldPTable | undefined {
    return this.filterFields.find((f: any) => f.key == field.key);
  }
  getFieldsToFilter(): FieldPTable[] {
    const returnTemp: FieldPTable[] = [];
    this.fields.map((field: FieldPTable) => {
      if (field.showFilterField) returnTemp.push(field);
    });
    return returnTemp;
  }
  // ----- filters end -----
  // ----- order ini -----
  addOrderField(field: string, direction: string): void {
    const orderField = this.valueLocal.order.orderFields.find((orderField) => orderField.field == field);
    if (orderField) {
      orderField.direction = direction;
    } else {
      this.valueLocal.order.orderFields = [];
      this.valueLocal.order.orderFields.push({ field, direction });
    }
  }
  removeOrderField(field: string): void {
    this.valueLocal.order.orderFields = this.valueLocal.order.orderFields.filter(
      (orderField) => orderField.field != field
    );
  }
  isOrderField(field: FieldPTable): boolean {
    return this.valueLocal.order.orderFields.some((orderField) => orderField.field == field.key);
  }
  getOrderIcon(field: FieldPTable): string {
    const orderField = this.valueLocal.order.orderFields?.find((orderField) => orderField.field == field.key);
    if (orderField) {
      return orderField.direction == "ASC" ? "icon-asc" : "icon-desc";
    }
    return "";
  }
  getOrderNumber(field: FieldPTable): number {
    return this.valueLocal.order.orderFields.map((orderField) => orderField.field).indexOf(field.key) + 1;
  }
  areManyOrderFields(): boolean {
    return this.valueLocal.order.orderFields?.length > 1;
  }
  isFieldOrderDirection(field: FieldPTable, tableAction: EPTableAction): boolean {
    const orderField = this.valueLocal.order.orderFields.find((orderField) => orderField.field == field.key);
    if (!orderField) {
      return false;
    }
    switch (tableAction) {
      case EPTableAction.ASC:
        return orderField.direction == "ASC";
      case EPTableAction.DESC:
        return orderField.direction == "DESC";
    }
    return false;
  }
  // ----- order end -----
  get itemsComp(): any[] {
    let itemsTemp: any[] = this.items.slice();
    const fieldsToFilter = this.getFieldsToFilter();
    this.filterFields.map((filterField: any) => {
      const field = fieldsToFilter.find((f: any) => f.key == filterField.key);
      if (field) {
        switch (filterField.filter) {
          case EFilterPTable.ALL:
            break;
          case EFilterPTable.NONE:
            itemsTemp = [];
            break;
          case EFilterPTable.SOME:
            itemsTemp = itemsTemp.filter((item: any) => {
              const value = this.filterFormatFieldValue(field, item);
              return filterField.itemValues.includes(value);
            });
        }
      }
    });
    this.areData = false;
    if (this.paginationServer) {
      if (this.items.length > 0) {
        this.areData = true;
      }
      return itemsTemp;
    } else {
      //Lo primero ordenamos por el campo que se quiere ordenar
      this.sortByMultipleFields(this.valueLocal.order.orderFields, itemsTemp);
      //recorremos los campos con agrupaciones de forma inversa y ordenamos
      const groupedFieldsReverse = this.valueLocal.order.groupedFields.slice().reverse();
      if (groupedFieldsReverse) {
        const groupedFields = groupedFieldsReverse.map((field) => ({ field, direction: "DESC" }));
        this.sortByMultipleFields(groupedFields, itemsTemp);
      }

      if (this.items.length > 0) {
        this.areData = true;
      }
      const iniItems =
        this.valueLocal.pagination.currentPage * this.valueLocal.pagination.pageSize -
        this.valueLocal.pagination.pageSize;
      const endItems = iniItems + this.valueLocal.pagination.pageSize;

      return itemsTemp.slice(iniItems, endItems);
    }
  }
  giveMeClassTypeField(field: FieldPTable): string {
    if (field.type == FieldPTableType.ACTION) {
      return "tdActions_PTable";
    } else {
      return "";
    }
  }
  giveMeTdClassAlign(field: FieldPTable): string {
    let returnTemp = "";
    switch (field.type) {
      case FieldPTableType.ACTION:
      case FieldPTableType.TEXT:
        returnTemp = "text-left";
        break;
      case FieldPTableType.DATE:
        returnTemp = "text-center";
        break;
      case FieldPTableType.NUMBER:
        returnTemp = "text-right";
        break;
    }
    return returnTemp;
  }
  sortByMultipleFields(propArr: OrderFieldType[], arr: any): void {
    if (!arr || !propArr) {
      return;
    }
    arr.sort((i: any, j: any) =>
      propArr
        .map((orderField: OrderFieldType) => this.sortLocal(i, j, orderField.field, orderField.direction))
        .find((result: any) => result)
    );
  }
  sortLocal(x: any, y: any, key: string, direction: string): number {
    let returnTemp = 0;
    const first = "" + x[key];
    const second = "" + y[key];
    if (first == second) {
      returnTemp = 0;
    } else {
      if (first > second) {
        returnTemp = 1;
      } else {
        returnTemp = -1;
      }
    }
    if (direction.toLocaleUpperCase() == "DESC") {
      returnTemp = returnTemp * -1;
    }
    return returnTemp;
  }
  initTdPreviousValues(): void {
    const numfileds = this.value.fields.filter((field: FieldPTable) => field?.show).length;
    this.tdPreviousValues = new Array(numfileds);
  }
  formatField(field: FieldPTable, item: any, index?: number): any {
    const fieldFormatter = this.getFieldFormatter(field.key);
    let value = this.findValueRecursive(item, field.key);
    if (value == undefined) return "";
    if (field.multipleValues && index != undefined && index >= 0) {
      value = value[index];
    }
    return fieldFormatter ? fieldFormatter(value) : value;
  }
  formatFieldForFilter(field: FieldPTable, item: any): any {
    const fieldFormatter = this.getFieldFormatterForFilter(field.key);
    let value = this.findValueRecursive(item, field.key);
    if (value == undefined) return "";
    return fieldFormatter ? fieldFormatter(value) : value;
  }
  getFieldFormatter(key: string): any | undefined {
    const fieldFormatter = this.fieldsFormatter.find((fi: FieldPTable) => fi.key == key);
    return fieldFormatter?.formatter ? fieldFormatter.formatter : undefined;
  }
  getFieldFormatterForFilter(key: string): any | undefined {
    const field = this.fieldsFormatter.find((fi: FieldPTable) => fi.key == key);
    return field?.formatterForFilter ? field.formatterForFilter : undefined;
  }
  findValueRecursive(object: any, key: string): any | undefined {
    let obj = object;
    key.split(".").forEach((keyPartial: string) => {
      if (obj != undefined && obj[keyPartial] != undefined) {
        obj = obj[keyPartial];
      } else {
        obj = undefined;
      }
    });
    return obj;
  }
  giveMeMaxSizeRowSpan(field: any, element: string, item: any, index: number, indexItem: number): string {
    let returnTemp = this.giveMeMaxSize(field, element, indexItem);
    //returnTemp = index + " g:" + field.groupedField + " v:" + valorTd;
    if (field.fixColumn) {
      if (indexItem % 2 == 0) {
        returnTemp =
          returnTemp +
          "position: sticky; right: 0;background-color: #fafafa;box-shadow: 1px 0 rgba(0, 0, 0, 0.16) inset;";
      } else {
        returnTemp =
          returnTemp +
          "position: sticky; right: 0;background-color: #f1f1f1;box-shadow: 1px 0 rgba(0, 0, 0, 0.16) inset;";
      }
    }
    if (field.groupedField) {
      if (this.tdPreviousValues[index] === this.formatField(field, item)) {
        returnTemp = returnTemp + "display:none;";
      }
      this.tdPreviousValues[index] = this.formatField(field, item);
    } else {
      this.tdPreviousValues[index] = "";
    }
    return returnTemp;
  }
  giveClassTh(field: FieldPTable): string {
    if (field.fixColumn) {
      return "fixColumn";
    } else {
      return "";
    }
  }
  giveMeMaxSize(field: FieldPTable, element: string, index?: any): string {
    let returnTemp = "";
    const elementTypes = ["divTd", "th"];
    if (field.width) {
      if (field.type != FieldPTableType.ACTION && elementTypes.includes(element)) {
        if (index === this.fields.length - 1 && this.biggerLastColumn) {
          const lastColumWidth: number = parseInt(field.width) + 60;
          returnTemp = "min-width:" + lastColumWidth + "px;";
        } else {
          return field.key != "barCodes" ? (returnTemp = "") : "width:" + field.width + ";";
        }
      } else {
        returnTemp = "width:" + field.width + ";";
      }
    } else {
      const numFields = this.fields.length;
      if (element === "divTd") {
        returnTemp = "width:100%;";
      } else {
        returnTemp = "max-width:" + 100 / numFields + "%;";
      }
    }
    return returnTemp;
  }
  actionTable(field: FieldPTable, action: EPTableAction): void {
    const fieldTemp: any = this.valueLocal.fields.find((fi: FieldPTable) => fi.key === field.key);
    switch (action) {
      case EPTableAction.ASC: {
        this.addOrderField(field.key, "ASC");
        this.valueLocal.pagination.currentPage = 1;
        break;
      }
      case EPTableAction.DESC: {
        this.addOrderField(field.key, "DESC");
        this.valueLocal.pagination.currentPage = 1;
        break;
      }
      case EPTableAction.NO_ORDER: {
        this.removeOrderField(field.key);
        this.valueLocal.pagination.currentPage = 1;
        break;
      }
      case EPTableAction.COMBINE: {
        //Debemos comprobar si nuestro field esta en groupedFields y añadirlo al array segun el orden del field
        const isFieldInGroupedFields = this.valueLocal.order.groupedFields.find((gr) => gr === field.key);
        const indexFieldToInsert = this.valueLocal.fields.findIndex((fi: FieldPTable) => fi.key === field.key);
        if (!isFieldInGroupedFields) {
          //como no esta prodemos a insertarlo
          let positionInArray = 0;
          //pero antes tenemos que ver en que posicion lo vamos a insertar
          //para recorremos el groupedFields y vemos los indices con los que corresponden los ya insertados en los fields
          this.valueLocal.order.groupedFields.forEach((gr, index) => {
            const indexYaInsertado = this.valueLocal.fields.findIndex((fi: FieldPTable) => fi.key === gr);
            if (indexFieldToInsert > indexYaInsertado) {
              positionInArray = index + 1;
            }
          });
          //como no esta lo añadimos
          this.valueLocal.order.groupedFields.splice(positionInArray, 0, field.key);
        }
        this.valueLocal.pagination.currentPage = 1;
        this.initTdPreviousValues();
        fieldTemp.groupedField = true;
        break;
      }
      case EPTableAction.EXPAND: {
        let array = [2, 5, 9];
        let index = array.indexOf(5);
        if (index > -1) {
          array.splice(index, 1);
        }
        //Debemos eliminar de groupedFields el campo que vayamos a desagrupar
        const indexToDelete = this.valueLocal.order.groupedFields.findIndex((gr) => gr === field.key);
        if (indexToDelete > -1) {
          this.valueLocal.order.groupedFields.splice(indexToDelete, 1);
        }
        this.valueLocal.pagination.currentPage = 1;
        this.initTdPreviousValues();
        fieldTemp.groupedField = false;
        break;
      }
      case EPTableAction.HIDE: {
        this.initTdPreviousValues();
        fieldTemp.show = false;
        break;
      }
    }
    this.change();
  }
  rowSpan(field: any, item: any, indexRow: number): number {
    if (field.groupedField) {
      const tempValue = this.formatField(field, item);
      let numRowSpan = 0;
      let i = indexRow;
      while (i < this.itemsComp.length && tempValue == this.formatField(field, this.itemsComp[i])) {
        i++;
        numRowSpan++;
      }
      return numRowSpan;
    } else {
      return 1;
    }
  }
  get classFieldFixed(): string {
    const fieldFixed = this.value.fields.find((fi) => fi.fixColumn);
    if (fieldFixed) {
      return "classFieldFixed";
    } else {
      return "";
    }
  }
  showDropDown(field: FieldPTable): boolean {
    return field.showOrderField || field.showGroupedField || field.showHideField || field.showFilterField;
  }
  change(): void {
    this.$emit("input", this.valueLocal);
    if (this.paginationServer) {
      this.$emit("change", this.valueLocal);
    }
  }
}
export default PTable;
