import { RawRole } from "./role.types";
import { Role } from "./role";

export const mockRawRoles = (): RawRole[] => [
  {
    id: 1,
    code: "ROL_CASH_CASHTODAY_PORTALCLIENTES_ADMINISTRATION",
    externalCode: "",
    module: "CASH TODAY PORTAL CLIENTES",
  },
];

export const mockRoles = (data: RawRole[] = mockRawRoles()): Role[] => data.map((item) => new Role(item));
