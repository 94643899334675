<template>
  <div class="change-type" @mouseover="toggle = true" @mouseleave="toggle = false">
    <button class="c-button c-button--transparent icon--end">
      {{ selectedOption(select).name }} <icon :image="down" size=".9rem" />
    </button>
    <ul class="options" v-if="toggle">
      <li
        v-for="(item, index) in options"
        :key="index"
        :class="selectedOption(select).id === item.id ? 'active' : ''"
        @click="changePage(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from "@/orders/components/Icon/index.vue";

export default {
  components: {
    Icon,
  },
  props: {
    select: Number,
  },
  data() {
    return {
      down: require("@/orders/assets/icons/icon-chevron-down.svg"),
      toggle: false,
      options: [
        {
          id: 1,
          name: this.$t("orders.calendar.day"),
          url: "ordersDay",
        },
        {
          id: 2,
          name: this.$t("orders.calendar.week"),
          url: "ordersWeek",
        },
        {
          id: 3,
          name: this.$t("orders.calendar.month"),
          url: "ordersMonth",
        },
      ],
    };
  },
  methods: {
    selectedOption(id) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id === id) {
          return this.options[i];
        }
      }
    },
    changePage(item) {
      if (item.id === this.select) return;
      this.$router.push({ name: item.url });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/orders/views/orders.scss";
.change-type {
  position: relative;

  ul {
    overflow: hidden;
    padding-left: 0;
    margin: 0;
    font-size: 0.75rem;
    color: #555348;
    background-color: white;
    border-radius: 0.3rem;
    position: absolute;
    top: 100%;
    width: 100%;
    box-shadow: 0 0 5px 3px rgba(#333333, 0.2);
    z-index: 100;

    li {
      display: flex;
      padding: 0.5rem;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #f4f4f4;
      }
      &.active {
        background-color: #f4f4f4;
      }
    }
  }
}
</style>
