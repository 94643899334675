
import { Component, Vue, Prop } from "vue-property-decorator";
import { LogicCashTodayBalance, LogicCashTodayBalanceBalance } from "@/entities";
import { PMiniLabel, Panel } from "@/common/components";
import { LocalDateTime } from "@js-joda/core";
import DateTimeMixin from "@/mixins/datetime";
import { PPiePercentage } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PMiniLabel,
    Panel,
    PPiePercentage,
  },
})
export default class LogicCashTodayDetailBalance extends Vue {
  @Prop() public logicCashTodayBalance!: LogicCashTodayBalance;

  miniLabelFontSize = "12px";
  currencies = this.logicCashTodayBalance.balances.map((balance) => balance.currency);
  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };

  getBalanceByCurrency(currency: string): LogicCashTodayBalanceBalance | undefined {
    return this.logicCashTodayBalance.balances.find((balance) => balance.currency == currency);
  }

  get info(): string {
    if (this.logicCashTodayBalance && this.logicCashTodayBalance.lastTransactionDate) {
      return this.formatDateTimeTodayYerterday(this.logicCashTodayBalance.lastTransactionDate as LocalDateTime) || "";
    }
    return "";
  }
}
