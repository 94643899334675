
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Icon from "@/orders/components/Icon/index.vue";
import VueOrders from "@/orders/vue-orders";
import { Commodity, OrderDTO } from "@/orders";

@Component({
  components: {
    Icon,
  },
  mixins: [VueOrders],
})
export default class StepTwo extends Vue {
  // Props
  @Prop({ default: false }) active!: boolean;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  @Getter("getCommoditiesService") commodities!: Commodity[];
  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  selectCommodity = {} as Commodity;

  created(): void {
    if (this.notChangeOrder.commodity) {
      this.selectCommodity = this.notChangeOrder.commodity;
    }
  }
  // Métodos
  enabledNext(): boolean {
    return this.selectCommodity.id != null;
  }

  nextPage(): void {
    if (this.enabledNext()) {
      this.$emit("selectCommodity", this.selectCommodity);
    }
  }

  prevPage(): void {
    this.$emit("prevPage", true);
  }

  onSelectCommodity(item: Commodity): void {
    this.selectCommodity = item;
  }
}
