import { RawDeviceDetails, DeviceDetails } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { DeviceDetailsService } from "./deviceDetails.types";

export class DeviceDetailsServiceImpl implements DeviceDetailsService {
  async fetchDeviceDetailsById(deviceDetailsId: string): Promise<RawDeviceDetails> {
    const { data } = await Vue.$axios.get<RawDeviceDetails>(
      `${BACKEND_BASE_URL}/api/v1/deviceDetails/${deviceDetailsId}`
    );
    return new DeviceDetails(data);
  }
}
