
import Icon from "@/orders/components/Icon/index.vue";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { Center } from "@/orders";

@Component({
  components: {
    Icon,
  },
})
export class Centers extends Vue {
  @Model("value") filteredCenters!: Center[];
  @Prop({ required: true }) items!: Center[];

  eyes = require("@/orders/assets/icons/icon-eye.svg");
  selectedItems: Center[] = [];
  filterItems: Center[] = [];
  searchTerm = "";
  isSelectAll = true;

  created(): void {
    this.filterItems = this.getFilterItems();
    this.selectedItems = [...this.filteredCenters];
  }

  selectOrUnselectAll(): void {
    if (this.isSelectAll) {
      this.selectedItems = [...this.filterItems];
      return;
    }
    this.selectedItems = [];
  }

  @Watch("selectedItems")
  onChangeItemsSelected(): void {
    this.isSelectAll = this.selectedItems.length == this.filterItems.length;
    this.$emit("value", this.selectedItems);
  }

  getFilterItems(): Center[] {
    return this.items.filter((item: Center) => {
      const searchTermLowerCase = this.searchTerm.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchTermLowerCase) ||
        item.code.toLowerCase().includes(searchTermLowerCase) ||
        item.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0 ||
        item.code
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0
      );
    });
  }
}

export default Centers;
