import { RatingTypesServices } from "@/orders/services/ratingTypes/ratingTypes.types";
import { BACKEND_ORDERS_BASE_URL } from "@/services";
import { RatingType, RawRatingType } from "@/orders";
import Vue from "vue";

export class RatingTypesServiceImpl implements RatingTypesServices {
  async fetchRatingTypes(): Promise<RatingType[]> {
    const { data } = await Vue.$axios.get<RawRatingType[]>(`${BACKEND_ORDERS_BASE_URL}/api/v1/ratings/rating-types`);
    return data.map((ratingType) => new RatingType(ratingType));
  }
}
