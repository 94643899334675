import { RawServicePointCosmos } from "@/entities/transactionCosmos/service-point-cosmos/servicePointCosmos.types";

export class ServicePointCosmos {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly isEarlyValue: boolean;
  readonly deviceId: string;
  readonly centerId: string;
  constructor(data: RawServicePointCosmos) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.isEarlyValue = data.isEarlyValue;
    this.deviceId = data.deviceId;
    this.centerId = data.centerId;
  }
}
