
import Icon from "@/orders/components/Icon/index.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OptionType } from "@/orders/components/Options/Options.types";
@Component({
  components: {
    Icon,
  },
})
export class Options extends Vue {
  @Prop({ required: true }) options!: OptionType[];
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) value!: OptionType;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  down = require("@/orders/assets/icons/icon-chevron-down.svg");
  toggle = false;
  valueLocal: OptionType | null = null;

  get optionSelected(): string {
    if (this.valueLocal) {
      return this.valueLocal.name;
    } else {
      return this.$t("orders.employees.select") as string;
    }
  }

  selectedOption(id: string): boolean {
    return this.valueLocal != null && this.valueLocal.id === id;
  }
  selectOption(item: OptionType) {
    this.valueLocal = item;
    this.$emit("input", this.valueLocal);
  }
}
export default Options;
