import { Establishment, RawEstablishments } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { EstablishmentService } from "./establishment.types";

export class EstablishmentServiceImpl implements EstablishmentService {
  async fetchEstablishments(): Promise<Establishment[]> {
    /** TODO wait for backend endpoint */
    // const { data } = await Vue.$axios.get<RawEstablishments>(
    //   `${BACKEND_BASE_URL}/api/v1/me/establishments`
    // );
    // return data.establishments;
    const data = [
      {
        id: "1",
        name: "Tienda Las Palmas (Lanzarote)",
        numberOfMachines: 2,
      },
      {
        id: "2",
        name: "Tienda Santa Cruz de Tenerife",
        numberOfMachines: 3,
      },
    ];
    return data;
  }
}
