import { RawLogicCashTodays, LogicCashTodays } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import { LogicCashTodayService } from "@/services/backend/logic-cash-today/logicCashToday.types";
import Vue from "vue";

export class LogicCashTodayServiceImpl implements LogicCashTodayService {
  async fetchLogicCashTodays(): Promise<LogicCashTodays> {
    const { data } = await Vue.$axios.get<RawLogicCashTodays>(`${BACKEND_BASE_URL}/api/v1/me/logicCashToday`);
    return new LogicCashTodays(data);
  }
}
