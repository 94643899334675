
import { Component, Vue, Prop } from "vue-property-decorator";
import { Establishment } from "./dashboard.type";
import { PFormInputTextSearch } from "@/common/components";

@Component({
  components: {
    PFormInputTextSearch,
  },
})
export class EstablishmentsDashboard extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) establishments!: Establishment[];

  filterInput = "";
  placeholderSearch = this.$t("establishments.search");

  giveMectionClassItem(est: string) {
    if (this.value === est) {
      return "itemSelectSelected_EstablishmentsDashboard";
    } else {
      return "itemSelect_EstablishmentsDashboard";
    }
  }
  get establishmentsComp() {
    return this.establishments.filter((es) => es.name.toUpperCase().includes(this.filterInput.toUpperCase()));
  }
  get giveMeNumEstablishments() {
    let returnTem = 0;
    this.establishments.forEach((es) => {
      returnTem = returnTem + es.num;
    });
    return returnTem;
  }
  filterEstablishments(est: string) {
    this.$emit("input", est);
    this.$emit("change", est);
  }
}
export default EstablishmentsDashboard;
