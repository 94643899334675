
import { Component, Vue, Prop } from "vue-property-decorator";
import Dropdown from "@/orders/components/Dropdown/Dropdown.vue";
import { CancellationOrder, CancellationReason, Order } from "@/orders";

@Component({
  components: {
    Dropdown,
  },
})
export default class ModalCancellation extends Vue {
  @Prop({ type: Boolean, required: true }) active!: boolean;
  @Prop({ required: true }) order!: Order;

  toggle = false;
  cancellationReasons: CancellationReason[] = [];
  optionSelected = "";
  comment = "";

  closeModal() {
    this.$emit("closeModal", !this.active);
  }

  changeOption(option: { code: string }) {
    this.optionSelected = option.code;
  }

  cancellationNames(): string[] {
    return this.cancellationReasons.map((reason: CancellationReason) => {
      return reason.description;
    });
  }

  getReasonIdByDescription(description: string): CancellationReason {
    const reason = this.cancellationReasons.find((reason: CancellationReason) => reason.description === description);
    return reason || ({} as CancellationReason);
  }

  saveCancelation() {
    const bodyReason: CancellationOrder = {
      orderId: this.order.orderId,
      cancellationReasonId: this.getReasonIdByDescription(this.optionSelected).id,
      cancellationComments: this.comment,
    };
    this.$services.order.patchOrderCancellation(bodyReason);
    this.$emit("closeModal", !this.active);
  }

  async created() {
    this.cancellationReasons = await this.$services.order.fetchCancellationReasons(this.order.country.code);
    this.optionSelected = this.cancellationReasons[0].description;
  }
}
