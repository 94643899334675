import { Currency } from "@/orders/entities/currency";
import { RawDenomination } from "@/orders/entities";

export class Denomination {
  readonly id: string;
  readonly code: string;
  readonly type: string;
  readonly description: string;
  readonly value: number;
  readonly currency: Currency;

  constructor(data: RawDenomination) {
    this.id = data.id;
    this.code = data.code;
    this.type = data.type;
    this.description = data.description;
    this.value = data.value;
    this.currency = new Currency(data.currency);
  }
}

export interface DenominationData extends Denomination {
  expand: boolean;
  denominationValue: DenominationValue;
}

export interface DenominationFromCurrencyCode {
  currencyCode: string;
  denominations: DenominationData[];
}

export interface DenominationValue {
  quantity: number;
  valueUpdated: number;
}

export interface AmountByDenomination {
  currencyCode: string;
  subtotal: number;
}

export class DenominationType {
  static readonly BILLS = new DenominationType("bills", "Billetes");
  static readonly COINS = new DenominationType("coins", "Monedas");

  private constructor(public readonly value: string, public readonly description: string) {}

  toString() {
    return this.value;
  }
}
