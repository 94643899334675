import { Entity, RawUser } from "@/entities";

export class User {
  readonly id: string;
  readonly name: string;
  readonly lastName: string;
  readonly email: string;
  readonly countryCode: string;
  readonly lastLogin?: Date;

  readonly fullName: string;
  readonly avatarLetter: string;
  readonly entities: Entity[];

  constructor(data: RawUser) {
    this.id = data.id;
    this.name = data.name;
    this.lastName = data.lastName;
    this.email = data.email;
    this.countryCode = data.countryCode;
    this.lastLogin = data.lastLogin ? new Date(data.lastLogin) : undefined;

    this.fullName = `${data.name} ${data.lastName}`;
    this.avatarLetter = data.lastName.substring(0, 1).toUpperCase();
    this.entities = data.entities.map((entity) => new Entity(entity));
  }

  isProsegur(): boolean {
    return this.email.split("@")[1].includes("prosegur.");
  }
}
