
import { Component, Vue, Watch } from "vue-property-decorator";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { Instant, LocalDateTime } from "@js-joda/core";
import {
  ETransactionOrigin,
  ETransactionTypeList,
  LogicCashToday,
  ServicePoint,
  TransactionCosmos,
  UserPreferences,
} from "@/entities";
import {
  ButtonBackToDashboard,
  FieldPTable,
  FieldPTableType,
  PButton,
  PPersonalizeTable,
  PSidebar,
  PTab,
  PTable,
  PTableType,
  PTabs,
  RawFieldPTable,
} from "@/common/components";
import DetailTransaction from "./DetailTransactionCosmos.vue";
import FilterTransactionsPage from "@/pages/transactions/FilterTransactionsPage.vue";
import { Getter } from "vuex-class";
import { OrderByFields } from "@/services";
import { mergeArrayUniqueValues } from "@/common/utils";
import { FilterTransations } from "@/pages/transactions/transactionsPage.type";
import { TransactionCosmosGrid } from "./TransactionCosmosGrid.vue";
import { TransactionCosmosItem } from "@/entities/transactionCosmos/transactionCosmosItem";

@Component({
  name: "transactions-page",
  mixins: [DateTimeMixin],
  components: {
    FilterTransactionsPage,
    PTable,
    PTabs,
    PTab,
    PSidebar,
    PPersonalizeTable,
    PButton,
    DetailTransaction,
    ButtonBackToDashboard,
    TransactionCosmosGrid,
  },
})
export default class TransactionsCosmosPage extends Vue {
  @Getter("getTransactionFields") getTransactionFields!: FieldPTable[];
  @Getter("getTransactionEarlyValueFields") getTransactionEarlyValueFields!: FieldPTable[];
  @Getter("getTransactionFieldGroups") getTransactionFieldGroups!: string[];

  language = i18n.locale;
  servicePointId = "";
  servicePoints: ServicePoint[] | undefined = [];
  @Watch("servicePoints", { deep: true, immediate: true })
  onChangeServicePoints(): void {
    this.updateTransactionsFieldsPTable();
  }
  download: {
    fields: { field: string; label: string }[];
    translations: { field: string; translation: { [key: string]: string } }[];
    download: boolean;
  } = { fields: [], translations: [], download: false };
  logicCashTodayId = "";
  logicCashTodays: LogicCashToday[] | undefined = [];
  @Watch("logicCashTodays", { deep: true, immediate: true })
  onChangeLogicCashTodays(): void {
    this.updateTransactionsFieldsPTable();
  }
  transactions: TransactionCosmos[] = [];
  transactionItems: TransactionCosmosItem[] = [];
  iTranslationsTable: any = { empty: this.$t("transactions.haventSearch") };
  showFilter = true;
  showSideBar = false;
  title = this.$t("transactions.detailsTitle");
  showPersonalize = false;
  showDetail = false;
  widthSidebar = "";
  pageSize = 20;
  selectedTransaction: TransactionCosmosItem | TransactionCosmos | null = null;
  eTransactionTypes = ETransactionTypeList;
  filtersServerParams: Record<string, unknown> = {};
  filters: FilterTransations = {
    dateStart: new Date(),
    dateEnd: new Date(),
    transactionType: [
      this.eTransactionTypes.SHIPOUT,
      this.eTransactionTypes.CASHIN,
      this.eTransactionTypes.CASHOUT,
      this.eTransactionTypes.SHIPIN,
    ],
    entityName: "",
    userTransaction: "",
    creditDateStart: null,
    creditDateEnd: null,
    isCheckAccreditation: false,
    entityNameAccrediting: "",
    smartDevices: [],
  };
  @Watch("filters", { deep: true, immediate: true })
  onChangeFilters(): void {
    this.updateTransactionsFieldsPTable();
  }
  paginationDefault = {
    pageSize: this.pageSize,
    pageSizeDefault: this.pageSize,
    totalElements: 0,
    currentPage: 1,
    limit: 300,
  };
  orderDefault = {
    orderFields: [{ field: "TRANSACTION_DATE", direction: "ASC" }],
    groupedFields: [],
  };
  transactionsCustomerFields: FieldPTable[] = [];
  extraDataFields: FieldPTable[] = [];
  paramsFilterTransactions: PTableType = {
    fields: [],
    defaultFields: [],
    pagination: this.paginationDefault,
    order: this.orderDefault,
    groupFields: [],
  };

  fieldsExportMap: Record<string, string> = {
    tellerName: "teller.name",
    "deviceDetails.deviceCode": "device.code",
    barCodes: "amount.barCodes",
    delegation: "delegationCode",
    teller: "teller.teller",
    tellerLogin: "teller.login",
  };

  @Watch("paramsFilterTransactions.pagination", { deep: true })
  onChangeParamsFilterTransactions(): void {
    this.saveUserPreferences();
  }
  userPreferences: UserPreferences = {} as UserPreferences;

  created(): void {
    this.logicCashTodayId = this.$route.params.logicCashTodayId;
    this.servicePointId = this.$route.params.servicePointId;
    Promise.all([
      this.$services.servicePoints.fetchServicePoints(),
      this.$services.userPreferences.fetchUserPreferences(),
    ]).then((resp) => {
      this.servicePoints = resp[0].servicePoints;
      this.userPreferences = resp[1];
      this.paramsFilterTransactions.defaultFields = this.getTransactionFields;
      this.paramsFilterTransactions.groupFields = this.getTransactionFieldGroups;
      this.setUserPreferences();
    });
  }
  setUserPreferences(): void {
    // default fields takes precedence
    this.userPreferences.transactionsPage.fields.map((rawField: any) => {
      const field = this.paramsFilterTransactions.defaultFields.find((fi: any) => fi.key == rawField.key);
      if (field) this.transactionsCustomerFields.push(FieldPTable.createAndMerge(field, rawField));
    });
    // pagination
    if (this.userPreferences.transactionsPage.pagination.pageSize) {
      this.paginationDefault.pageSize = this.userPreferences.transactionsPage.pagination.pageSize;
    }
  }
  saveUserPreferences(): void {
    this.setUserPreferences();
    if (!this.userPreferences || !this.userPreferences.transactionsPage) return;
    // merge with filter
    this.userPreferences.transactionsPage.fields = this.paramsFilterTransactions.fields.map(
      (field) => new RawFieldPTable(field)
    );
    this.userPreferences.transactionsPage.pagination = { pageSize: this.paramsFilterTransactions.pagination.pageSize };
    // save on backend
    this.$services.userPreferences.saveUserPreferences(this.userPreferences);
  }

  downloadFileExport(): void {
    const fields: { field: string; label: string }[] = [];

    const translations: { field: string; translation: { [key: string]: string } }[] = [];
    const transactionTypes: { [key: string]: string } = {};
    Object.keys(ETransactionTypeList).forEach(
      (key: any) => (transactionTypes[key] = this.$tc("transactions.transactionType." + key))
    );
    translations.push({ field: "transactionType", translation: transactionTypes });

    const transactionOrigins: { [key: string]: string } = {};
    Object.keys(ETransactionOrigin).forEach(
      (key: any) => (transactionOrigins[key] = this.$tc("transactions.transactionOrigin." + key))
    );
    translations.push({ field: "origin", translation: transactionOrigins });

    const excludedFieldTypes = [FieldPTableType.HIDDEN, FieldPTableType.ACTION];
    this.paramsFilterTransactions.fields.forEach((field: FieldPTable) => {
      if (field.show && !excludedFieldTypes.includes(field.type))
        fields.push({ field: this.fieldsExportMap[field.key] || field.key, label: field.label || "" });
    });
    this.download = { fields: fields, translations: translations, download: true };
  }

  updateTransactionsFieldsPTable(): void {
    if (this.areAnyExtraDataField()) {
      this.paramsFilterTransactions.defaultFields = mergeArrayUniqueValues(
        this.paramsFilterTransactions.defaultFields,
        this.extraDataFields
      );
    }
    if (this.paramsFilterTransactions.fields.length > 0) {
      this.paramsFilterTransactions.defaultFields = mergeArrayUniqueValues(
        this.paramsFilterTransactions.defaultFields,
        this.getTransactionEarlyValueFields
      );
      const userFields = this.updateFieldsFromRawFields(
        this.paramsFilterTransactions.defaultFields,
        this.userPreferences.transactionsPage.fields
      );
      this.paramsFilterTransactions.fields = this.updateFieldsFromRawFields(
        userFields,
        this.paramsFilterTransactions.fields
      );
    } else {
      this.paramsFilterTransactions.fields = this.updateFieldsFromRawFields(
        this.paramsFilterTransactions.defaultFields,
        this.transactionsCustomerFields
      );
    }
  }
  areAnyExtraDataField(): boolean {
    return this.extraDataFields.length > 0;
  }
  getExtraDataFields(extraData: any): FieldPTable[] {
    const extraDataFields: FieldPTable[] = [];
    for (const key of Object.keys(extraData)) {
      const field = new FieldPTable({
        key: "extraData." + key,
        label: key,
        width: "110px",
        groupField: "" + i18n.t("transactions.field.groupFieldExtradaData"),
        showFilterField: false,
        show: false,
      });
      extraDataFields.push(field);
    }
    return extraDataFields;
  }
  updateFieldsFromRawFields(fields: FieldPTable[], rawFields: RawFieldPTable[]): FieldPTable[] {
    const returnTemp: FieldPTable[] = [];
    const fieldsNotSaved = fields.filter((fi) => !rawFields.find((rawField) => rawField.key == fi.key));
    rawFields.map((rawField) => {
      const fieldBase = fields.find((fi) => fi.key == rawField.key);
      const fieldCustomer = new FieldPTable(rawField);
      if (fieldBase) returnTemp.push(Object.assign({}, fieldBase, fieldCustomer));
    });
    if (fieldsNotSaved) returnTemp.push(...fieldsNotSaved);
    return returnTemp;
  }
  searchTransactions(filter: FilterTransations): void {
    this.iTranslationsTable = undefined;
    const hasData = (obj: any) => obj && obj != "";

    this.filters = JSON.parse(JSON.stringify(filter));
    this.filtersServerParams = {};

    const dateStart = LocalDateTime.ofInstant(Instant.parse(filter.dateStart.toISOString()));
    const dateEnd = LocalDateTime.ofInstant(Instant.parse(filter.dateEnd.toISOString()));
    const field = filter.searchBy ? "accreditationDate" : "transactionDate";
    this.filtersServerParams[field + "Start"] = dateStart;
    this.filtersServerParams[field + "End"] = dateEnd;

    const servicePoints: string[] = [];
    this.servicePoints
      ?.filter(hasData)
      .filter((servicePoint) => this.filters.smartDevices.includes(servicePoint.id))
      .forEach((servicePoint) => servicePoints.push(servicePoint.id));
    if (servicePoints && servicePoints.length > 0) this.filtersServerParams["servicePointIds"] = servicePoints;

    const logicCashTodays: string[] = [];
    this.logicCashTodays
      ?.filter(hasData)
      .filter((logicCashToday) => this.filters.smartDevices.includes(logicCashToday.id))
      .forEach((logicCashToday) => logicCashTodays.push(logicCashToday.id));
    if (logicCashTodays && logicCashTodays.length > 0) this.filtersServerParams["logicCashTodayIds"] = logicCashTodays;

    const transactionType: string[] = [];
    filter.transactionType.filter(hasData).forEach((tr) => transactionType.push(tr));
    if (transactionType.toString()) this.filtersServerParams["transactionTypes"] = transactionType;

    if (filter.entityName) this.filtersServerParams["entityName"] = filter.entityName;
    if (filter.userTransaction) this.filtersServerParams["tellerName"] = filter.userTransaction;
  }
  showSideBarPersonalize(): void {
    this.showPersonalize = true;
    this.widthSidebar = "";
    this.title = this.$t("transactions.personalize");
    this.showSideBar = true;
  }
  areFiltersSet(): boolean {
    return this.filters.smartDevices && this.filters.smartDevices.length > 0 && this.filters.transactionType.length > 0;
  }
  fileDownloaded(): void {
    this.download.download = false;
  }
  updateNumTransactions(data: TransactionCosmosItem[]): void {
    this.transactionItems = data;
  }
  areTransactions(): boolean {
    return this.transactionItems.length > 0;
  }
  viewTransation(rowTransaction: TransactionCosmosItem): void {
    this.title = this.$t("transactions.detailsTitle");
    this.selectedTransaction = rowTransaction;
    this.showPersonalize = false;
    this.widthSidebar = "613px";
    this.showSideBar = true;
    this.showDetail = true;
  }
  changePersonalize(): void {
    this.showPersonalize = false;
    this.showSideBar = false;
    this.showDetail = false;
    this.saveUserPreferences();
  }
}
