
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType, SelectOrderType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { PFormSelectTranslations, translations } from "./pFormSelectLanguages";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PFormSelectOrder extends Vue {
  @Prop({ required: true }) value!: SelectOrderType;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false }) translation!: PFormSelectTranslations;
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false, default: "button" }) variant!: string;
  @Prop({ required: false }) dropDownPlacement!: string;

  selectedItemValue: SelectOrderType = { selected: "", orderDirection: "DESC" };
  pussOption = false;
  translator: PFormSelectTranslations = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelectOrder_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue.selected != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelectOrder";
    }
    return returnTemp;
  }
  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue.selected);
    if (optionFind) {
      return optionFind.text;
    } else {
      return this.localPlaceHolder;
    }
  }
  get localPlaceHolder(): string {
    return !this.placeholder && this.translator?.textSelectAnOption
      ? this.translator?.textSelectAnOption
      : this.placeholder;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectItem(itemValue: any): void {
    //this.selectedItemValue = Object.assign(itemValue);
    this.selectedItemValue.selected = itemValue;
    if (this.selectedItemValue.orderDirection === "DESC") {
      this.selectedItemValue.orderDirection = "ASC";
    } else {
      this.selectedItemValue.orderDirection = "DESC";
    }
    this.$emit("input", this.selectedItemValue);
    this.$emit("change", this.selectedItemValue);
    this.pussOption = false;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue.selected == itemValue) {
      return "itemOption_PFormSelectOrder itemSelectSelected";
    } else {
      return "itemOption_PFormSelectOrder itemSelect";
    }
  }
}
export default PFormSelectOrder;
