import { RawAmountCosmos } from "./amountCosmos.types";

export class AmountCosmos {
  readonly currency: string;
  readonly total: number;
  readonly numItems: number;
  readonly isValid: boolean;
  readonly barCodes: string[];
  constructor(data: RawAmountCosmos) {
    this.currency = data.currency;
    this.total = data.total;
    this.numItems = data.numItems;
    this.isValid = data.isValid;
    this.barCodes = data.barCodes;
  }
}
