
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "modal",
  inheritAttrs: false,
})
/**
 *
 */
export class Modal extends Vue {
  @Prop({ required: true, default: "Title of modal" })
  title!: string;
  @Prop({ required: false, default: "md" })
  size!: string;
  @Prop({ required: false, default: "modal-content" })
  classModalContent!: string;
  @Prop({ required: false, default: true })
  hideFooter!: boolean;
  @Prop({ required: true })
  modalId!: string;
}
export default Modal;
