
import { Component, Vue, Prop } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { LogicCashTodayBalance } from "@/entities";

@Component({
  components: {
    PIcon,
  },
})
export default class LogicCashTodayDetailHeader extends Vue {
  @Prop() public logicCashTodayBalance!: LogicCashTodayBalance;
}
