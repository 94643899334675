
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export class ToastPack extends Vue {
  toggle = false;
  tooltipWidth = 0;

  handleMouseOver() {
    this.toggle = true;
    this.$nextTick(() => {
      const tooltip = this.$el.querySelector(".toast__content");
      const containerRect = this.$el.getBoundingClientRect();
      if (tooltip instanceof HTMLElement && containerRect) {
        this.tooltipWidth = tooltip.offsetWidth;
      }
    });
  }
}
export default ToastPack;
