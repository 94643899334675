import { RawEstablishment } from "./establishment.types";

export class Establishment {
  readonly id: string;
  readonly name: string;
  readonly numberOfMachines: number;

  constructor(data: RawEstablishment) {
    this.id = data?.id || "";
    this.name = data?.name || "";
    this.numberOfMachines = data?.numberOfMachines || 0;
  }
}
