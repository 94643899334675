
import ModalCancelation from "@/orders/components/ModalCancelation/index.vue";
import ModalUpdate from "@/orders/components/ModalUpdate/ModalUpdate.vue";
import Icon from "@/orders/components/Icon/index.vue";
import PRating from "@/orders/components/Aside/Rating/PRating.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CreateOrderAssistanceData,
  CreateOrderDeliveryChangeData,
  CreateOrderDeliveryData,
  CreateOrderPickupData,
  CreateOrderSupplyData,
  Order,
  OrderDTO,
  OrderStatus,
  RequestedDenomination,
} from "@/orders";
import { Getter } from "vuex-class";
import VueOrders from "@/orders/vue-orders";
import DateTimeMixin from "@/mixins/datetime";
import { RequestedCurrency, RequestedCurrencyData } from "@/orders/entities/requestedCurrency";

interface DenominationFormat {
  code: string;
  symbol: string;
  total: number;
}
@Component({
  components: {
    Icon,
    PRating,
    ModalCancelation,
    ModalUpdate,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export class PAside extends Vue {
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) order!: Order;
  @Getter("getStatuses") getStatuses!: OrderStatus[];
  orderDTO = {} as OrderDTO;

  eyes = require("@/orders/assets/icons/icon-eye-off.svg");
  statusExpand = false;
  openCancelation = false;
  openUpdate = false;

  created(): void {
    const _order = this.order;
    this.orderDTO = {
      commodity: _order.commodity,
      servicePoint: _order.servicePoint,
      country: _order.country,
      orderId: _order.orderId,
    };
    const commodity = this.orderDTO.commodity;

    switch (commodity.name) {
      case "PICKUP":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedCurrencies: this.toRequestedCurrencyData(_order.requestedCurrencies),
          hasPickupDeclared: _order.hasPickupDeclared,
        } as CreateOrderPickupData;
        break;
      case "ASSISTANCE":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          assistanceFailureId: _order.assistanceFailure!.id,
        } as CreateOrderAssistanceData;
        break;
      case "DELIVERY":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedDenominations: _order.requestedDenominations,
          requestedDenominationPacks: _order.requestedDenominationPacks,
        } as CreateOrderDeliveryData;
        break;
      case "DELIVERY_CHANGE":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedDenominations: _order.requestedDenominations,
          requestedDenominationPacks: _order.requestedDenominationPacks,
        } as CreateOrderDeliveryChangeData;
        break;
      case "SUPPLY":
        this.orderDTO.orderData = {
          comments: _order.comments,
          serviceDate: _order.serviceDate,
          requestInitialTime: _order.requestInitialTime,
          requestFinalTime: _order.requestFinalTime,
          servicePointId: _order.servicePoint.id,
          requestedSupplies: _order.requestedSupplies,
        } as CreateOrderSupplyData;
        break;
      default:
        console.error("Commodity not valid");
    }
  }

  closedModalCancelation() {
    this.openCancelation = false;
  }

  closedModalUpdate() {
    this.openUpdate = false;
  }

  async closeModalOnUpdate() {
    await this.$store.dispatch("fetchOrders");
    this.openUpdate = false;
  }

  closeModal() {
    this.$emit("closeModal");
  }

  openTripulation() {
    this.$emit("openTripulation", this.order);
  }

  formatPrice(price: number): string {
    return price.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  formatStateDate(code: string, order: Order): string {
    const dateString: string = this.namedStatus(code, order).date || "";
    return dateString === "" ? "" : this.formatDateSimple(dateString);
  }
  formatDateSimple(date: string): string {
    const dateToFormat = this.convertISODateStringToDate(date);
    return this.formatDateTime(dateToFormat, "dd/MM/yyyy")!;
  }

  sumTotalsByCode(items: RequestedDenomination[]): DenominationFormat[] {
    const resultMap: { [key: string]: { symbol: string; total: number } } = {};

    items.forEach((item) => {
      const { code, symbol } = item.denomination.currency;
      if (resultMap[code]) {
        resultMap[code].total += item.total;
      } else {
        resultMap[code] = { symbol, total: item.total };
      }
    });

    return Object.entries(resultMap).map(([code, { symbol, total }]) => ({ code, symbol, total }));
  }

  get statuses(): OrderStatus[] {
    return this.getStatuses;
  }

  toRequestedCurrencyData(requestedCurrencies: RequestedCurrency[]): RequestedCurrencyData[] {
    if (!requestedCurrencies) {
      return [];
    }
    return requestedCurrencies.map((value) => {
      return {
        currency: value.currency,
        quantity: value.total,
      };
    });
  }
}
export default PAside;
