
import { Component, Vue } from "vue-property-decorator";
import { DeviceDetails, LogicCashTodayBalance, LogicCashTodayLastTransaction, ServicePoint } from "@/entities";
import LogicCashTodayDetailHeader from "./components/LogicCashTodayDetailHeader.vue";
import LogicCashTodayDetailBalance from "./components/LogicCashTodayDetailBalance.vue";
import LogicCashTodayDetailLastTransactions from "./components/LogicCashTodayDetailLastTransactions.vue";
import LogicCashTodayDetailDeviceDetails from "./components/LogicCashTodayDetailDeviceDetails.vue";
import { ButtonBackToDashboard } from "@/common/components";

@Component({
  name: "service-point-detail-page",
  components: {
    LogicCashTodayDetailHeader,
    LogicCashTodayDetailBalance,
    LogicCashTodayDetailLastTransactions,
    LogicCashTodayDetailDeviceDetails,
    ButtonBackToDashboard,
  },
})
export default class LogicCashTodayDetailPage extends Vue {
  balanceId: string;
  logicCashTodayBalance: LogicCashTodayBalance | null = null;
  logicCashTodayLastTransactions: LogicCashTodayLastTransaction[];
  deviceDetails: DeviceDetails | null = null;
  servicePointId: string;
  servicePoint: ServicePoint | null = null;
  logicCashTodayId: string;

  constructor() {
    super();
    this.balanceId = this.$route.params.balanceId;
    this.logicCashTodayLastTransactions = [];
    this.logicCashTodayId = "-";
    this.servicePointId = "-";
  }

  created(): void {
    this.$services.logicCashTodayBalance.fetchLogicCashTodayBalanceById(this.balanceId).then((resp) => {
      this.logicCashTodayBalance = resp;
      this.logicCashTodayId = resp.logicCashTodayId;
      this.$services.logicCashTodayTransactions
        .fetchLogicCashTodayLastTransactionsByLogicCashTodayId(resp.logicCashTodayId)
        .then((resp2) => {
          this.logicCashTodayLastTransactions = resp2.logicCashTodayLastTransactions;
        });
      this.$services.servicePoints.fetchServicePointById(this.servicePointId).then((resp3: ServicePoint) => {
        this.servicePoint = resp3;
        if (this.servicePoint.deviceDetails) {
          this.deviceDetails = new DeviceDetails(this.servicePoint.deviceDetails);
        }
      });
      // this.$services.deviceDetails.fetchDeviceDetailsByServicePointId(resp.servicePointId).then((resp3) => {
      //   this.deviceDetails = resp3;
      // });
    });
  }
}
