import { BACKEND_ORDERS_BASE_URL } from "@/services";
import Vue from "vue";
import { CommoditiesService } from "./commodities.types";
import { OrderCommodities, RawOrderCommodities } from "@/orders";

export class CommoditiesServiceImpl implements CommoditiesService {
  async fetchCommodities(id: string): Promise<OrderCommodities[]> {
    const { data } = await Vue.$axios.get<RawOrderCommodities[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/commodities/service-point/${id}`
    );
    return data.map((value) => new OrderCommodities(value));
  }
}
