/* eslint-disable @typescript-eslint/no-explicit-any */
export class FieldPTable {
  [key: string]: any;
  key: string;
  type = FieldPTableType.TEXT;
  groupField?: string;
  label?: string;
  filter: string[] = [];
  fixedOrder = false;
  groupedField = false;
  fixColumn = false;
  show = true;
  showOrderField = false;
  showGroupedField = false;
  showHideField = false;
  showFilterField = false;
  width?: string;
  multipleValues = false;
  alwaysShow = false;
  formatter?: FormatterFieldType;
  formatterForFilter?: FormatterFieldType;
  constructor(args: FieldPTableParameters) {
    this.key = args.key;
    if (args.type) this.type = args.type;
    if (args.groupField) this.groupField = args.groupField;
    if (args.label) this.label = args.label;
    if (args.filter) this.filter = args.filter;
    if (args.fixedOrder) this.fixedOrder = args.fixedOrder;
    if (args.groupedField) this.groupedField = args.groupedField;
    if (args.fixColumn) this.fixColumn = args.fixColumn;
    if (args.show != undefined && args.show == false) this.show = false;
    if (args.showOrderField) this.showOrderField = args.showOrderField;
    if (args.showGroupedField) this.showGroupedField = args.showGroupedField;
    if (args.showHideField) this.showHideField = args.showHideField;
    if (args.showFilterField) this.showFilterField = args.showFilterField;
    if (args.width) this.width = args.width;
    if (args.multipleValues) this.multipleValues = args.multipleValues;
    if (args.formatter) this.formatter = args.formatter;
    if (args.formatterForFilter) this.formatterForFilter = args.formatterForFilter;
    if (args.alwaysShow){
      this.alwaysShow = args.alwaysShow;
      this.show = args.alwaysShow;
    }
  }
  static createAndMerge(field: FieldPTable, rawField: RawFieldPTable): FieldPTable {
    const newField = new FieldPTable(field);
    return Object.assign(newField, rawField);
  }
}

export interface FieldPTableParameters {
  key: string;
  type?: FieldPTableType;
  groupField?: string;
  label?: string;
  filter?: string[];
  fixedOrder?: boolean;
  groupedField?: boolean;
  fixColumn?: boolean;
  show?: boolean;
  showOrderField?: boolean;
  showGroupedField?: boolean;
  showHideField?: boolean;
  showFilterField?: boolean;
  width?: string;
  multipleValues?: boolean;
  formatter?: FormatterFieldType;
  formatterForFilter?: FormatterFieldType;
  alwaysShow?: boolean;
}

export enum FieldPTableType {
  ACTION = "action",
  TEXT = "text",
  NUMBER = "number",
  DATE = "date",
  HIDDEN = "hidden",
}
export interface FormatterFieldType {
  (value: any): string | undefined;
}
/**
 * This class is for store in database without calculated FieldPTable attributes
 */
export class RawFieldPTable {
  key: string;
  fixedOrder = false;
  groupedField = false;
  fixColumn = false;
  show = false;
  showOrderField = false;
  showGroupedField = false;
  showHideField = false;
  showFilterField = false;
  width?: string;
  constructor(args: RawFieldPTableParameters | FieldPTable) {
    this.key = args.key;
    if (args.fixedOrder) this.fixedOrder = args.fixedOrder;
    if (args.groupedField) this.groupedField = args.groupedField;
    if (args.fixColumn) this.fixColumn = args.fixColumn;
    if (args.show) this.show = args.show;
    else if (args.alwaysShow) this.show = args.alwaysShow;
    if (args.showOrderField) this.showOrderField = args.showOrderField;
    if (args.showGroupedField) this.showGroupedField = args.showGroupedField;
    if (args.showHideField) this.showHideField = args.showHideField;
    if (args.showFilterField) this.showFilterField = args.showFilterField;
    if (args.width) this.width = args.width;

  }
}

export interface RawFieldPTableParameters {
  key: string;
  fixedOrder?: boolean;
  groupedField?: boolean;
  fixColumn?: boolean;
  show?: boolean;
  showOrderField?: boolean;
  showGroupedField?: boolean;
  showHideField?: boolean;
  showFilterField?: boolean;
  width?: string;
  alwaysShow?: boolean;
}
export interface PaginationPTableType {
  pageSize: number;
  pageSizeDefault?: number;
  totalElements: number;
  currentPage: number;
}

export const iniPaginationPTable: PaginationPTableType = {
  pageSize: 20,
  totalElements: 0,
  currentPage: 1,
}

export interface OrderFieldType {
  field: string;
  direction: string;
}
export interface OrderPTableType {
  orderFields: OrderFieldType[];
  groupedFields: string[];
}

export interface PTableType {
  fields: FieldPTable[];
  defaultFields: FieldPTable[];
  pagination: PaginationPTableType;
  order: OrderPTableType;
  groupFields?: string[];
}

export enum EPTableAction {
  ASC,
  DESC,
  NO_ORDER,
  COMBINE,
  EXPAND,
  HIDE,
}

export enum EFilterPTable {
  ALL,
  NONE,
  SOME,
}

export interface FilterFieldPTable {
  key: string;
  filter: EFilterPTable;
  itemValues: any[];
}
