/* eslint-disable @typescript-eslint/no-explicit-any */
export const getUniqueObjectListBykey = (arr: any[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getUniqueValues = (arr: any[]) => {
  return arr.filter((value, index, array) => array.indexOf(value) === index);
};

export const mergeArrayUniqueValues = (arr1: any[], arr2: any[]) => {
  return Array.from(new Set([...arr1, ...arr2]));
};
