import { RawCountry } from "@/entities";

export class Country {
  readonly id: number;
  readonly code: string;
  readonly name: string;

  constructor(data: RawCountry) {
    this.id = data.id;
    this.code = data.isoCode;
    this.name = data.name;
  }
}
