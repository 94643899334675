
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/orders";
import VueOrders from "@/orders/vue-orders";
@Component({
  components: {},
  mixins: [VueOrders],
})
export class CardDetail extends Vue {
  @Prop({ required: true }) order!: Order;

  groupedDenominations() {
    return this.order.requestedDenominations.reduce((acc, { denomination: { currency }, total }) => {
      const { code, symbol } = currency;
      acc[code] = acc[code] || { total: 0, symbol };
      acc[code].total += total;
      return acc;
    }, {} as Record<string, { total: number; symbol: string }>);
  }

  openModalTripulation(): void {
    this.$emit("openTripulation", this.order);
  }

  openModalDetail(): void {
    this.$emit("openModalDetail", this.order);
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) return new Date(dateString.slice(0, index));
    return new Date(dateString);
  }

  dateFormated(fecha: string): string {
    const date = this.dateFromString(fecha);
    const month = date.toLocaleDateString("es-ES", { month: "long" });
    const day = date.toLocaleDateString("es-ES", { weekday: "long" });
    return day + ", " + date.getDate() + " " + month;
  }

  getStatusExecution(code: string): boolean {
    return code === "EXG" || code === "EXD";
  }
}
export default CardDetail;
