
import { Component, Vue } from "vue-property-decorator";
import {
  DeviceDetails,
  ServicePoint,
  ServicePointBalance,
  ServicePointLastTransaction,
  ServicePointLastTransactions,
} from "@/entities";
import ServicePointDetailHeader from "./components/ServicePointDetailHeader.vue";
import ServicePointDetailBalance from "./components/ServicePointDetailBalance.vue";
import ServicePointDetailLastTransactions from "./components/ServicePointDetailLastTransactions.vue";
import ServicePointDetailDeviceDetails from "./components/ServicePointDetailDeviceDetails.vue";
import { ButtonBackToDashboard } from "@/common/components";

@Component({
  name: "service-point-detail-page",
  components: {
    ServicePointDetailHeader,
    ServicePointDetailBalance,
    ServicePointDetailLastTransactions,
    ServicePointDetailDeviceDetails,
    ButtonBackToDashboard,
  },
})
export default class ServicePointDetailPage extends Vue {
  balanceId: string;
  servicePointBalance: ServicePointBalance | null = null;
  servicePointLastTransactions: ServicePointLastTransaction[];
  deviceDetails: DeviceDetails | null = null;
  servicePointId: string;
  servicePoint: ServicePoint | null = null;

  constructor() {
    super();
    this.servicePointLastTransactions = [];
    this.balanceId = this.$route.params.balanceId;
    this.servicePointId = "-";
  }

  created(): void {
    this.$services.servicePointBalance.fetchServicePointBalanceById(this.balanceId).then((resp) => {
      this.servicePointBalance = resp;
      this.servicePointId = resp.servicePointId;
      this.$services.servicePointTransactions
        .fetchServicePointLastTransactionsByServicePointId(this.servicePointId)
        .then((resp2: ServicePointLastTransactions) => {
          this.servicePointLastTransactions = resp2.servicePointLastTransactions;
        });
      this.$services.servicePoints.fetchServicePointById(this.servicePointId).then((resp3: ServicePoint) => {
        this.servicePoint = resp3;
        if (this.servicePoint.deviceDetails) {
          this.deviceDetails = new DeviceDetails(this.servicePoint.deviceDetails);
        }
      });
    });
  }
  get mustDisplayDetail(): boolean {
    return this.servicePointBalance?.deviceType !== "Recicladora" ?? false;
  }
}
