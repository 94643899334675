
import { Component, Vue, Prop } from "vue-property-decorator";
import { SwitcherType } from "./switcher.types";

@Component({
  name: "switcher",
})
export class Switcher extends Vue {
  @Prop() public items!: Array<SwitcherType>;
  @Prop() onClick!: (selectedId: string) => void;
  @Prop({ default: "small" }) widthType!: "small" | "big";

  constructor() {
    super();
  }

  handleOnClick(selectedId: string): void {
    this.items.forEach((el) => {
      el.selected = false;
      if (el.id === selectedId) {
        el.selected = true;
      }
    });
    if (this.onClick) {
      this.onClick(selectedId);
    }
  }
}
export default Switcher;
