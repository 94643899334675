import { RawCrew } from "@/orders/entities/crew/crew.types";
import { Employee, Fleet } from "@/orders";

export class Crew {
  readonly fleet: Fleet;
  readonly employees: Employee[];

  constructor(data: RawCrew) {
    this.fleet = new Fleet(data.fleet);
    this.employees = data.employees.map((value) => new Employee(value));
  }
}
