
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import ComparatorInput from "@/orders/components/ComparatorInput/ComparatorInput.vue";
import PDropdown from "@/orders/components/Dropdown/Dropdown.vue";
import {
  AmountByDenomination,
  Currency,
  Denomination,
  DenominationData,
  DenominationFromCurrencyCode,
  DenominationPack,
  DenominationPackData,
  DenominationPackFromCurrencyCode,
  DenominationPackType,
  DenominationType,
  OrderDeliveryData,
  OrderDTO,
  RequestedDenomination,
  RequestedDenominationPack,
} from "@/orders/entities";
import TableDelivery from "@/orders/components/Delivery/TableDelivery.vue";
import { PButton } from "@/common/components";
import CardButton from "@/orders/components/Commons/CardButton.vue";
import Utils from "@/orders/components/Commons/utils";
import { Semaphore } from "@/orders/components/Commons/semaphore";
import TableDeliveryPack from "../../Delivery/TableDeliveryPack.vue";

@Component({
  components: {
    CardButton,
    TableDelivery,
    TableDeliveryPack,
    Icon,
    ComparatorInput,
    PDropdown,
    PButton,
  },
})
export default class StepDeliveryChange extends Vue {
  // Props
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  @Prop({ required: true }) value!: OrderDeliveryData;
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  semaphore = new Semaphore();

  isLoaded = false;
  addDenominations = false;

  selectCurrency = "";
  currentPack = "";
  currencies: Currency[] = [];
  currenciesRemoved: Currency[] = [];
  currencyPrincipal: Currency | undefined = undefined;
  denominationFromCurrencyCode: DenominationFromCurrencyCode[] = [];
  denominationPackFromCurrencyCode: DenominationPackFromCurrencyCode[] = [];
  listSubtotalsInBills: AmountByDenomination[] = [];
  listSubtotalsInCoins: AmountByDenomination[] = [];
  listSubtotalsInPacks: AmountByDenomination[] = [];
  totalByCurrencies: AmountByDenomination[] = [];
  total = 0;

  async created(): Promise<void> {
    // se realiza una copia del objeto value porque al momento de incializar atributo
    // denominationFromCurrencyCode esta escuchando sobre cualquier cambio que se realice
    // sobre si mismo y actualizando this.value
    const copyFromValue = { value: this.value };
    await this.semaphore.acquire(
      async () => {
        await this.setupCurrenciesAndDenominations();
      },
      async () => {
        await this.setupRequestedDenominationFromValue(copyFromValue.value);
      },
      async () => {
        await this.setupRequestedDenominationPackFromValue(copyFromValue.value);
      }
    );
  }

  async setupRequestedDenominationFromValue(deliveryData: OrderDeliveryData): Promise<void> {
    if (this.isLoaded && deliveryData) {
      const requestedDenominations = deliveryData.requestedDenominations;
      for (const requestedDenomination of requestedDenominations) {
        await this.processRequestedDenomination(requestedDenomination);
      }
    }
  }

  async setupRequestedDenominationPackFromValue(deliveryData: OrderDeliveryData): Promise<void> {
    if (this.isLoaded && deliveryData) {
      const requestedDenominationPacks = deliveryData.requestedDenominationPacks;
      for (const requestedDenominationPack of requestedDenominationPacks) {
        await this.processRequestedDenominationPack(requestedDenominationPack);
      }
    }
  }

  async processRequestedDenomination(requestedDenomination: RequestedDenomination): Promise<void> {
    const denominationData = this.mapRequestedDenominationToDenominationData(requestedDenomination);
    const currencyCode = denominationData.currency.code;
    const found = await this.findOrCreateDenomination(currencyCode);
    this.updateDenominationValue(found, denominationData);
    this.setupTotalsAndSubtotalsFromValue(found);
  }

  async processRequestedDenominationPack(requestedDenominationPack: RequestedDenominationPack): Promise<void> {
    const denominationPackData = this.mapRequestedDenominationPackToDenominationPackData(requestedDenominationPack);
    const currencyCode = denominationPackData.currency.code;
    const found = await this.findOrCreateDenominationPack(currencyCode);
    this.updateDenominationPackValue(found, denominationPackData);
    this.setupTotalsAndSubtotalsFromValuePack(found);
  }

  async findOrCreateDenomination(currencyCode: string): Promise<DenominationFromCurrencyCode> {
    let found = this.denominationFromCurrencyCode.find(
      (denominationFromCurrencyCode) => denominationFromCurrencyCode.currencyCode === currencyCode
    );
    if (!found) {
      const denominations = await this.getDenominationsData(currencyCode);
      found = { currencyCode, denominations };
      this.denominationFromCurrencyCode.push(found);
    }
    return found;
  }

  async findOrCreateDenominationPack(currencyCode: string): Promise<DenominationPackFromCurrencyCode> {
    let found = this.denominationPackFromCurrencyCode.find(
      (denominationPackFromCurrencyCode) => denominationPackFromCurrencyCode.currencyCode === currencyCode
    );
    if (!found) {
      const denominationsPacks = await this.getDenominationsPacksData(currencyCode);
      found = { currencyCode, denominationsPacks };
      this.denominationPackFromCurrencyCode.push(found);
    }
    return found;
  }

  updateDenominationValue(found: DenominationFromCurrencyCode, denominationData: DenominationData): void {
    found.denominations.find((denomination) => {
      if (denomination.id === denominationData.id) {
        denomination.denominationValue = denominationData.denominationValue;
      }
    });
  }

  updateDenominationPackValue(
    found: DenominationPackFromCurrencyCode,
    denominationPackData: DenominationPackData
  ): void {
    found.denominationsPacks.find((denominationPack) => {
      if (denominationPack.id === denominationPackData.id) {
        denominationPack.denominationValue = denominationPackData.denominationValue;
      }
    });
  }

  setupTotalsAndSubtotalsFromValue(fromCurrency: DenominationFromCurrencyCode) {
    const type = this.getTypeDenominations(fromCurrency);
    for (const denominationType of type) {
      const denominations = fromCurrency.denominations.filter((denomination) => denomination.type === denominationType);
      const subtotal = this.calculateSubtotal(denominations, denominationType);
      if (denominationType === DenominationType.BILLS.description) {
        this.updateSubtotalList(this.listSubtotalsInBills, fromCurrency.currencyCode, subtotal);
      } else if (denominationType === DenominationType.COINS.description) {
        this.updateSubtotalList(this.listSubtotalsInCoins, fromCurrency.currencyCode, subtotal);
      }
    }
    this.total = this.calculateTotal();
  }

  setupTotalsAndSubtotalsFromValuePack(fromCurrencyPack: DenominationPackFromCurrencyCode) {
    const type = this.getTypeDenominationsPacks(fromCurrencyPack);
    for (const denominationType of type) {
      const denominationsPacks = fromCurrencyPack.denominationsPacks.filter(
        (denominationPack) => denominationPack.type === denominationType
      );
      const subtotal = this.calculateSubtotalPack(denominationsPacks, denominationType);
      if (denominationType === DenominationPackType.PACKS.description) {
        this.updateSubtotalList(this.listSubtotalsInPacks, fromCurrencyPack.currencyCode, subtotal);
      }
    }
    this.total = this.calculateTotal();
  }

  getTypeDenominations(fromCurrency: DenominationFromCurrencyCode): string[] {
    const typeFromArray = fromCurrency.denominations.map((denomination) => denomination.type);
    return Array.from(new Set(typeFromArray));
  }

  getTypeDenominationsPacks(fromCurrencyPack: DenominationPackFromCurrencyCode): string[] {
    const typeFromArray = fromCurrencyPack.denominationsPacks.map((denominationPack) => denominationPack.type);
    return Array.from(new Set(typeFromArray));
  }

  async setupCurrenciesAndDenominations() {
    const countryCode = this.notChangeOrder.country.code;
    this.currencies = await this.$services.order.fetchOrderCurrencies(countryCode);
    this.currencyPrincipal = this.currencies.find((currency) => currency.principalCurrency);

    if (this.currencyPrincipal) {
      const currencyCode = this.currencyPrincipal.code;
      const denominations = await this.getDenominationsData(currencyCode);
      const denominationsPacks = await this.getDenominationsPacksData(countryCode);
      const denominationFromCurrencyCode: DenominationFromCurrencyCode = {
        currencyCode,
        denominations,
      };
      const denominationPackFromCurrencyCode: DenominationPackFromCurrencyCode = {
        currencyCode,
        denominationsPacks,
      };
      this.denominationFromCurrencyCode.push(denominationFromCurrencyCode);
      this.denominationPackFromCurrencyCode.push(denominationPackFromCurrencyCode);
    }
    this.isLoaded = true;
  }

  get currenciesCode() {
    return this.currencies.map((currency) => currency.code);
  }

  async onChangeCurrency(currencyCode: string): Promise<void> {
    const denominations = await this.getDenominationsData(currencyCode);
    const denominationFromCurrencyCode = this.denominationFromCurrencyCode.find(
      (denominationFromCurrencyCode) => denominationFromCurrencyCode.currencyCode === currencyCode
    );
    if (denominationFromCurrencyCode) {
      this.verifySubtotalsOnDenominationFromCurrency();
      denominationFromCurrencyCode.denominations = denominations;
    } else {
      const denominationFromCurrencyCode: DenominationFromCurrencyCode = {
        currencyCode,
        denominations,
      };
      this.denominationFromCurrencyCode.push(denominationFromCurrencyCode);
    }
    this.addDenominations = false;
  }

  onAddDenominations(): void {
    const existingCurrencyCodes = new Set(
      this.denominationFromCurrencyCode.map((denomination) => denomination.currencyCode)
    );

    this.currencies = this.currencies.filter((currency) => {
      const found = existingCurrencyCodes.has(currency.code);
      if (found) {
        this.currenciesRemoved.push(currency);
      }
      return !found;
    });

    this.selectCurrency = this.currencies[0].code || "";
    this.addDenominations = true;
  }

  onDeleteDenominations(currencyCode: string): void {
    if (this.denominationFromCurrencyCode.length === 1) {
      return;
    }
    this.denominationFromCurrencyCode = this.denominationFromCurrencyCode.filter(
      (denomination) => denomination.currencyCode !== currencyCode
    );
    const currencyRemoved = this.currenciesRemoved.find((currency) => currency.code === currencyCode);
    if (currencyRemoved) {
      this.currencies.push(currencyRemoved);
      this.currenciesRemoved = this.currenciesRemoved.filter((currency) => currency.code !== currencyCode);
    }
    this.verifySubtotalsOnDenominationFromCurrency();
  }

  onUpdateDenominations(fromCurrencyCode: DenominationFromCurrencyCode): void {
    const type = this.evaluateCurrentPackTranslate(this.currentPack);
    const subtotal = this.calculateSubtotal(fromCurrencyCode.denominations, type);

    this.verifySubtotalsOnDenominationFromCurrency();
    if (this.currentPack === "bills") {
      this.updateSubtotalList(this.listSubtotalsInBills, fromCurrencyCode.currencyCode, subtotal);
    } else if (this.currentPack === "coins") {
      this.updateSubtotalList(this.listSubtotalsInCoins, fromCurrencyCode.currencyCode, subtotal);
    }

    this.total = this.calculateTotal();
  }

  onUpdateDenominationsPacks(fromCurrencyCodePack: DenominationPackFromCurrencyCode): void {
    const subtotal = this.calculateSubtotalPack(
      fromCurrencyCodePack.denominationsPacks,
      fromCurrencyCodePack.currencyCode
    );
    this.verifySubtotalsOnDenominationFromCurrency();
    this.updateSubtotalList(this.listSubtotalsInPacks, fromCurrencyCodePack.currencyCode, subtotal);
    this.total = this.calculateTotal();
  }

  verifySubtotalsOnDenominationFromCurrency(): void {
    const existingCurrencyCodes = new Set(
      this.denominationFromCurrencyCode.map((denomination) => denomination.currencyCode)
    );
    const existingCurrencyCodesPack = new Set(
      this.denominationPackFromCurrencyCode.map((denomination) => denomination.currencyCode)
    );
    this.listSubtotalsInBills = this.listSubtotalsInBills.filter((subtotal) => {
      return existingCurrencyCodes.has(subtotal.currencyCode);
    });
    this.listSubtotalsInCoins = this.listSubtotalsInCoins.filter((subtotal) => {
      return existingCurrencyCodes.has(subtotal.currencyCode);
    });
    this.listSubtotalsInPacks = this.listSubtotalsInPacks.filter((subtotal) => {
      return existingCurrencyCodesPack.has(subtotal.currencyCode);
    });
    this.verifyTotalsByCurrencies();
  }

  calculateTotal(): number {
    const totalInBills = this.listSubtotalsInBills.reduce((acc, subtotal) => acc + subtotal.subtotal, 0);
    const totalInCoins = this.listSubtotalsInCoins.reduce((acc, subtotal) => acc + subtotal.subtotal, 0);
    const totalInPacks = this.listSubtotalsInPacks.reduce((acc, subtotal) => acc + subtotal.subtotal, 0);
    this.verifyTotalsByCurrencies();
    return totalInBills + totalInCoins + totalInPacks;
  }

  verifyTotalsByCurrencies(): void {
    const allCurrencies = new Set([
      ...this.listSubtotalsInBills.map((bills) => bills.currencyCode),
      ...this.listSubtotalsInCoins.map((coins) => coins.currencyCode),
      ...this.listSubtotalsInPacks.map((packs) => packs.currencyCode),
    ]);
    this.totalByCurrencies = Array.from(allCurrencies).map((currencyCode) => {
      const bills = this.listSubtotalsInBills.find((b) => b.currencyCode === currencyCode);
      const coins = this.listSubtotalsInCoins.find((c) => c.currencyCode === currencyCode);
      const packs = this.listSubtotalsInPacks.find((p) => p.currencyCode === currencyCode);
      let subtotal = 0;
      if (bills) {
        subtotal += bills.subtotal;
      }
      if (coins) {
        subtotal += coins.subtotal;
      }
      if (packs) {
        subtotal += packs.subtotal;
      }
      return { currencyCode, subtotal };
    });
  }

  updateSubtotalList(list: AmountByDenomination[], currencyCode: string, subtotal: number): void {
    const item = list.find((subtotal) => subtotal.currencyCode === currencyCode);
    if (item) {
      item.subtotal = subtotal;
    } else {
      list.push({ currencyCode, subtotal });
    }
  }

  calculateSubtotal(denominations: DenominationData[], type: string): number {
    return denominations
      .filter((denomination) => denomination.type === type)
      .reduce((acc, denomination) => acc + denomination.denominationValue.valueUpdated, 0);
  }

  calculateSubtotalPack(denominationsPacks: DenominationPackData[], currencyCode: string): number {
    return denominationsPacks
      .filter((denominationPack) => denominationPack.currency.code === currencyCode)
      .reduce((acc, denominationPack) => acc + denominationPack.denominationValue.valueUpdated, 0);
  }

  @Watch("denominationFromCurrencyCode", { deep: true })
  @Watch("denominationPackFromCurrencyCode", { deep: true })
  updateDeliveryData(): void {
    let listRequestedDenomination: RequestedDenomination[] = [];
    let listRequestedDenominationPack: RequestedDenominationPack[] = [];
    this.denominationFromCurrencyCode.forEach((denominationFromCurrencyCode) => {
      const requestedDenomination: RequestedDenomination[] = denominationFromCurrencyCode.denominations
        .filter((denomination) => denomination.denominationValue.quantity > 0)
        .map((denomination) => {
          return {
            quantity: denomination.denominationValue.quantity,
            total: denomination.denominationValue.valueUpdated,
            denomination,
          };
        });
      listRequestedDenomination = [...listRequestedDenomination, ...requestedDenomination];
    });
    this.denominationPackFromCurrencyCode.forEach((denominationPackFromCurrencyCode) => {
      const requestedDenominationPacks: RequestedDenominationPack[] =
        denominationPackFromCurrencyCode.denominationsPacks
          .filter((denominationPack) => denominationPack.denominationValue.quantity > 0)
          .map((denominationPack) => {
            return {
              quantity: denominationPack.denominationValue.quantity,
              total: denominationPack.denominationValue.valueUpdated,
              denominationPack,
            };
          });
      listRequestedDenominationPack = [...listRequestedDenominationPack, ...requestedDenominationPacks];
    });
    const newDeliveryData: OrderDeliveryData = {
      ...this.value,
      requestedDenominationPacks: listRequestedDenominationPack,
      requestedDenominations: listRequestedDenomination,
      totalPrice: this.total,
    };
    this.$emit("input", newDeliveryData);
  }

  evaluateCurrentPackTranslate(typeDenomination: string): string {
    switch (typeDenomination) {
      case DenominationType.BILLS.value:
        return DenominationType.BILLS.description;
      case DenominationType.COINS.value:
        return DenominationType.COINS.description;
      default:
        return typeDenomination;
    }
  }

  mapDenominationsToDenominationsData(denominations: Denomination[]): DenominationData[] {
    return denominations.map((denomination) => ({
      ...denomination,
      expand: false,
      denominationValue: { quantity: 0, valueUpdated: 0 },
    }));
  }

  mapDenominationsPacksToDenominationsPackData(denominationsPacks: DenominationPack[]): DenominationPackData[] {
    return denominationsPacks.map((denominationPack) => ({
      ...denominationPack,
      expand: false,
      type: DenominationPackType.PACKS.description,
      denominationValue: { quantity: 0, valueUpdated: 0 },
    }));
  }

  mapRequestedDenominationToDenominationData(requestedDenominations: RequestedDenomination): DenominationData {
    return {
      id: requestedDenominations.denomination.id,
      code: requestedDenominations.denomination.code,
      type: requestedDenominations.denomination.type,
      description: requestedDenominations.denomination.description,
      value: requestedDenominations.denomination.value,
      currency: requestedDenominations.denomination.currency,
      expand: false,
      denominationValue: {
        quantity: requestedDenominations.quantity,
        valueUpdated: requestedDenominations.total,
      },
    };
  }

  mapRequestedDenominationPackToDenominationPackData(
    requestedDenominationsPack: RequestedDenominationPack
  ): DenominationPackData {
    return {
      id: requestedDenominationsPack.denominationPack.id,
      code: requestedDenominationsPack.denominationPack.code,
      description: requestedDenominationsPack.denominationPack.description,
      commercialDescription: requestedDenominationsPack.denominationPack.commercialDescription,
      total: requestedDenominationsPack.denominationPack.total,
      active: requestedDenominationsPack.denominationPack.active,
      currency: requestedDenominationsPack.denominationPack.currency,
      packAmounts: requestedDenominationsPack.denominationPack.packAmounts,
      expand: false,
      type: DenominationPackType.PACKS.description,
      denominationValue: {
        quantity: requestedDenominationsPack.quantity,
        valueUpdated: requestedDenominationsPack.total,
      },
    };
  }

  changePack(pack: string): void {
    this.currentPack = this.currentPack === pack ? "" : pack;
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  async getDenominationsData(currencyCode: string): Promise<DenominationData[]> {
    const resDenominations = await this.$services.order.fetchOrderDenominations(currencyCode);
    return this.mapDenominationsToDenominationsData(resDenominations);
  }

  async getDenominationsPacksData(countryCode: string): Promise<DenominationPackData[]> {
    const resDenominationsPacks = await this.$services.order.fetchOrderDenominationPacks(countryCode);
    return this.mapDenominationsPacksToDenominationsPackData(resDenominationsPacks);
  }
}
