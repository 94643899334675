import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { RawI18n, I18nService } from "./i18n.types";

export class I18nServiceImpl implements I18nService {
  async fetchI18n(): Promise<RawI18n> {
    const { data } = await Vue.$axios.get<RawI18n>(`${BACKEND_BASE_URL}/api/configuration/v1/i18n`);
    return data;
  }
}
