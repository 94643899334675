import { RawLogicCashTodayLastTransaction, RawLogicCashTodayLastTransactions } from "@/entities";
import { LocalDateTime } from "@js-joda/core";

export class LogicCashTodayLastTransaction {
  readonly tellerName: string;
  readonly amount: number;
  readonly currency: string;
  readonly transactionType: string;
  readonly certificationDate: LocalDateTime;
  constructor(data: RawLogicCashTodayLastTransaction) {
    this.tellerName = data.tellerName;
    this.amount = data.amount;
    this.currency = data.currency;
    this.transactionType = data.transactionType;
    this.certificationDate = LocalDateTime.parse(data.certificationDate);
  }
}

export class LogicCashTodayLastTransactions {
  readonly logicCashTodayLastTransactions: LogicCashTodayLastTransaction[];
  constructor(data: RawLogicCashTodayLastTransactions) {
    const logicCashTodayLastTransactions: LogicCashTodayLastTransaction[] = [];
    data.logicCashTodayLastTransactions.forEach((rawData) =>
      logicCashTodayLastTransactions.push(new LogicCashTodayLastTransaction(rawData))
    );
    this.logicCashTodayLastTransactions = logicCashTodayLastTransactions;
  }
}
