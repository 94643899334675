import { RawCountries, RawCountry } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { CountryService } from "./country.types";

export class CountryServiceImpl implements CountryService {
  async fetchCountries(): Promise<RawCountry[]> {
    const { data } = await Vue.$axios.get<RawCountries>(`${BACKEND_BASE_URL}/api/configuration/v1/country`);

    return data.countries;
  }
}
