import { RawLogicCashToday, RawLogicCashTodays } from "@/entities";

export class LogicCashToday {
  readonly id: string;
  readonly name: string;
  readonly isEarlyValue: boolean;
  readonly center: typeof LogicCashToday.Center.prototype;

  constructor(data: RawLogicCashToday) {
    this.id = data?.aggregateId || "";
    this.name = data?.name || "";
    this.isEarlyValue = data?.isEarlyValue || false;
    this.center = new LogicCashToday.Center({ code: data.centerCode, name: data.centerName });
  }

  static Center = class {
    readonly code: string;
    readonly name: string;
    constructor(args: { code: string; name: string }) {
      this.code = args?.code || "";
      this.name = args?.name || "";
    }
  };
}

export class LogicCashTodays {
  readonly logicCashTodays?: LogicCashToday[];
  constructor(data: RawLogicCashTodays) {
    const logicCashTodays: LogicCashToday[] = [];
    data.logicCashTodays.forEach((lct) => {
      logicCashTodays.push(new LogicCashToday(lct));
    });
    this.logicCashTodays = logicCashTodays;
  }
}
