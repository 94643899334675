import { Teller } from "./teller";
import { RawTeller } from "./teller.types";

export const mockRawTeller = (): RawTeller => ({
  teller: "quia",
  login: "non",
  name: "in",
});

export const mockTeller = (data: RawTeller = mockRawTeller()): Teller => new Teller(data);
