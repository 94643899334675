import { parseLocalDateTime } from "@/common/utils";
import { RawLogicCashTodayBalance, RawLogicCashTodayBalances, RawLogicCashTodayBalanceBalance } from "@/entities";
import { LocalDateTime } from "@js-joda/core";

export class LogicCashTodayBalanceBalance {
  readonly id: string;
  readonly noteCapacity: number;
  readonly coinCapacity: number;
  readonly currency: string;
  readonly todayDepositAmount: number;
  constructor(data: RawLogicCashTodayBalanceBalance) {
    this.id = data.id;
    this.noteCapacity = data.noteCapacity;
    this.coinCapacity = data.coinCapacity;
    this.currency = data.currency;
    this.todayDepositAmount = data.todayDepositAmount;
  }
}

export class LogicCashTodayBalance {
  readonly id: string;
  readonly logicCashTodayId: string;
  readonly logicCashTodayName: string;
  readonly servicePointId: string;
  readonly servicePointName: string;
  readonly cashTodayType: string;
  readonly centerName: string;
  readonly maxNoteCapacity: number;
  readonly maxCoinCapacity: number;
  readonly deviceFillPercentage: number;
  readonly capacityEnabled: boolean;
  readonly deviceType: string;
  readonly isEarlyValue: boolean;
  readonly lastCertificationDate: LocalDateTime | undefined;
  readonly lastTransactionDate: LocalDateTime | undefined;
  readonly solutionType: string;
  readonly balances: LogicCashTodayBalanceBalance[];
  constructor(data: RawLogicCashTodayBalance) {
    this.id = data.id;
    this.logicCashTodayId = data.logicCashTodayId;
    this.logicCashTodayName = data.logicCashTodayName;
    this.servicePointId = data.servicePointId;
    this.servicePointName = data.servicePointName;
    this.cashTodayType = data.cashTodayType;
    this.centerName = data.centerName;
    this.maxNoteCapacity = data.maxNoteCapacity;
    this.maxCoinCapacity = data.maxCoinCapacity;
    this.deviceFillPercentage = data.deviceFillPercentage;
    this.capacityEnabled = data.capacityEnabled;
    this.deviceType = data.deviceType;
    this.isEarlyValue = data.isEarlyValue;
    this.lastCertificationDate = parseLocalDateTime(data.lastCertificationDate);
    this.lastTransactionDate = parseLocalDateTime(data.lastTransactionDate);
    this.solutionType = data.solutionType;
    this.balances = data.balances.map((el: RawLogicCashTodayBalanceBalance) => new LogicCashTodayBalanceBalance(el));
  }
}

export class LogicCashTodayBalances {
  readonly logicCashTodayBalances: LogicCashTodayBalance[] = [];
  constructor(data: RawLogicCashTodayBalances) {
    const logicCashTodayBalances: LogicCashTodayBalance[] = [];
    data.logicCashTodayBalances.forEach((rawData) => logicCashTodayBalances.push(new LogicCashTodayBalance(rawData)));
    this.logicCashTodayBalances = logicCashTodayBalances;
  }
}
