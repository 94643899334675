import { RawUserPreferences } from "./user-preferences.types";
import { UserPreferences } from "./user-preferences";

export const mockRawUserPreferences = (): RawUserPreferences => {
  return {
    transactionsPage: { fields: [], pagination: { pageSize: 78 } },
  };
};

export const mockUserPreferences = (data: RawUserPreferences = mockRawUserPreferences()): UserPreferences =>
  new UserPreferences(data);
