import { RawTeller } from "@/entities/teller/teller.types";

export class Teller {
  readonly teller: string;
  readonly login: string;
  readonly name: string;

  constructor(data: RawTeller | null) {
    this.teller = data?.teller || "";
    this.login = data?.login || "";
    this.name = data?.name || "";
  }
}
