import { Entity, ServicePointCosmos, TransactionCosmos } from "@/entities";
import { TransactionCosmosDetail } from "@/entities/transactionCosmos/transactionCosmosDetail";

export class TransactionCosmosItem {
  readonly id: string;
  readonly transactionDate: string;
  readonly servicePoint?: ServicePointCosmos;
  readonly entity: Entity;
  readonly tellerName: string;
  readonly transactionType: string;
  readonly amount: { numItems: number; currency: string; total: number };
  readonly transactionRef: string;
  readonly transactionInfo: string;
  readonly channel: string;
  readonly barCodes: string;
  readonly strapSealCode: string;
  readonly delegation: string;
  readonly accountingDate: string;
  readonly deviceDetails: { deviceCode?: string };
  readonly collectionId: string;
  readonly actualId: string;
  readonly teller: string;
  readonly tellerLogin: string;
  readonly receiptNumber: string;
  readonly origin: string;
  readonly transactionDetails: TransactionCosmosDetail;
  readonly multiline: boolean;

  constructor(data: TransactionCosmos, multiline = false) {
    this.id = data.id;
    this.transactionDate = data.transactionDate;
    this.accountingDate = data.accountingDate;
    this.actualId = data.actualId;
    this.collectionId = data.collectionId;
    this.servicePoint = data.servicePoint;
    this.entity = data.entity;
    this.teller = data.teller.teller;
    this.tellerLogin = data.teller.login;
    this.tellerName = data.teller.name;
    this.transactionType = data.transactionType;
    this.transactionDetails = data.transactionDetails[0];
    this.delegation = data.delegationCode;
    this.channel = data.channel;
    this.strapSealCode = data.strapSealCode;
    this.receiptNumber = data.receiptNumber;
    this.transactionRef = data.transactionRef;
    this.transactionInfo = data.transactionInfo;
    this.origin = this.transactionDetails.origin;
    this.barCodes = this.transactionDetails.amount.barCodes.join(" ");
    this.amount = {
      currency: this.transactionDetails.amount.currency,
      numItems: this.transactionDetails.amount.numItems,
      total: this.transactionDetails.amount.total,
    };
    this.deviceDetails = { deviceCode: this.servicePoint?.code };
    this.multiline = multiline;
  }
}
