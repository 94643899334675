import { parseLocalDateTime } from "@/common/utils";
import { RawServicePointBalance, RawServicePointBalanceBalance, RawServicePointBalances } from "@/entities";
import { LocalDateTime, ZonedDateTime, ZoneId } from "@js-joda/core";

export class ServicePointBalanceBalance {
  readonly id: string;
  readonly noteCapacity: number;
  readonly coinCapacity: number;
  readonly currency: string;
  readonly currentDepositAmount: number;
  readonly todayDepositAmount: number;
  readonly lastTransactionDate?: LocalDateTime;
  readonly deviceTimeZone?: string;

  constructor(data: RawServicePointBalanceBalance) {
    this.id = data.id;
    this.noteCapacity = data.noteCapacity;
    this.coinCapacity = data.coinCapacity;
    this.currency = data.currency;
    this.currentDepositAmount = data.currentDepositAmount;
    this.lastTransactionDate = parseLocalDateTime(data.lastTransactionDate);
    this.deviceTimeZone = data.deviceTimeZone;
    this.todayDepositAmount = this.getTodayDepositAmount(data);
  }

  private getTodayDepositAmount(data: RawServicePointBalanceBalance): number {
    if (!this.lastTransactionDate || !data.deviceTimeZone) {
      return 0;
    }

    const nowAtDiviceTimeZone = ZonedDateTime.now(ZoneId.of(data.deviceTimeZone));
    const nowOn24hAtDiviceTimeZone = nowAtDiviceTimeZone.plusHours(24);
    const nowAtDiviceTimeZone0h = ZonedDateTime.now(ZoneId.of(data.deviceTimeZone)).withHour(0).withSecond(0);
    const isTodayDepositAmount = this.lastTransactionDate.isAfter(nowAtDiviceTimeZone0h.toLocalDateTime());
    const isTomorrowBefore24hDepositAmount = this.lastTransactionDate.isBefore(
      nowOn24hAtDiviceTimeZone.toLocalDateTime()
    );

    return isTodayDepositAmount && isTomorrowBefore24hDepositAmount ? data.todayDepositAmount : 0;
  }
}
export class ServicePointBalance {
  readonly id: string;
  readonly servicePointId: string;
  readonly servicePointName: string;
  readonly centerName: string;
  readonly entityName: string;
  readonly maxNoteCapacity: number;
  readonly maxCoinCapacity: number;
  readonly deviceFillPercentage: number;
  readonly capacityEnabled: boolean;
  readonly cashTodayType: string;
  readonly deviceCode: string;
  readonly deviceType: string;
  readonly deviceTimeZone: string;
  readonly solutionType: string;
  readonly lastCertificationDate: LocalDateTime | undefined;
  readonly lastTransactionDate: LocalDateTime | undefined;
  readonly isEarlyValue: boolean;
  readonly balances: Array<ServicePointBalanceBalance>;
  constructor(data: RawServicePointBalance) {
    this.id = data.id;
    this.servicePointId = data.servicePointId;
    this.servicePointName = data.servicePointName;
    this.centerName = data.centerName;
    this.entityName = data.entityName;
    this.maxNoteCapacity = data.maxNoteCapacity;
    this.maxCoinCapacity = data.maxCoinCapacity;
    this.deviceFillPercentage = Number(data.deviceFillPercentage);
    this.capacityEnabled = data.capacityEnabled;
    this.cashTodayType = data.cashTodayType;
    this.deviceCode = data.deviceCode;
    this.deviceType = data.deviceType;
    this.deviceTimeZone = data.deviceTimeZone;
    this.solutionType = data.solutionType;
    this.lastCertificationDate = parseLocalDateTime(data.lastCertificationDate);
    this.lastTransactionDate = parseLocalDateTime(data.lastTransactionDate);
    this.isEarlyValue = data.isEarlyValue || false;
    this.balances = data.balances.map((el: RawServicePointBalanceBalance) => new ServicePointBalanceBalance(el));
  }
}

export class ServicePointBalances {
  readonly servicePointBalances: ServicePointBalance[];
  constructor(data: RawServicePointBalances) {
    const servicePointBalances: ServicePointBalance[] = [];
    data.servicePointBalances.forEach((rawData) => servicePointBalances.push(new ServicePointBalance(rawData)));
    this.servicePointBalances = servicePointBalances;
  }
}
