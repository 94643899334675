import { BACKEND_BASE_URL_READS } from "@/services";
import Vue from "vue";
import { ServicePointLastTransactions, RawServicePointLastTransactions } from "@/entities";
import { ServicePointTransactionsService } from "./servicePointTransactions.types";

export class ServicePointTransactionsServiceImpl implements ServicePointTransactionsService {
  async fetchServicePointLastTransactionsByServicePointId(id: string): Promise<ServicePointLastTransactions> {
    const { data } = await Vue.$axios.get<RawServicePointLastTransactions>(
      `${BACKEND_BASE_URL_READS}/api/v1/servicePoint/${id}/lastTransactions`
    );
    return new ServicePointLastTransactions(data);
  }
}
