import { RawEntity } from "@/entities";

export class Entity {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly imageLink: string;
  readonly countryCode: string;

  constructor(data: RawEntity | null) {
    this.id = data?.id || "";
    this.code = data?.code || "";
    this.name = data?.name || "";
    this.imageLink = data?.imageLink || "";
    this.countryCode = data?.countryCode || "";
  }
}
