export type RawServicePointBalances = {
  readonly servicePointBalances: RawServicePointBalance[];
};

export type RawServicePointBalance = {
  readonly id: string;
  readonly servicePointId: string;
  readonly servicePointName: string;
  readonly centerName: string;
  readonly entityName: string;
  readonly maxNoteCapacity: number;
  readonly maxCoinCapacity: number;
  readonly deviceFillPercentage: string;
  readonly capacityEnabled: boolean;
  readonly cashTodayType: string;
  readonly deviceCode: string;
  readonly deviceType: string;
  readonly deviceTimeZone: string;
  readonly solutionType: string;
  readonly isEarlyValue: boolean;
  readonly lastCertificationDate: string;
  readonly lastTransactionDate: string;
  readonly balances: Array<RawServicePointBalanceBalance>;
};

export interface RawServicePointBalanceBalance {
  readonly id: string;
  readonly noteCapacity: number;
  readonly coinCapacity: number;
  readonly currency: string;
  readonly currentDepositAmount: number;
  readonly todayDepositAmount: number;
  readonly lastTransactionDate?: string;
  readonly deviceTimeZone: string;
}

const enum FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM {
  SERVICE_POINT_NAME = "servicePointName",
  IS_EARLY_VALUE = "isEarlyValue",
  ORDER_BY = "orderBy",
  CENTER_NAME = "centerName",
  ESTABLISHMENT = "establishment",
}

export const FILTER_TYPE_SERVICE_POINT_BALANCES = {
  SERVICE_POINT_NAME: FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.SERVICE_POINT_NAME,
  IS_EARLY_VALUE: FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.IS_EARLY_VALUE,
  ORDER_BY: FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.ORDER_BY,
  CENTER_NAME: FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.CENTER_NAME,
  ESTABLISHMENT: FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.ESTABLISHMENT,
};
export type FilterTypeServicePointBalanceType =
  | FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.SERVICE_POINT_NAME
  | FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.IS_EARLY_VALUE
  | FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.ORDER_BY
  | FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.CENTER_NAME
  | FILTER_TYPE_SERVICE_POINT_BALANCES_ENUM.ESTABLISHMENT;

export const enum FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM {
  LAST_TRANSACTION_DATE = "lastTransactionDate",
  DEVICE_FILL_PERCENTAGE = "deviceFillPercentage",
  CENTER_NAME = "centerName",
}
export const FILTER_ORDERBY_SERVICE_POINT_BALANCES = {
  LAST_TRANSACTION_DATE: FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.LAST_TRANSACTION_DATE,
  DEVICE_FILL_PERCENTAGE: FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.DEVICE_FILL_PERCENTAGE,
  CENTER_NAME: FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.CENTER_NAME,
};
export type FilterOrderByServicePointBalanceType =
  | FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.LAST_TRANSACTION_DATE
  | FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.DEVICE_FILL_PERCENTAGE
  | FILTER_ORDERBY_SERVICE_POINT_BALANCES_ENUM.CENTER_NAME;

export type FiltersServicePointBalanceType = {
  servicePointName?: string;
  isEarlyValue?: boolean;
  orderBy?: FilterOrderByServicePointBalanceType;
  centerName?: string;
  establishment?: string;
};
