export enum FilterOperator {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_EQUALS = "LESS_THAN_EQUALS",
  IN = "IN",
  BETWEEN = "BETWEEN",
  FULL_TEXT_SEARCH = "FULL_TEXT_SEARCH",
  // STARTS_WITH = "STARTS_WITH",
}
