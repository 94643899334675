import { RawI18n, I18nServiceMock } from "./i18n.types";

export const mockI18nService = (): I18nServiceMock => ({
  fetchI18n: jest.fn(),
});

export const mockRawI18n = (): RawI18n => ({
  en: {
    "column.code": "Code",
    "column.description": "Description",
  },
  es: {
    "label.code": "Código",
    "label.description": "Descripción",
  },
});
