import { RawCurrency } from "@/orders/entities";

export class Currency {
  readonly id: string;
  readonly code: string;
  readonly symbol: string;
  readonly description: string;
  readonly minorUnit: number;
  readonly principalCurrency: boolean;

  constructor(data: RawCurrency) {
    this.id = data.id;
    this.code = data.code;
    this.symbol = data.symbol;
    this.description = data.description;
    this.minorUnit = data.minorUnit;
    this.principalCurrency = data.principalCurrency;
  }
}
