import { DenominationCosmos } from "./denominationCosmos";
import { RawDenominationCosmos } from "./denominationCosmos.types";

export const mockRawDenominationsCosmos = (): RawDenominationCosmos => {
  return {
    barCode: "string",
    boxName: "string",
    boxDescription: "string",
    category: "string",
    cardNumber: "string",
    codeLine: "string",
    collectionId: "string",
    currency: "string",
    date: "string",
    description: "string",
    isValid: true,
    numItems: 0,
    origin: "string",
    subType: "string",
    total: 0,
    type: "string",
    value: 0,
  };
};

export const mockDenominationCosmos = (
  data: RawDenominationCosmos = mockRawDenominationsCosmos()
): DenominationCosmos => new DenominationCosmos(data);
