import { FailureType, RawAssistanceFailure } from "@/orders/entities";

export class AssistanceFailure {
  readonly id: string;
  readonly failureType: FailureType;
  readonly code: string;
  readonly description: string;
  readonly needKey: boolean;

  constructor(data: RawAssistanceFailure) {
    this.id = data.id;
    this.failureType = new FailureType(data.failureType);
    this.code = data.code;
    this.description = data.description;
    this.needKey = data.needKey;
  }
}
