
import { User } from "@/entities";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export class BaseOrderView extends Vue {
  @Getter("getLoggedUser") loggedUser!: User;
  @Getter("getIsSignedIn") isSignedIn!: boolean;

  ordersLoaded = false;

  @Watch("loggedUser", { deep: true, immediate: true })
  onLoggedUserChanged(newValue: User) {
    if (this.isSignedIn && newValue) {
      this.loadOrderServices();
    }
  }

  loadOrderServices() {
    if (this.loggedUser?.email.includes("@prosegur")) {
      Promise.all([
        this.$store.dispatch("fetchOrders"),
        this.$store.dispatch("fetchOrdersSupplies"),
        this.$store.dispatch("fetchServicePointsOrders", this.loggedUser.id),
        this.$store.dispatch("fetchStatuses"),
      ]).then(() => {
        this.ordersLoaded = true;
      });
    }
  }
}
export default BaseOrderView;
