import { RawDenominationCosmos } from "./denominationCosmos.types";

export class DenominationCosmos {
  readonly barCode: string;
  readonly boxName: string;
  readonly boxDescription: string;
  readonly category: string;
  readonly cardNumber: string;
  readonly codeLine: string;
  readonly collectionId: string;
  readonly currency: string;
  readonly date: string;
  readonly description: string;
  readonly isValid: boolean;
  readonly numItems: number;
  readonly origin: string;
  readonly subType: string;
  readonly total: number;
  readonly type: string;
  readonly value: number;
  constructor(data: RawDenominationCosmos) {
    this.barCode = data.barCode;
    this.boxName = data.boxName;
    this.boxDescription = data.boxDescription;
    this.category = data.category;
    this.cardNumber = data.cardNumber;
    this.codeLine = data.codeLine;
    this.collectionId = data.collectionId;
    this.currency = data.currency;
    this.date = data.date;
    this.description = data.description;
    this.isValid = data.isValid;
    this.numItems = data.numItems;
    this.origin = data.origin;
    this.subType = data.subType;
    this.total = data.total;
    this.type = data.type;
    this.value = data.value;
  }
}
