
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
@Component({
  components: {
    PIcon,
  },
})
export class PCardShowHiden extends Vue {
  @Prop({ required: false, default: "" }) title!: string;
  @Prop({ required: false, default: "" }) description!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 20 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: true }) value!: boolean;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
  }

  valueLocal = true;

  showHiden(): void {
    this.valueLocal = !this.valueLocal;
    this.$emit("input", this.valueLocal);
  }
}
export default PCardShowHiden;
