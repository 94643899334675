import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";

declare module "vue/types/vue" {
  interface Vue {
    cleanUpEmptyStrings(obj: any): any;
    sortAscending(a: any, b: any, sortby: string): number;
    sortAscendingIgnoreCase(a: any, b: any, sortby: string): number;
    sortDescending(a: any, b: any, sortby: string): number;
    sortDescendingIgnoreCase(a: any, b: any, sortby: string): number;
    isObjectEmpty(object: any): boolean;

    getStartDate(date?: string | null): string | undefined;

    getEndDate(date?: string | null): string | undefined;
    getThreeDate(date: string): string | undefined;
    isInitialObject(object: any, initialObject: any): boolean;
    downloadFile(response: AxiosResponse | undefined): void;
    buildAndDownloadFile(blobObj: Blob | undefined, filename: string): void;
    makeToast(variant: string, message: string): void;
    convertBase64StringToBlob(base64String: string, type: string): Blob;
    respondToVisibility(element: any, callback: any): void;

    pageable(
      limit?: number | null,
      offset?: number | null,
      sortField?: string | null,
      sortWay?: string | null,
      filters?: Record<string, unknown>
    ): Record<string, unknown>;
  }
}

@Component
export default class VueMethods extends Vue {
  public cleanUpEmptyStrings(obj: Record<string, any>): Record<string, any> {
    const map = new Map(Object.entries(obj));
    map.forEach((value, key) => {
      if (value === "") {
        obj[key] = undefined;
      }
    });
    return obj;
  }

  public sortAscending(a: unknown, b: unknown, sortBy: string): number {
    if (((a as any)[sortBy] || "") < ((b as any)[sortBy] || "")) {
      return -1;
    }
    return ((a as any)[sortBy] || "") > ((b as any)[sortBy] || "") ? 1 : 0;
  }
  public sortAscendingIgnoreCase(a: unknown, b: unknown, sortBy: string): number {
    if (((a as any)[sortBy] || "").toLowerCase() < ((b as any)[sortBy] || "").toLowerCase()) {
      return -1;
    }
    return ((a as any)[sortBy] || "").toLowerCase() > ((b as any)[sortBy] || "").toLowerCase() ? 1 : 0;
  }

  public sortDescending(a: unknown, b: unknown, sortBy: string): number {
    if (((a as any)[sortBy] || "") < ((b as any)[sortBy] || "")) {
      return 1;
    }
    return ((a as any)[sortBy] || "") > ((b as any)[sortBy] || "") ? -1 : 0;
  }

  public sortDescendingIgnoreCase(a: unknown, b: unknown, sortBy: string): number {
    if (((a as any)[sortBy] || "").toLowerCase() < ((b as any)[sortBy] || "").toLowerCase()) {
      return 1;
    }
    return ((a as any)[sortBy] || "").toLowerCase() > ((b as any)[sortBy] || "").toLowerCase() ? -1 : 0;
  }

  public isObjectEmpty(object: unknown): boolean {
    return Object.values(object as any).every((item: any) => {
      return item === null || item === undefined || item === "" || (item.constructor === Array && item.length === 0);
    });
  }

  public getStartDate(date?: string): string | undefined {
    if (!date) return undefined;
    return new Date(new Date(date).toDateString()).toISOString();
  }
  public getEndDate(date?: string): string | undefined {
    if (!date) return undefined;
    const startDate = new Date(this.getStartDate(date)!);
    startDate.setDate(startDate.getDate() + 1);
    startDate.setMilliseconds(startDate.getMilliseconds() - 1);
    return startDate.toISOString();
  }
  public getThreeDate(date: string): string | undefined {
    if (!date) return undefined;
    const startDate = new Date(this.getStartDate(date)!);
    startDate.setDate(startDate.getDate() - 3);
    return startDate.toISOString();
  }

  public isInitialObject(object: unknown, initialObject: unknown): boolean {
    return JSON.stringify(initialObject) === JSON.stringify(object);
  }

  public downloadFile(response: AxiosResponse | undefined): void {
    if (response) {
      let filename = "";
      const disposition = response?.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, "");
      const blobObj = response?.data;
      this.buildAndDownloadFile(blobObj, filename);
    }
  }

  public buildAndDownloadFile(blobObj: Blob | unknown, filename: string): void {
    const url = window.URL.createObjectURL(blobObj);
    const aElement = document.createElement("a");
    aElement.href = url;
    aElement.download = filename;
    aElement.click();
    window.URL.revokeObjectURL(url);
  }

  public convertBase64StringToBlob(base64String: string, type: string): Blob {
    const binaryString = Buffer.from(base64String, "base64");
    return new Blob([binaryString], { type });
  }

  public makeToast(variant: string, message: string): void {
    this.$bvToast.toast(message, {
      autoHideDelay: 4000,
      variant,
      toaster: "b-toaster-bottom-center",
      noCloseButton: true,
    });
  }

  public respondToVisibility(element: any, callback: any) {
    const options = {
      root: document.documentElement,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        callback(entry.intersectionRatio > 0);
      });
    }, options);

    observer.observe(element);
  }

  public pageable(
    limit?: number | null,
    offset?: number | null,
    sortField?: string | null,
    sortWay?: string | null,
    filters?: Record<string, unknown>
  ): Record<string, unknown> {
    const pagination: Record<string, unknown> = {};
    pagination["limit"] = limit || 300;
    pagination["offset"] = offset || 0;
    pagination["sortFields"] = [{ field: sortField || "id", direction: sortWay || "ASC" }];
    pagination["filters"] = filters || {};
    return pagination;
  }
}
