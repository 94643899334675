import { RawUser } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import { UserService } from "@/services/backend/user/user.types";
import Vue from "vue";

export class UserServiceImpl implements UserService {
  async fetchMe(): Promise<RawUser> {
    const { data } = await Vue.$axios.get<RawUser>(`${BACKEND_BASE_URL}/api/v1/me`);
    return data;
  }
  async updateMe(): Promise<void> {
    await Vue.$axios.patch<void>(`${BACKEND_BASE_URL}/api/v1/me`);
  }
}
