import { Fleet, RawFleet } from "@/orders";

export const mockRawFleet = (): RawFleet => {
  return {
    id: "aaaaaaaa-ssss-cccc-ab13-000000000001",
    code: "13456",
    plate: "49-SA-24",
    model: "",
    delegationId: "aaaaaaaa-eeee-cccc-ab10-000000000001",
  };
};
export const mockFleet = (data: RawFleet = mockRawFleet()): Fleet => new Fleet(data);
