import { RawServicePoint } from "@/orders/entities/servicePoint/servicePoint.types";
import { Entity } from "@/orders/entities/entity";
import { Center } from "@/orders/entities/center";
import { Country } from "@/orders/entities/country";

export class ServicePoint {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly type: string;
  readonly deviceId: string;
  readonly entity: Entity;
  readonly center: Center;
  readonly frequencyUse: number;
  readonly country: Country;

  constructor(data: RawServicePoint) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.type = data.type;
    this.deviceId = data.deviceId;
    this.entity = data.entity;
    this.center = data.center;
    this.frequencyUse = data.frequencyUse;
    this.country = data.country;
  }
}
