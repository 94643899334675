import Vue from "vue";
import VueI18n from "vue-i18n";
import { IStore } from "@/store";

Vue.use(VueI18n);

function getLocale() {
  const cookie = document.cookie.match("(^|;) ?LOCALE=([^;]*)(;|$)");

  if (cookie && cookie[2]) {
    return cookie[2];
  }

  if (navigator.language) {
    return navigator.language;
  }

  return process.env.VUE_APP_I18N_LOCALE;
}

export const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentTranslationWarn: true,
});

export async function prepareI18n(store: IStore): Promise<void> {
  // const remote = await store.dispatch("fetchI18n");
  const local = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

  local.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      i18n.setLocaleMessage(locale, local(key));

      // if (remote) {
      //   i18n.mergeLocaleMessage(locale, remote[locale]);
      // }
    }
  });
}
