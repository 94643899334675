import { BACKEND_ORDERS_BASE_URL } from "@/services";
import Vue from "vue";
import { AssistanceFailure, RawAssistanceFailure, AssistanceFailureService } from "@/orders";

export class AssistanceFailureServiceImpl implements AssistanceFailureService {
  async fetchAssistanceFailures(country: string): Promise<AssistanceFailure[]> {
    const { data } = await Vue.$axios.get<RawAssistanceFailure[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/assistance-failures/country/${country}`
    );
    return data.map((value) => new AssistanceFailure(value));
  }
}
