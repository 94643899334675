import { Country } from "./country";
import { RawCountry } from "./country.types";

export const mockRawCountries = (): RawCountry[] => [
  {
    id: 1,
    name: "ESPAÑA",
    isoCode: "ES",
  },
  {
    id: 2,
    name: "ARGENTINA",
    isoCode: "AR",
  },
];

export const mockCountries = (data: RawCountry[] = mockRawCountries()): Country[] =>
  data.map((item) => new Country(item));
