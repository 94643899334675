import { parseLocalDateTime } from "@/common/utils";
import { RawServicePointLastTransaction, RawServicePointLastTransactions } from "@/entities";
import { LocalDateTime } from "@js-joda/core";

export class ServicePointLastTransaction {
  readonly amount: number;
  readonly accreditationDate: LocalDateTime | undefined;
  readonly currency: string;
  readonly earlyValue: boolean;
  readonly servicePointName: string;
  readonly tellerName: string;
  readonly transactionDate: LocalDateTime | undefined;
  readonly transactionType: string;
  constructor(data: RawServicePointLastTransaction) {
    this.amount = Number(data.amount); // / 100; // TODO: Handle this on backend response
    this.accreditationDate = parseLocalDateTime(data.accreditationDate);
    this.currency = data.currency;
    this.earlyValue = data.earlyValue;
    this.servicePointName = data.servicePointName;
    this.tellerName = data.tellerName;
    this.transactionDate = parseLocalDateTime(data.transactionDate);
    this.transactionType = data.transactionType;
  }
}

export class ServicePointLastTransactions {
  readonly servicePointLastTransactions: ServicePointLastTransaction[];
  constructor(data: RawServicePointLastTransactions) {
    const servicePointLastTransactions: ServicePointLastTransaction[] = [];
    data.servicePointLastTransactions.forEach((rawData) =>
      servicePointLastTransactions.push(new ServicePointLastTransaction(rawData))
    );
    this.servicePointLastTransactions = servicePointLastTransactions;
  }
}
