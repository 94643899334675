import { TransactionsCosmos } from "./transactionCosmos";
import { RawTransactionsCosmos } from "./transactionCosmos.types";

export const mockRawTransactionsCosmos = (): RawTransactionsCosmos => {
  return {
    results: [
      {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        accountingDate: "2024-04-26T10:01:23.721Z",
        actualId: "string",
        channel: "string",
        collectionId: "string",
        delegationCode: "string",
        logicCashTodayId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        receiptNumber: "string",
        strapSealCode: "string",
        timeZone: "string",
        transactionDate: "2024-04-26T10:01:23.721Z",
        transactionInfo: "string",
        transactionRef: "string",
        transactionType: "string",
        centerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        center: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          name: "string",
          code: "string",
        },
        deviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        device: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          entityId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          name: "string",
          code: "string",
          manufacturer: "string",
          model: "string",
          type: "string",
          ip: "string",
          port: "string",
          solutionType: "string",
          maxNoteCapacity: 0,
          maxCoinCapacity: 0,
          currencies: ["string"],
        },
        entityId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        entity: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          name: "string",
          code: "string",
          countryCode: "string",
          imageLink: "string",
        },
        servicePointId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        servicePoint: {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          name: "string",
          code: "string",
          isEarlyValue: true,
          deviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          centerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        },
        systemEntryDate: "",
        teller: {
          teller: "string",
          login: "string",
          name: "string",
        },
        extraDatas: [
          {
            category: "string",
            currency: "string",
            name: "string",
            value: "string",
          },
        ],
        legacyCodes: {
          actualId: "string",
          countryId: "string",
          customerId: "string",
          agencyId: "string",
          servicePtId: "string",
          deviceId: "string",
          delegacion: "string",
          teller: "string",
        },
        transactionDetails: [
          {
            currency: "EUR",
            origin: "Contado",
            isValid: true,
            currencyInfo: {
              isoCode: "string",
              minorUnits: 0,
              exponent: 0,
            },
            amount: {
              currency: "EUR",
              total: 100,
              numItems: 1,
              isValid: true,
              barCodes: ["348921089"],
            },
            denominations: [
              {
                barCode: "string",
                boxName: "string",
                boxDescription: "string",
                category: "string",
                cardNumber: "string",
                codeLine: "string",
                collectionId: "string",
                currency: "string",
                date: "string",
                description: "string",
                isValid: true,
                numItems: 0,
                origin: "string",
                subType: "string",
                total: 0,
                type: "string",
                value: 0,
              },
            ],
          },
          {
            currency: "USD",
            origin: "Contado",
            isValid: true,
            currencyInfo: {
              isoCode: "USD",
              minorUnits: 0,
              exponent: 0,
            },
            amount: {
              currency: "USD",
              total: 50,
              numItems: 2,
              isValid: true,
              barCodes: ["34432423"],
            },
            denominations: [
              {
                barCode: "string",
                boxName: "string",
                boxDescription: "string",
                category: "string",
                cardNumber: "string",
                codeLine: "string",
                collectionId: "string",
                currency: "string",
                date: "string",
                description: "string",
                isValid: true,
                numItems: 0,
                origin: "string",
                subType: "string",
                total: 0,
                type: "string",
                value: 0,
              },
            ],
          },
        ],
      },
    ],
    totalResults: 1,
  };
};

export const mockTransactionsCosmos = (data: RawTransactionsCosmos = mockRawTransactionsCosmos()): TransactionsCosmos =>
  new TransactionsCosmos(data);
