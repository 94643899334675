
import { Component, Prop, Vue } from "vue-property-decorator";
import { Crew } from "@/orders/entities/crew";
import { Employee, Order } from "@/orders";
import Icon from "@/orders/components/Icon/index.vue";

@Component({
  components: {
    Icon,
  },
})
export class Tripulation extends Vue {
  @Prop({ required: true }) order!: Order;
  left = require("@/orders/assets/chevron.svg");
  plus = require("@/orders/assets/icons/icon-plus-w.svg");
  photoDefault = require("@/orders/assets/icons/icon-icn-user.svg");
  signatureDefault = require("@/orders/assets/icons/icon-icn-edit-3.svg");
  crew!: Crew;
  loaded = false;

  closeModal(): void {
    this.$emit("close");
  }

  mounted(): void {
    this.$services.crew.fetchByOrder("8fd143dd-d8be-4aad-bd36-0fdeebfe01a0").then((value) => {
      this.crew = value;
      this.loaded = true;
    });
  }

  employeeName(employee: Employee): string {
    return employee.name.toLowerCase() + " " + employee.lastName.toLowerCase();
  }
  signatureNotFound(event: any) {
    event.target.src = this.signatureDefault;
    event.target.style.opacity = 0.1;
    event.target.style.boxShadow = "1px 1px 3px 2px #000000";
  }
  photoNotFound(event: any) {
    event.target.src = this.photoDefault;
    event.target.style.opacity = 0.1;
    event.target.style.boxShadow = "1px 1px 3px 2px #000000";
  }
}
export default Tripulation;
