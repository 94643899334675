
import { Component, Prop, Vue } from "vue-property-decorator";
import StepFive from "@/orders/components/ModalCreate/stepFive.vue";
import StepFour from "@/orders/components/ModalCreate/stepFour.vue";
import StepThree from "@/orders/components/ModalCreate/stepThree.vue";
import Icon from "@/orders/components/Icon/index.vue";
import {
  Commodity,
  CreateOrderAssistanceData,
  CreateOrderDeliveryChangeData,
  CreateOrderDeliveryData,
  CreateOrderPickupData,
  CreateOrderSupplyData,
  FactoryUpdateOrder,
  OrderData,
  OrderDateDTO,
  OrderDTO,
  OrderProgrammingHourType,
} from "@/orders";
import DateTimeMixin from "@/mixins/datetime";
import VueOrders from "@/orders/vue-orders";

@Component({
  components: {
    StepFive,
    StepFour,
    StepThree,
    Icon,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export default class ModalUpdate extends Vue {
  private readonly patternToResume = `dd MMM yyyy`;
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) order!: OrderDTO;

  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  iconClose: string = require("@/orders/assets/icons/icon-x.svg");
  stepPosition = 3;
  sendOrders: OrderDTO[] = [];
  orderDate = {} as OrderDateDTO;
  services: Commodity[] = [];

  created(): void {
    this.orderDate = this.setUpOrderDate();
  }

  closeModal(): void {
    this.stepPosition = 3;
    this.$emit("closeModal");
  }

  get formattedDateSelected(): string {
    return this.formatDateTime(this.orderDate.serviceDate!, this.patternToResume)!;
  }

  get commoditySelected(): Commodity {
    return this.order.commodity;
  }

  selectDate(orderDate: OrderDateDTO): void {
    this.orderDate = orderDate;
    this.nextPage();
  }

  async prepareOrder({ commodity, body }: { commodity: string; body: any }): Promise<void> {
    if (commodity === "ASSISTANCE") {
      const assistance = body as CreateOrderAssistanceData;
      const toSaveOrder = this.savedOrder(assistance);

      this.pushOrder(toSaveOrder);
    } else if (commodity === "SUPPLY") {
      const supply = body as CreateOrderSupplyData;
      const toSavedOrder = this.savedOrder(supply);

      this.pushOrder(toSavedOrder);
    } else if (commodity === "PICKUP") {
      const pickup = body as CreateOrderPickupData;
      const toSaveOrder = this.savedOrder(pickup);

      this.pushOrder(toSaveOrder);
    } else if (commodity === "DELIVERY") {
      const delivery = body as CreateOrderDeliveryData;
      const toSavedOrder = this.savedOrder(delivery);

      this.pushOrder(toSavedOrder);
    } else if (commodity === "DELIVERY_CHANGE") {
      const deliveryChange = body as CreateOrderDeliveryChangeData;
      const toSavedOrder = this.savedOrder(deliveryChange);

      this.pushOrder(toSavedOrder);
    }
    this.nextPage();
  }

  pushOrder(order: OrderDTO): void {
    const indexOrder = this.sendOrders.findIndex((item) => item.commodity === order.commodity);
    if (indexOrder !== -1) {
      this.sendOrders[indexOrder] = order;
    } else {
      this.sendOrders.push(order);
    }
  }

  savedOrder(obj: OrderData): OrderDTO {
    return {
      ...this.order,
      orderData: {
        ...this.orderDate,
        ...obj,
      },
    };
  }

  nextPage(): void {
    this.stepPosition = this.stepPosition + 1;
  }

  prevPage(): void {
    this.stepPosition = this.stepPosition - 1;
  }

  async submitOrders(): Promise<void> {
    for (const order of this.sendOrders) {
      await this.updateOrder(order);
    }
    this.closeModalUpdated();
  }

  closeModalUpdated(): void {
    this.stepPosition = 3;
    this.$emit("closeModalUpdated");
  }

  async updateOrder(order: OrderDTO) {
    const name = order.commodity.name;
    switch (name) {
      case "ASSISTANCE": {
        const data = order.orderData as CreateOrderAssistanceData;
        const assistance = FactoryUpdateOrder.buildUpdateOrderAssistanceService(data, order.orderId!);
        await this.$services.order.patchOrderAssistance(assistance);
        break;
      }
      case "SUPPLY": {
        const data = order.orderData as CreateOrderSupplyData;
        const supply = FactoryUpdateOrder.buildUpdateOrderSupplyService(data, order.orderId!);
        await this.$services.order.patchOrderSupply(supply);
        break;
      }
      case "PICKUP": {
        const data = order.orderData as CreateOrderPickupData;
        const pickup = FactoryUpdateOrder.buildUpdateOrderPickupService(data, order.orderId!);
        await this.$services.order.patchOrderPickup(pickup);
        break;
      }
      case "DELIVERY": {
        const data = order.orderData as CreateOrderDeliveryData;
        const delivery = FactoryUpdateOrder.buildUpdateOrderDeliveryService(data, order.orderId!);
        await this.$services.order.patchOrderDelivery(delivery);
        break;
      }
      case "DELIVERY_CHANGE": {
        const data = order.orderData as CreateOrderDeliveryChangeData;
        const deliveryChange = FactoryUpdateOrder.buildUpdateOrderDeliveryChangeService(data, order.orderId!);
        await this.$services.order.patchOrderDeliveryChange(deliveryChange);
        break;
      }
      default:
        console.error("Commodity not valid");
    }
  }

  setUpOrderDate(): OrderDateDTO {
    const orderData = this.order.orderData;
    if (orderData) {
      const date = this.convertISODateStringToDate(orderData.serviceDate);
      const initialDate = this.convertISODateStringToDate(orderData.requestInitialTime);
      const finalDate = this.convertISODateStringToDate(orderData.requestFinalTime);
      return new OrderDateDTO(date, initialDate, finalDate, OrderProgrammingHourType.INDICATE_DAY);
    }
    return {} as OrderDateDTO;
  }

  editStep(order: OrderDTO): void {
    this.stepPosition = 4;
    this.order = order;
  }

  removeOrder(order: OrderDTO): void {
    this.sendOrders = this.sendOrders.filter((item) => item.commodity !== order.commodity);
  }
}
