import { User } from "./user";
import { RawUser } from "./user.types";

export const mockRawUser = (): RawUser => {
  return {
    id: "43f32c11-f80b-4d6a-addc-7588a160fcc0",
    name: "TESTPrueba",
    lastName: "TESTApellido",
    countryCode: "BR",
    email: "PT10001111@prosegur.dev",
    lastLogin: "",
    entities: [
      {
        id: "43f32c11-f80b-4d6a-addc-7588a160fcc1",
        code: "101",
        name: "COESTI",
        imageLink:
          "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0066.png",
        countryCode: "BR",
      },
    ],
  };
};

export const mockUser = (data: RawUser = mockRawUser()): User => new User(data);
