import { RawCenter } from "@/orders/entities/center/center.types";

export class Center {
  id: string;
  code: string;
  name: string;
  cityName: string;
  provinceName: string;
  zipCode: string;
  address: string;
  frequencyUse: number;

  constructor(data: RawCenter) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.cityName = data.cityName;
    this.provinceName = data.provinceName;
    this.zipCode = data.zipCode;
    this.address = data.address;
    this.frequencyUse = data.frequencyUse;
  }
}
