/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseLocalDateTime } from "@/common/utils";
import { LocalDateTime } from "@js-joda/core";
import { DeviceDetails } from "../device-details";
import { Entity } from "../entity";
import {
  RawAmount,
  RawCurrencyRef,
  RawDenomination,
  RawExtraData,
  RawTransaction,
  RawTransactions,
  RawAmountDetails,
  RawEarlyValueNotification,
  RawTransactionServicePoint,
} from "./transaction.types";

export enum EEarlyValueNotificationType {
  CERT = "CERT",
  NO_CERT = "NO_CERT",
  NOT = "NOT",
  NO_NOT = "NO_NOT",
}

export class EarlyValueNotification {
  readonly id: string;
  readonly date: LocalDateTime | undefined;
  readonly source: string;
  readonly type: EEarlyValueNotificationType;
  constructor(data: RawEarlyValueNotification) {
    this.id = data.id ? data.id : "";
    this.date = parseLocalDateTime(data.date);
    this.source = data.source ? data.source : "";
    this.type = EEarlyValueNotificationType[data.type as keyof typeof EEarlyValueNotificationType];
  }
}

export class Amount {
  readonly total: number;
  readonly category: string;
  readonly barCodes: string[];
  readonly currency: string;
  readonly numItems: number;
  accreditation: EarlyValueNotification | undefined;
  notification: EarlyValueNotification | undefined;
  constructor(data: RawAmount) {
    this.total = data.total;
    this.category = data.category;
    this.currency = data.currency;
    this.numItems = data.numItems;

    const barCodes: string[] = [];
    if (data.barCodes) data.barCodes.filter((rawdata) => Boolean(rawdata)).forEach((rawData) => barCodes.push(rawData));
    this.barCodes = barCodes;
  }
  setEarlyValueNotifications(amountDetails: RawAmountDetails[]): any {
    const amountDetailsFound = amountDetails.find(
      (amountDetail) => amountDetail.currency == this.currency && amountDetail.category === this.category
    );
    if (!amountDetailsFound) return;
    this.accreditation = amountDetailsFound.accreditation
      ? new EarlyValueNotification(amountDetailsFound.accreditation)
      : undefined;
    this.notification = amountDetailsFound.notification
      ? new EarlyValueNotification(amountDetailsFound.notification)
      : undefined;
  }
}

export class Denomination {
  readonly date: string;
  readonly type: string;
  readonly total: number;
  readonly value: number;
  readonly barCode: string;
  readonly boxName: string;
  readonly subType: string;
  readonly category: string;
  readonly codeLine: string;
  readonly currency: string;
  readonly numItems: number;
  readonly cardNumber: string;
  readonly description: string;
  readonly collectionId: string;
  constructor(data: RawDenomination) {
    this.date = data.date;
    this.type = data.type;
    this.total = data.total / 100; // TODO: Handle this on backend response;
    this.value = data.value / 100; // TODO: Handle this on backend response;
    this.barCode = data.barCode;
    this.boxName = data.boxName;
    this.subType = data.subType;
    this.category = data.category;
    this.codeLine = data.codeLine;
    this.currency = data.currency;
    this.numItems = data.numItems;
    this.cardNumber = data.cardNumber;
    this.description = data.description;
    this.collectionId = data.collectionId;
  }
}

export class ExtraData {
  readonly name: string;
  readonly value: string;
  readonly category: string;
  readonly currency: string;
  constructor(data: RawExtraData) {
    this.name = data.name;
    this.value = data.value;
    this.category = data.category;
    this.currency = data.currency;
  }
}

export class CurrencyRef {
  readonly iso: string;
  readonly exponent: number;
  constructor(data: RawCurrencyRef) {
    this.iso = data.iso;
    this.exponent = data.exponent;
  }
}

export class TransactionServicePoint {
  readonly id: string;
  readonly isEarlyValue: boolean;
  readonly name: string;
  readonly centerCode: string;
  readonly centerName: string;
  constructor(data: RawTransactionServicePoint) {
    this.id = data?.id || "";
    this.isEarlyValue = data?.isEarlyValue || false;
    this.name = data?.name || "";
    this.centerCode = data?.centerCode || "";
    this.centerName = data?.centerName || "";
  }
}

export enum ETransactionType {
  CASHIN = "CASHIN",
  CASHOUT = "CASHOUT",
  SHIPIN = "SHIPIN",
  SHIPOUT = "SHIPOUT",
  MOVEIN = "MOVEIN",
  MOVEOUT = "MOVEOUT",
  BBALANCE = "BBALANCE",
  EBALANCE = "EBALANCE",
}

export enum ETransactionOrigin {
  CASH = "CASH",
  DECLARED = "DECLARED",
}

export class Transaction {
  readonly accountingDate: LocalDateTime | undefined;
  readonly actualId: string;
  readonly amounts: Amount[];
  readonly centerCode: string;
  readonly centerId: string;
  readonly channel: string;
  readonly collectionId: string;
  readonly countryId: string;
  readonly currencies: CurrencyRef[];
  readonly delegation: string;
  readonly denominations: Denomination[];
  readonly deviceDetails: DeviceDetails;
  readonly deviceId: string;
  readonly entity: Entity;
  readonly entityId: string;
  readonly extraDatas: ExtraData[];
  readonly id: string;
  readonly hasAccreditation: boolean;
  readonly hasNotification: boolean;
  readonly logicCashTodayId: string;
  readonly origin: string;
  readonly receiptNumber: string;
  readonly servicePoint: TransactionServicePoint;
  readonly servicePointCode: string;
  readonly servicePointId: string;
  readonly strapSealCode: string;
  readonly systemEntryDate: LocalDateTime | undefined;
  readonly teller: string;
  readonly tellerLogin: string;
  readonly tellerName: string;
  readonly timeZone: string;
  readonly transactionDate: LocalDateTime | undefined;
  readonly transactionInfo: string;
  readonly transactionRef: string;
  readonly transactionType: ETransactionType;
  constructor(data: RawTransaction) {
    this.id = data.id;
    this.accountingDate = parseLocalDateTime(data.accountingDate);
    this.actualId = data.actualId;
    this.centerCode = data?.centerCode || "";
    this.centerId = data?.centerId || "";
    this.channel = data.channel;
    this.collectionId = data.collectionId;
    this.countryId = data.countryId;
    this.delegation = data.delegation;
    this.deviceDetails = new DeviceDetails(data.deviceDetails);
    this.deviceId = data.deviceId;
    this.entity = new Entity(data.entity);
    this.entityId = data.entityId;
    this.logicCashTodayId = data.logicCashTodayId;
    this.origin = ETransactionOrigin[data.origin as keyof typeof ETransactionOrigin];
    this.receiptNumber = data.receiptNumber;
    this.servicePoint = data.servicePoint;
    this.servicePointCode = data.servicePointCode;
    this.servicePointId = data.servicePointId;
    this.strapSealCode = data.strapSealCode;
    this.systemEntryDate = parseLocalDateTime(data.systemEntryDate);
    this.teller = data.teller;
    this.tellerLogin = data.tellerLogin;
    this.tellerName = data.tellerName;
    this.timeZone = data.timeZone;
    this.transactionDate = parseLocalDateTime(data.transactionDate);
    this.transactionInfo = data.transactionInfo;
    this.transactionRef = data.transactionRef;
    this.transactionType = ETransactionType[data.transactionType as keyof typeof ETransactionType];
    this.hasAccreditation = !!data.hasAccreditation;
    this.hasNotification = !!data.hasNotification;

    const amounts: Amount[] = data.amounts.map((rawData) => {
      const amount = new Amount(rawData);
      amount.setEarlyValueNotifications(data.amountDetails);
      return amount;
    });
    this.amounts = amounts;

    const denominations: Denomination[] = [];
    data.denominations.forEach((rawData) => denominations.push(new Denomination(rawData)));
    this.denominations = denominations;

    const extraDatas: ExtraData[] = [];
    data.extraDatas.forEach((rawData) => extraDatas.push(new ExtraData(rawData)));
    this.extraDatas = extraDatas;

    const currencies: CurrencyRef[] = [];
    data.currencies.forEach((rawData) => currencies.push(new CurrencyRef(rawData)));
    this.currencies = currencies;
  }
}

export class Transactions {
  readonly transactions: Transaction[];
  readonly totalResult: number;
  constructor(data: RawTransactions) {
    this.totalResult = data.totalResult;
    const transactions: Transaction[] = [];
    data.projections?.forEach((rawData: RawTransaction) => transactions.push(new Transaction(rawData)));
    this.transactions = transactions;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ETransactionTypeList = Object.fromEntries(Object.entries(ETransactionType).filter(([k, v]) => true));
