import { RawEmployee } from "@/orders/entities/employee/employee.types";

export class Employee {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly lastName: string;
  readonly function: string;
  readonly urlPhoto: string;
  readonly urlSignature: string;
  readonly imagePhoto: string;
  readonly imageSignature: string;
  readonly workBadge: string;
  readonly validationDocumentType: string;
  readonly validationDocumentValue: string;

  constructor(data: RawEmployee) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.lastName = data.lastName;
    this.function = data.function;
    this.urlPhoto = data.urlPhoto;
    this.urlSignature = data.urlSignature;
    this.imagePhoto = data.imagePhoto;
    this.imageSignature = data.imageSignature;
    this.workBadge = data.workBadge;
    this.validationDocumentType = data.validationDocumentType;
    this.validationDocumentValue = data.validationDocumentValue;
  }
}
