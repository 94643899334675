export class Semaphore {
  private tasks: (() => Promise<void>)[] = [];
  private running = false;

  acquire = async (...tasks: (() => Promise<void>)[]): Promise<void> => {
    this.tasks.push(...tasks);
    if (!this.running) {
      this.running = true;
      while (this.tasks.length) {
        const task = this.tasks.shift();
        if (task) {
          await task();
        }
      }
      this.running = false;
    }
  };
}
