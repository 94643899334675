export interface iTranslationsTable {
  [key: string]: string;
  orderAsc: string;
  orderDesc: string;
  noOrder: string;
  compressData: string;
  expandData: string;
  filter: string;
  filterEmpty: string;
  all: string;
  hideColumn: string;
  empty: string;
}
export interface iLanguageTable {
  language: string;
  translations: iTranslationsTable;
}
export const translations: iLanguageTable[] = [
  {
    language: "es",
    translations: {
      orderAsc: "Ordenar columna ascendente",
      orderDesc: "Ordenar columna descendente",
      noOrder: "Quitar ordenamiento",
      compressData: "Comprimir datos",
      expandData: "Expandir datos",
      filter: "Filtrar",
      filterEmpty: "Vacíos",
      all: "Todos",
      hideColumn: "Ocultar Columna",
      empty: "No hay datos para mostrar",
    },
  },
  {
    language: "en",
    translations: {
      orderAsc: "Sort ascending column",
      orderDesc: "Sort descending column",
      noOrder: "Delete sort",
      compressData: "Compress data",
      expandData: "Expand data",
      filter: "Filter",
      filterEmpty: "Empty",
      all: "All",
      hideColumn: "Hide column",
      empty: "There is no data to show",
    },
  },
  {
    language: "pt",
    translations: {
      orderAsc: "Classificar coluna crescente",
      orderDesc: "Classificar coluna descendente",
      noOrder: "Excluir classificação",
      compressData: "Compactar dados",
      expandData: "Expandir dados",
      filter: "Filtro",
      filterEmpty: "Vazio",
      all: "Tudo",
      hideColumn: "Ocultar coluna",
      empty: "Não há dados para mostrar",
    },
  },
  {
    language: "de",
    translations: {
      orderAsc: "Spalte aufsteigend sortieren",
      orderDesc: "Absteigend sortieren",
      noOrder: "Sortierung löschen",
      compressData: "Daten komprimieren",
      expandData: "Daten erweitern",
      filter: "Filter",
      filterEmpty: "Leer",
      all: "Alle",
      hideColumn: "Spalte ausblenden",
      empty: "Es gibt keine anzuzeigenden Daten",
    },
  },
];
