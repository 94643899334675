import {
  LogicCashTodayBalanceServiceImpl,
  CountryServiceImpl,
  I18nServiceImpl,
  ServicePointBalanceServiceImpl,
  UserServiceImpl,
  EstablishmentServiceImpl,
  LogicCashTodayTransactionsServiceImpl,
  DeviceDetailsServiceImpl,
  LogicCashTodayServiceImpl,
  TransactionServiceImpl,
  ServicePointTransactionsServiceImpl,
  ServicePointServiceImpl,
  UserPreferencesServiceImpl,
} from "@/services";
import { IStore } from "@/store";
import Vue from "vue";
import { IProvider } from "./provider.types";
import { OrderServiceImpl, ServicePointOrdersServiceImpl } from "@/orders/services";
import { StatusServiceImpl } from "@/orders/services/status";
import { CommoditiesServiceImpl } from "@/orders/services/commodities";
import { AssistanceFailureServiceImpl } from "@/orders/services/assistanceFailure";
import { RatingTypesServiceImpl } from "@/orders/services/ratingTypes";
import { CrewServiceImpl } from "@/orders/services/crew";

export const provider = (): IProvider => ({
  assistanceFailure: new AssistanceFailureServiceImpl(),
  i18n: new I18nServiceImpl(),
  user: new UserServiceImpl(),
  country: new CountryServiceImpl(),
  servicePointBalance: new ServicePointBalanceServiceImpl(),
  logicCashTodayBalance: new LogicCashTodayBalanceServiceImpl(),
  logicCashTodayTransactions: new LogicCashTodayTransactionsServiceImpl(),
  establishment: new EstablishmentServiceImpl(),
  deviceDetails: new DeviceDetailsServiceImpl(),
  logicCashTodays: new LogicCashTodayServiceImpl(),
  transactions: new TransactionServiceImpl(),
  servicePointTransactions: new ServicePointTransactionsServiceImpl(),
  servicePoints: new ServicePointServiceImpl(),
  userPreferences: new UserPreferencesServiceImpl(),
  order: new OrderServiceImpl(),
  servicePointOrders: new ServicePointOrdersServiceImpl(),
  status: new StatusServiceImpl(),
  commodities: new CommoditiesServiceImpl(),
  ratingTypes: new RatingTypesServiceImpl(),
  crew: new CrewServiceImpl(),
});

export const prepareServices = (store: IStore): void => {
  store.$services = provider();

  Vue.mixin({
    beforeCreate() {
      this.$services = store.$services;
    },
  });

  Vue.$axios.interceptors.request.use(async (config) => {
    config.headers!.Accept = "application/json";

    return config;
  });
};
