import { RawLegacyCodes } from "./legacyCodes.types";

export class LegacyCodes {
  readonly actualId: string;
  readonly countryId: string;
  readonly customerId: string;
  readonly agencyId: string;
  readonly servicePtId: string;
  readonly deviceId: string;
  readonly delegacion: string;
  readonly teller: string;
  constructor(data: RawLegacyCodes) {
    this.actualId = data.actualId;
    this.countryId = data.countryId;
    this.customerId = data.customerId;
    this.agencyId = data.agencyId;
    this.servicePtId = data.servicePtId;
    this.deviceId = data.deviceId;
    this.delegacion = data.delegacion;
    this.teller = data.teller;
  }
}
