import { RawDelegation } from "@/orders/entities";

export class Delegation {
  readonly id: string;
  readonly value: number;
  readonly description: string;

  constructor(data: RawDelegation) {
    this.id = data.id;
    this.value = data.value;
    this.description = data.description;
  }
}
