
import { Prop, Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";

@Component({
  components: { Icon },
})
export default class CardButton extends Vue {
  @Prop({ type: String, default: "" }) image!: string;
}
