import { render, staticRenderFns } from "./BalancesDashboard.vue?vue&type=template&id=7f547689&scoped=true&"
import script from "./BalancesDashboard.vue?vue&type=script&lang=ts&"
export * from "./BalancesDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./BalancesDashboard.vue?vue&type=style&index=0&id=7f547689&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f547689",
  null
  
)

export default component.exports