
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  components: {
    PIcon,
  },
})
export class Panel extends Vue {
  @Prop({ required: false, default: "" }) title!: string;
  @Prop({ required: false, default: "" }) info!: string;
  @Prop({ required: false, default: "" }) infoExtra!: string;

  @Prop({ required: false, default: "icon-transaction" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 20 }) iSize!: number;
  @Prop({ required: false, default: "#323232" }) iColor!: string;

  showContent = true;

  @Ref("panel-body") readonly panelBody!: HTMLInputElement;
  panelBodyHeigth = "auto";
  mounted(): void {
    this.panelBodyHeigth = this.panelBody.offsetHeight + "px";
    // algunos componentes tardan en renderizar
    setTimeout(() => {
      this.panelBodyHeigth = this.panelBody.offsetHeight + "px";
    }, 1000);
  }
}
export default Panel;
