
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class TimePicker extends Vue {
  @Prop({ type: String, default: "00:00" }) value!: string;
  @Prop({ type: String, default: "00" }) hourStart!: string;
  @Prop({ type: String, default: "23:59" }) hourEnd!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  selectedHour = "00";
  selectedMinute = "00";
  dropdownOpen = false;

  get formattedTime(): string {
    return `${this.selectedHour}:${this.selectedMinute}`;
  }

  get availableHours(): string[] {
    const startHour = this.hourStart ? parseInt(this.hourStart.substring(0, 2)) : 0;
    const endHour = this.hourEnd ? parseInt(this.hourEnd.substring(0, 2)) : 23;

    return Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0")).filter((hour) => {
      const currentHour = parseInt(hour);
      return currentHour >= startHour && currentHour <= endHour;
    });
  }

  get availableMinutes(): string[] {
    return Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));
  }

  @Watch("value", { immediate: true })
  onValueChanged(newValue: string) {
    if (newValue) {
      const [hour, minute] = newValue.split(":");
      this.selectedHour = hour;
      this.selectedMinute = minute;
    } else {
      this.selectedHour = "00";
      this.selectedMinute = "00";
    }
  }

  @Watch("selectedHour", { immediate: true })
  @Watch("selectedMinute", { immediate: true })
  onTimeChanged() {
    this.emitTime();
  }

  openDropdown() {
    this.dropdownOpen = true;
  }

  closeDropdown() {
    this.dropdownOpen = false;
  }

  isHourSelectable(hour: string): boolean {
    if (!this.hourStart && !this.hourEnd) {
      return true; // Si no hay hourStart ni hourEnd, todas las horas son seleccionables
    }

    const startHour = this.hourStart ? parseInt(this.hourStart.substring(0, 2)) : 0;
    const endHour = this.hourEnd ? parseInt(this.hourEnd.substring(0, 2)) : 23;
    const currentHour = parseInt(hour);

    return currentHour >= startHour && currentHour <= endHour;
  }

  selectHour(hour: string) {
    if (this.isHourSelectable(hour)) {
      this.selectedHour = hour;
    }
  }

  selectMinute(minute: string) {
    this.selectedMinute = minute;
  }

  emitTime() {
    const time = `${this.selectedHour}:${this.selectedMinute}`;
    this.$emit("input", time);
  }
}
