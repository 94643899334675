import { render, staticRenderFns } from "./PSidebar.vue?vue&type=template&id=13c21df2&scoped=true&"
import script from "./PSidebar.vue?vue&type=script&lang=ts&"
export * from "./PSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./PSidebar.vue?vue&type=style&index=0&id=13c21df2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c21df2",
  null
  
)

export default component.exports