import { LogicCashTodayLastTransactions } from "./logicCashTodayTransaction";
import { RawLogicCashTodayLastTransactions } from "./logicCashTodayTransaction.types";

export const mockRawLogicCashTodayLastTransactions = (): RawLogicCashTodayLastTransactions => {
  return {
    logicCashTodayLastTransactions: [
      {
        tellerName: "Daniel Ruiz",
        currency: "EUR",
        amount: 10000,
        transactionType: "Depósito",
        certificationDate: "2022-01-19T18:22:43Z",
      },
      {
        tellerName: "Daniel Ruiz",
        currency: "EUR",
        amount: 100,
        transactionType: "Depósito",
        certificationDate: "2022-02-09T10:02:43Z",
      },
      {
        tellerName: "Daniel Ruiz",
        currency: "EUR",
        amount: 10000,
        transactionType: "Dispensación",
        certificationDate: "2022-02-09T18:02:43Z",
      },
    ],
  };
};

export const mockLogicCashTodayLastTransactions = (
  data: RawLogicCashTodayLastTransactions = mockRawLogicCashTodayLastTransactions()
): LogicCashTodayLastTransactions => new LogicCashTodayLastTransactions(data);
