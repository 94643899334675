import { RawContract } from "@/orders/entities";

export class Contract {
  readonly id: string;
  readonly code: string;
  readonly servicePointId: string;
  readonly validityStart: string;
  readonly validityEnd: string;
  readonly contractTypeId: string;
  readonly active: boolean;

  constructor(data: RawContract) {
    this.id = data.id;
    this.code = data.code;
    this.servicePointId = data.servicePointId;
    this.validityStart = data.validityStart;
    this.validityEnd = data.validityEnd;
    this.contractTypeId = data.contractTypeId;
    this.active = data.active;
  }
}
