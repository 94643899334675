
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "not-found-error-page",
  components: {},
})
export default class NotFoundErrorPage extends Vue {
  constructor() {
    super();
  }
}
