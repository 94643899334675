import { Entity } from "./entity";
import { RawEntity } from "./entity.types";

export const mockRawEntity = (): RawEntity => ({
  id: "bd5ed482-5a0a-3865-be5b-06d36058daf4",
  code: "041836",
  name: "BR003042G - REDE G4 POSTOS",
  imageLink: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0066.png",
  countryCode: "BR",
});

export const mockEntity = (data: RawEntity = mockRawEntity()): Entity => new Entity(data);
