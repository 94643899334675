import { DeviceCosmos } from "../device-details";
import { Entity } from "../entity";
import { Center } from "@/entities/center";
import { Teller } from "@/entities/teller";
import { ExtraData, LegacyCodes, RawTransactionCosmos, RawTransactionsCosmos, ServicePointCosmos } from "@/entities";
import { TransactionCosmosDetail } from "@/entities/transactionCosmos/transactionCosmosDetail";

export class TransactionCosmos {
  readonly id: string;
  readonly accountingDate: string;
  readonly actualId: string;
  readonly channel: string;
  readonly collectionId: string;
  readonly delegationCode: string;
  readonly logicCashTodayId: string;
  readonly receiptNumber: string;
  readonly strapSealCode: string;
  readonly timeZone: string;
  readonly transactionDate: string;
  readonly transactionInfo: string;
  readonly transactionRef: string;
  readonly transactionType: string;
  readonly centerId: string;
  readonly center: Center;
  readonly deviceId: string;
  readonly device?: DeviceCosmos;
  readonly entityId: string;
  readonly entity: Entity;
  readonly servicePointId: string;
  readonly servicePoint?: ServicePointCosmos;
  readonly teller: Teller;
  readonly systemEntryDate: string;
  readonly extraDatas: ExtraData[];
  readonly legacyCodes: LegacyCodes;
  readonly transactionDetails: TransactionCosmosDetail[];

  constructor(data: RawTransactionCosmos) {
    this.id = data.id;
    this.accountingDate = data.accountingDate;
    this.actualId = data.actualId;
    this.channel = data.channel;
    this.collectionId = data.collectionId;
    this.delegationCode = data.delegationCode;
    this.logicCashTodayId = data.logicCashTodayId;
    this.receiptNumber = data.receiptNumber;
    this.strapSealCode = data.strapSealCode;
    this.timeZone = data.timeZone;
    this.transactionDate = data.transactionDate;
    this.transactionInfo = data.transactionInfo;
    this.transactionRef = data.transactionRef;
    this.transactionType = data.transactionType;
    this.centerId = data.centerId;
    this.center = new Center(data.center);
    this.deviceId = data.deviceId;
    this.device = data.device ? new DeviceCosmos(data.device) : undefined;
    this.entityId = data.entityId;
    this.entity = new Entity(data.entity);
    this.servicePointId = data.servicePointId;
    this.servicePoint = data.servicePoint ? new ServicePointCosmos(data.servicePoint) : undefined;
    this.teller = new Teller(data.teller);
    this.systemEntryDate = data.systemEntryDate;
    this.extraDatas = data.extraDatas.map((value) => new ExtraData(value));
    this.legacyCodes = new LegacyCodes(data.legacyCodes);
    this.transactionDetails = data.transactionDetails.map((value) => new TransactionCosmosDetail(value));
  }
}

export class TransactionsCosmos {
  readonly results: TransactionCosmos[];
  readonly totalResults: number;

  constructor(data: RawTransactionsCosmos) {
    this.totalResults = data.totalResults;
    this.results = data.results.map((value) => new TransactionCosmos(value));
  }
}
