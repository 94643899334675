import { Contract, RawOrderCommodities } from "@/orders/entities";

export class OrderCommodities {
  readonly id: string;
  readonly code: string;
  readonly description: string;
  readonly name: string;
  readonly contract: Contract;

  constructor(data: RawOrderCommodities) {
    this.id = data.id;
    this.code = data.code;
    this.description = data.description;
    this.name = data.name;
    this.contract = new Contract(data.contract);
  }
}
