
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";

@Component({
  components: { PDropDownContainer },
})
export class PFormInputHour extends Vue {
  @Prop({ required: true }) value!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value && typeof this.value === "string" && this.value != "" && this.value.includes(":")) {
      this.valueLocal = this.value;
      const arrayTime = this.valueLocal.split(":");
      this.valueHour = parseInt(arrayTime[0]);
      this.valueMin = parseInt(arrayTime[1]);
    }
  }
  @Prop({ required: false, default: "default" }) variant!: string; //default/filter
  @Prop({ required: false, default: "" }) label!: string;

  valueLocal = "00:00";
  valueHour = 0;
  valueMin = 0;
  get inputClassComp(): string {
    return "viewInput_PFormInputHour" + "_" + this.variant;
  }
  get valueHourComp(): number {
    return this.valueHour;
  }
  set valueHourComp(val: number) {
    const intVal = parseInt(val + "");

    if (intVal >= 0 && intVal < 24) {
      this.valueHour = intVal;
    } else if (intVal && intVal >= 0) {
      this.valueHour = intVal;
      this.valueHour = 23;
    } else if (intVal && intVal < 0) {
      this.valueHour = intVal;
      this.valueHour = 0;
    }
  }
  get valueMinComp(): number {
    return this.valueMin;
  }
  set valueMinComp(val: number) {
    const intVal = parseInt(val + "");
    if (intVal >= 0 && intVal < 60) {
      this.valueMin = intVal;
    } else if (intVal && intVal >= 0) {
      this.valueMin = intVal;
      this.valueMin = 59;
    } else if (intVal && intVal < 0) {
      this.valueMin = intVal;
      this.valueMin = 0;
    }
  }
  getTwoDigitNumber(num: number): string {
    if (num < 10) return "0" + num;
    return "" + num;
  }
  input(): void {
    this.valueLocal = this.getTwoDigitNumber(this.valueHour) + ":" + this.getTwoDigitNumber(this.valueMin);
    this.$emit("input", this.valueLocal);
  }
  blur(): void {
    this.valueLocal = this.getTwoDigitNumber(this.valueHour) + ":" + this.getTwoDigitNumber(this.valueMin);
    this.$emit("blur", this.valueLocal);
  }
}
export default PFormInputHour;
