import { Center } from "./center";
import { RawCenter } from "./center.types";

export const mockRawCenter = (): RawCenter => ({
  id: "bd5ed482-5a0a-3865-be5b-06d36058daf4",
  code: "041836",
  name: "BR003042G - REDE G4 POSTOS",
});

export const mockCenter = (data: RawCenter = mockRawCenter()): Center => new Center(data);
