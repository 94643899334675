
import { Component, Vue, Prop } from "vue-property-decorator";
import { BalanceItem } from "./dashboard.type";
import { PMiniLabel } from "@/common/components";
import DateTimeMixin from "@/mixins/datetime";
import { PIcon, PPiePercentage } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { User } from "@/entities";
import { Getter } from "vuex-class";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PMiniLabel,
    PIcon,
    PPiePercentage,
  },
})
export class BalancesDashboard extends Vue {
  @Getter("getLoggedUser") loggedUser?: User;
  @Prop({ required: true }) balance!: BalanceItem;

  get hasNotes(): boolean {
    if (!this.balance || !this.balance.balances || !Array.isArray(this.balance.balances)) {
      return false;
    }
    return !this.balance.balances.some(balance => balance.noteCapacity === null);
  }

  iconLogic = "iconLogic";

  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };
  notesOptions = {
    useGrouping: true,
  };

  get iconType(): string {
    return this.iconLogic;
  }

  get loggedUserHasSeveralEntities(): boolean {
    const entities = this.loggedUser?.entities;
    if (entities && entities.length > 1) {
      return true;
    }
    return false;
  }

  get mustDisplay(): boolean {
    return this.balance.deviceType !== "Recicladora";
  }

  classFirstBalanceItem(index: number): string {
    return index == 0 ? "classFirstBalanceItem" : "";
  }
}
export default BalancesDashboard;
