import { ServicePointLastTransactions } from "./servicePointTransaction";
import { RawServicePointLastTransactions } from "./servicePointTransaction.types";

export const mockRawServicePointLastTransactions = (): RawServicePointLastTransactions => {
  return {
    servicePointLastTransactions: [
      {
        amount: "50015.00",
        accreditationDate: "2022-11-28T10:36:20",
        currency: "CLP",
        earlyValue: false,
        servicePointName: "CARLS SC 270",
        tellerName: "Daniel Ruiz",
        transactionDate: "2022-11-28T10:36:20",
        transactionType: "CASHIN",
      },
    ],
  };
};

export const mockServicePointLastTransactions = (
  data: RawServicePointLastTransactions = mockRawServicePointLastTransactions()
): ServicePointLastTransactions => new ServicePointLastTransactions(data);
