
import { Component, Vue, Prop } from "vue-property-decorator";
import { PMiniLabel, Panel } from "@/common/components";
import { ServicePointBalance, ServicePointLastTransaction } from "@/entities";
import DateTimeMixin from "@/mixins/datetime";
import { LocalDateTime } from "@js-joda/core";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PMiniLabel,
    Panel,
  },
})
export default class ServicePointDetailLastTransactions extends Vue {
  @Prop() public servicePointBalance!: ServicePointBalance;
  @Prop() public servicePointLastTransactions!: ServicePointLastTransaction[];
  @Prop({ required: true }) servicePointId!: string;

  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };

  get hasData(): boolean {
    return this.servicePointLastTransactions.length > 0;
  }
  get info(): string {
    let returnTemp = "";
    if (this.hasData && this.servicePointBalance) {
      if (this.servicePointBalance.lastCertificationDate) {
        const date =
          this.formatDateTimeTodayYerterday(this.servicePointBalance.lastTransactionDate as LocalDateTime) || "";
        returnTemp += this.$t("servicePointDetail.updated") + date;
      }
    } else {
      returnTemp += this.$t("servicePointDetail.noTransactions");
    }
    return returnTemp;
  }
  isEarlyValue(): boolean {
    return this.hasData && this.servicePointLastTransactions[0].earlyValue;
  }
  getTransactionType(transaction: ServicePointLastTransaction): string {
    const text = this.$t("transactions.transactionType." + transaction.transactionType);
    return `${text}`;
  }
}
