export interface TranslationsPaginationPTable {
  [key: string]: string;
  page: string;
  rows: string;
  showing: string;
  of: string;
}
export interface LanguagePaginationPTable {
  language: string;
  translations: TranslationsPaginationPTable;
}
export const translations: LanguagePaginationPTable[] = [
  {
    language: "es",
    translations: {
      page: "Página",
      rows: "Filas",
      showing: "Mostrando",
      of: "de",
    },
  },
  {
    language: "en",
    translations: {
      page: "Page",
      rows: "Rows",
      showing : "Showing",
      of: "of",
    },
  },
  {
    language: "pt",
    translations: {
      page: "Página",
      rows: "Linhas",
      showing : "Mostrando",
      of: "de",
    },
  },
  {
    language: "de",
    translations: {
      page: "Seite",
      rows: "Reihen",
      showing: "Anzeigen",
      of: "von",
    },
  },
];
