import { IStaticData, RawCountry, RawUser } from "@/entities";
import { IMutations } from "@/store";
import { IState } from "@/store";
import { Order } from "@/orders/entities/order";
import { ServicePoint } from "@/orders/entities/servicePoint";
import { Center, Commodity, Country, Entity, OrderStatus, AssistanceFailure } from "@/orders";

export const mutations: IMutations = {
  // Root
  SET_LOCALE(state: IState, payload: string): void {
    state.locale = payload;
  },
  SET_URL(state: IState, payload: string): void {
    state.url = payload;
  },
  SET_IS_SIGNED_IN(state: IState, payload: boolean): void {
    state.isSignedIn = payload;
  },
  SET_LOGGED_USER(state: IState, payload: RawUser): void {
    state.loggedUser = payload;
  },
  SET_STATIC_DATA(state: IState, payload: IStaticData): void {
    state.staticData = payload;
  },

  // Country
  SET_COUNTRIES(state: IState, payload: RawCountry[]): void {
    state.countries = payload;
  },
  SET_ORDERS(state: IState, payload: Order[]): void {
    state.orders = payload;
  },
  SET_ORDERS_SUPPLIES(state: IState, payload: Order[]): void {
    state.ordersSupplies = payload;
  },
  SET_SERVICE_POINT_ORDERS(state: IState, payload: ServicePoint[]): void {
    state.servicePointsOrders = payload;
  },
  SET_CENTERS(state: IState, payload: Center[]): void {
    state.centers = payload;
  },
  SET_ENTITIES(state: IState, payload: Entity[]): void {
    state.entities = payload;
  },
  SET_STATUSES(state: IState, payload: OrderStatus[]): void {
    state.statuses = payload;
  },
  SET_COMMODITIES(state: IState, payload: Commodity[]): void {
    state.commodities = payload;
  },
  SET_COMMODITIES_SERVICE(state: IState, payload: Commodity[]): void {
    state.commoditiesService = payload;
  },
  SET_COUNTRIES_ORDERS(state: IState, payload: Country[]): void {
    state.countriesOrders = payload;
  },
  SET_ASSISTANCE_FAILURES(state: IState, payload: AssistanceFailure[]): void {
    state.assistanceFailures = payload;
  },
};
