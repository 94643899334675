import { RawSupply } from "@/orders/entities";

export class Supply {
  readonly id: string;
  readonly supplyTypeDescription: string;
  readonly supplyTypeCode: string;
  readonly supplyCode: string;
  readonly supplyDescription: string;
  readonly active: boolean;

  constructor(data: RawSupply) {
    this.id = data.id;
    this.supplyTypeDescription = data.supplyTypeDescription;
    this.supplyTypeCode = data.supplyTypeCode;
    this.supplyCode = data.supplyCode;
    this.supplyDescription = data.supplyDescription;
    this.active = data.active;
  }
}
