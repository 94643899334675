import Vue from "vue";
import { BACKEND_BASE_URL, ServicePointService } from "@/services";
import { RawServicePoint, RawServicePoints, ServicePoint, ServicePoints } from "@/entities";

export class ServicePointServiceImpl implements ServicePointService {
  async fetchServicePointById(servicePointId: string): Promise<ServicePoint> {
    const { data } = await Vue.$axios.get<RawServicePoint>(`${BACKEND_BASE_URL}/api/v1/servicePoint/${servicePointId}`);
    return new ServicePoint(data);
  }
  async fetchServicePoints(): Promise<ServicePoints> {
    const { data } = await Vue.$axios.get<RawServicePoints>(`${BACKEND_BASE_URL}/api/v1/me/servicePoints`);
    return new ServicePoints(data);
  }
}
