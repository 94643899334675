import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import Vue, { PluginObject } from "vue";

const userManager = new UserManager({
  authority: process.env.VUE_APP_OIDC_AUTHORITY as string,
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID as string,
  redirect_uri: `${window.origin}/callback`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  response_mode: "query",
  automaticSilentRenew: true,
  response_type: "code",
  scope: process.env.VUE_APP_OIDC_SCOPES as string,
  post_logout_redirect_uri: `${window.origin}`,
  popup_post_logout_redirect_uri: `${window.origin}`,
  filterProtocolClaims: true,
});

const oidcClientPlugin: PluginObject<unknown> = {
  install: (vue) => {
    vue.$auth = userManager;
  },
};

oidcClientPlugin.install = (vue) => {
  vue.$auth = userManager;
  window.auth = userManager;
  Object.defineProperties(vue.prototype, {
    $auth: {
      get() {
        return userManager;
      },
    },
  });
};

Vue.use(oidcClientPlugin);

export default oidcClientPlugin;
