
import { Component, Prop, Vue } from "vue-property-decorator";
import { DenominationData } from "@/orders";
import Icon from "@/orders/components/Icon/index.vue";
import LabelComparator from "@/orders/components/ComparatorInput/LabelComparator/LabelComparator.vue";

@Component({
  components: {
    LabelComparator,
    Icon,
  },
})
export default class ComparatorInput extends Vue {
  @Prop({ required: true }) denomination!: DenominationData;
  @Prop({ type: Number, required: true }) numberReference!: number;
  @Prop({ type: String, required: true }) id!: string;

  arrow = require("@/orders/assets/icons/icon-arrow-left.svg");
  arrowInColor = require("@/orders/assets/icons/icon-arrow-left-2.svg");

  isRoundedQuantity = false;
  roundedQuantity = 0;
  isRoundedValue = false;
  roundedValue = 0;
  quantity = 0;
  value = 0;
  symbol = "$";

  roundedLabel = this.$t("orders.creation.roundedTo") as string;

  created(): void {
    const denominationValue = this.denomination.denominationValue;
    if (denominationValue) {
      this.quantity = denominationValue.quantity;
      this.value = denominationValue.valueUpdated;
      this.symbol = this.denomination.currency.symbol;
    }
  }

  onQuantityChange() {
    this.isRoundedValue = false;
    const isDecimal = this.quantity % 1 !== 0;

    if (isDecimal) {
      this.roundedQuantity = this.roundToInteger(this.quantity);
      this.isRoundedQuantity = true;
    } else {
      this.isRoundedQuantity = false;
    }

    const arg1 = this.isRoundedQuantity ? this.roundedQuantity : this.quantity;
    this.value = this.updateValue(arg1);
  }

  roundToInteger(number: number): number {
    return Math.round(number);
  }

  updateValue(quantity: number): number {
    return quantity * this.numberReference;
  }

  onValueChange() {
    this.isRoundedQuantity = false;
    const isDecimal = this.value % 1 !== 0;

    if (isDecimal) {
      this.roundedValue = this.roundToCompatibleValue(this.value);
      this.isRoundedValue = true;
    } else {
      this.isRoundedValue = !this.isValueCompatible(this.value);
      if (this.isRoundedValue) {
        this.roundedValue = this.roundToCompatibleValue(this.value);
      }
    }

    const arg1 = this.isRoundedValue ? this.roundedValue : this.value;
    const newQuantity = arg1 / this.numberReference;
    this.quantity = this.roundToInteger(newQuantity);
  }

  roundToCompatibleValue(value: number): number {
    return this.roundToInteger(value / this.numberReference) * this.numberReference;
  }

  isValueCompatible(value: number): boolean {
    return value % this.numberReference === 0;
  }

  exit() {
    this.$emit("exit");
  }

  add() {
    const valueRounded = this.isRoundedValue ? this.roundedValue : this.value;
    const quantityRounded = this.isRoundedQuantity ? this.roundedQuantity : this.quantity;
    this.denomination.denominationValue.valueUpdated = valueRounded;
    this.denomination.denominationValue.quantity = quantityRounded;

    const idDenomination = this.id;
    this.$emit("add", idDenomination);
  }
}
