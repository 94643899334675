import { RawCurrencyInfo } from "./currencyInfo.types";

export class CurrencyInfo {
  readonly isoCode: string;
  readonly minorUnits: number;
  readonly exponent: number;
  constructor(data: RawCurrencyInfo) {
    this.isoCode = data.isoCode;
    this.minorUnits = data.minorUnits;
    this.exponent = data.exponent;
  }
}
