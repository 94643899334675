import { BACKEND_ORDERS_BASE_URL } from "@/services";
import Vue from "vue";
import { StatusService } from "./status.types";
import { OrderStatus, RawOrderStatus } from "@/orders";

export class StatusServiceImpl implements StatusService {
  async fetchStatuses(): Promise<OrderStatus[]> {
    const { data } = await Vue.$axios.get<RawOrderStatus[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/order-statuses?statusesShowInGenkinOrder=true`
    );
    return data.map((value) => new OrderStatus(value));
  }
}
