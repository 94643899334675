
import { Component, Prop, Vue } from "vue-property-decorator";
import Dropdown from "@/orders/components/Dropdown/Dropdown.vue";
import { AssistanceFailure, OrderAssistanceData, FailureType, OrderDTO } from "@/orders";

@Component({
  components: {
    Dropdown,
  },
})
export default class StepAssistance extends Vue {
  @Prop({ required: true }) value!: OrderAssistanceData;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;

  assistanceFailures: AssistanceFailure[] = [];
  listErrorType: FailureType[] = [];
  listFailureType: FailureType[] = [];
  failureType = "";
  errorType = "";
  comments = "";

  // Methods
  get failureTypes(): string[] {
    return this.listFailureType.map((item) => item.description);
  }

  get errorsTypes(): string[] {
    return this.listErrorType.map((item) => item.description);
  }

  onSelectFailureType(): void {
    this.listErrorType = this.assistanceFailures.filter((item) => item.failureType.description === this.failureType);
    this.errorType = this.$t("orders.action.select").toString();
  }

  onSelectErrorType(): void {
    if (this.errorType && this.failureType) {
      this.onSettingAssistanceService();
    }
  }

  onSettingAssistanceService(): void {
    const id = this.assistanceFailures.find(
      (item) => item.failureType.description === this.failureType && item.description === this.errorType
    )?.id;
    if (id) {
      const newValue: OrderAssistanceData = {
        assistanceFailureId: id,
      };
      this.$emit("input", newValue);
    }
  }

  async created(): Promise<void> {
    const countryCode = this.notChangeOrder.country.code;
    this.assistanceFailures = await this.$services.assistanceFailure.fetchAssistanceFailures(countryCode);

    if (this.value) {
      const assistanceFailure = this.assistanceFailures.find((item) => item.id === this.value.assistanceFailureId);
      this.failureType = assistanceFailure?.failureType.description! || "";
      this.errorType = assistanceFailure?.description || "";
    } else if (this.notChangeOrder) {
      const assistanceFailure = this.assistanceFailures.find((item) => item.id === this.value.assistanceFailureId);
      this.failureType = assistanceFailure?.failureType.description || "";
      this.errorType = assistanceFailure?.description || "";
    }

    this.assistanceFailures.forEach((item) => {
      if (!this.listFailureType.some((failure) => failure.code === item.failureType.code)) {
        this.listFailureType.push(item.failureType);
      }
    });
  }
}
