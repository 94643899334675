
import { Component, Vue, Prop } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { ServicePointBalance } from "@/entities";

@Component({
  components: {
    PIcon,
  },
})
export default class ServicePointDetailHeader extends Vue {
  @Prop() public servicePointBalance!: ServicePointBalance;
}
