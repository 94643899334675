import { RawCenter } from "@/orders/entities/center/center.types";
import { Center } from "@/orders/entities/center/center";

export const mockRawCenters = (): RawCenter[] => [
  {
    id: "1",
    code: "1",
    name: "España",
    cityName: "Segovia",
    provinceName: "Segovia",
    zipCode: "40002",
    address: "Avd Conde Sepúlveda",
    frequencyUse: 1,
  },
  {
    id: "2",
    code: "2",
    name: "España",
    cityName: "Segovia",
    provinceName: "Segovia",
    zipCode: "40004",
    address: "Calle José Zorrilla",
    frequencyUse: 1,
  },
];

export const mockCenters = (data: RawCenter[] = mockRawCenters()): Center[] => data.map((item) => new Center(item));
