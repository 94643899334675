import { RawFleet } from "@/orders";

export class Fleet {
  readonly id: string;
  readonly code: string;
  readonly plate: string;
  readonly model: string;
  readonly delegationId: string;

  constructor(data: RawFleet) {
    this.id = data.id;
    this.code = data.code;
    this.plate = data.plate;
    this.model = data.model;
    this.delegationId = data.delegationId;
  }
}
