
import { Component, Vue } from "vue-property-decorator";
import { PButton } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "button-back-to-dashboard",
  components: {
    PButton,
    PIcon,
  },
})
export class ButtonBackToDashboard extends Vue {
  constructor() {
    super();
  }
}
export default ButtonBackToDashboard;
