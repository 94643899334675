
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { PFormSelectNarrowTranslations, translations } from "./pFormSelectNarrowTranslations";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class PFormSelectNarrow extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false }) translation!: PFormSelectNarrowTranslations;
  @Prop({ required: false, default: "es" }) language!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }

  pussOption = false;
  selectedItemValue = "";
  pulsadoApertura = false;
  translator: PFormSelectNarrowTranslations = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  get classInput(): string {
    if (this.pussOption) {
      return "inputSelectNarrowOpen pointer";
    } else {
      return "inputSelectNarrow pointer";
    }
  }
  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue);
    if (optionFind) {
      return optionFind.text;
    } else {
      return this.translator.textSelectAnOption;
    }
  }

  selectItem(itemValue: any): void {
    this.selectedItemValue = itemValue;
    this.pussOption = false;
    this.$emit("input", itemValue);
    this.$emit("change", itemValue);
  }
  giveMeClass(itemValue: any): string {
    if (this.selectedItemValue == itemValue) {
      return "pointer itemSelectNarrowSelected";
    } else {
      return "pointer itemSelectNarrow";
    }
  }
}
export default PFormSelectNarrow;
