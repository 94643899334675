import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { RawUserPreferences, UserPreferences } from "@/entities";
import { UserPreferencesService } from "./userPreferences.types";

export class UserPreferencesServiceImpl implements UserPreferencesService {
  async fetchUserPreferences(): Promise<UserPreferences> {
    const url = `${BACKEND_BASE_URL}/api/v1/me/userpreferences`;
    const { data } = await Vue.$axios.get<RawUserPreferences>(url);
    return new UserPreferences(data);
  }
  async saveUserPreferences(userPreferences: UserPreferences): Promise<void> {
    const url = `${BACKEND_BASE_URL}/api/v1/me/userpreferences`;
    const body = userPreferences;
    await Vue.$axios.put(url, body);
  }
}
