import { Denomination } from "@/orders/entities/denomination";
import { RawPackAmounts } from "@/orders/entities";

export class PackAmounts {
  readonly id: string;
  readonly quantity: number;
  readonly total: number;
  readonly denomination: Denomination;

  constructor(data: RawPackAmounts) {
    this.id = data.id;
    this.quantity = data.quantity;
    this.total = data.total;
    this.denomination = data.denomination;
  }
}
