import { Currency } from "@/orders";
import { RawRequestedCurrency } from "@/orders/entities/requestedCurrency/requestedCurrency.types";

export class RequestedCurrency {
  readonly currency: Currency;
  readonly total: number;

  constructor(data: RawRequestedCurrency) {
    this.currency = new Currency(data.currency);
    this.total = data.total;
  }
}

export interface CreateRequestedCurrency {
  currencyId: string;
  total: number;
}

export interface RequestedCurrencyData {
  currency: Currency;
  quantity: number;
}
