import { DeviceDetails } from "./deviceDetails";
import { RawDeviceDetails } from "./deviceDetails.types";

export const mockRawDevice = (): RawDeviceDetails => ({
  countryCode: "PE",
  currencies: ["PEN"],
  delegationCode: "PE240",
  deviceCode: "014601051001",
  deviceIp: "190.90.90.90",
  deviceManufacturer: "----",
  deviceModel: "------",
  deviceName: "DEVICE - P161 COESTI E/S COLONIAL 2 MINI MAE (FV)",
  devicePort: 8080,
  deviceType: "------",
  id: "6b3453c5-f6bd-3767-a96c-975ced40688a",
  maxCoinCapacity: 0,
  maxNoteCapacity: 0,
  solutionType: "------",
});

export const mockDevice = (data: RawDeviceDetails = mockRawDevice()): DeviceDetails => new DeviceDetails(data);
