import { Transactions } from "./transaction";
import { RawTransactions } from "./transaction.types";

export const mockRawTransactions = (): RawTransactions => {
  return {
    totalResult: 0,
    projections: [
      {
        id: "e48992ae-51fa-4e27-bd33-05c4f56f9b5d",
        entity: {
          id: "bd5ed482-5a0a-3865-be5b-06d36058daf4",
          code: "041836",
          name: "BR003042G - REDE G4 POSTOS",
          imageLink:
            "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0066.png",
          countryCode: "BR",
        },
        teller: "0418366001700000010",
        amounts: [
          {
            total: 800,
            category: "CAT_4",
            currency: "BRL",
            barCodes: ["CA4145536", "CA4145537"],
            numItems: 4,
          },
        ],
        actualId: "20221110154833_041836600170_CASHIN",
        centerId: "d73b4e7d-7fb6-3cf0-a96a-7b88eb83498a",
        deviceId: "041836600170",
        entityId: "bd5ed482-5a0a-3865-be5b-06d36058daf4",
        timeZone: "GMT-03:00",
        collectionId: "N20221223194149",
        countryId: "BR",
        centerCode: "6001",
        channel: "canal",
        currencies: [
          {
            iso: "BRL",
            exponent: -2,
          },
        ],
        delegation: "085",
        extraDatas: [
          {
            name: "url",
            value: "url",
            category: "",
            currency: "",
          },
        ],
        tellerName: "SAMUEL PUPO",
        tellerLogin: "10",
        denominations: [
          {
            date: "",
            type: "NOTE",
            total: 800,
            value: 200,
            barCode: "",
            boxName: "NOTEACCEPTOR",
            subType: "",
            category: "CAT_4",
            codeLine: "",
            currency: "BRL",
            numItems: 4,
            cardNumber: "",
            description: "",
            collectionId: "",
          },
          {
            date: "",
            type: "DROPSAFE",
            total: 800,
            value: 200,
            barCode: "CA4145536",
            boxName: "AGGR",
            subType: "",
            category: "CAT_4",
            codeLine: "",
            currency: "BRL",
            numItems: 4,
            cardNumber: "",
            description: "",
            collectionId: "",
          },
          {
            date: "",
            type: "DROPSAFE",
            total: 800,
            value: 200,
            barCode: "CA4145537",
            boxName: "AGGR",
            subType: "",
            category: "CAT_4",
            codeLine: "",
            currency: "BRL",
            numItems: 4,
            cardNumber: "",
            description: "",
            collectionId: "",
          },
        ],
        deviceDetails: {
          id: "a83733b1-ff80-31e1-9a8d-cab5f0d0d8d1",
          deviceCode: "041836600170",
          deviceIp: "190.90.90.90",
          currencies: ["BRL"],
          deviceName: "DEVICE - CENTRO AUTOMOTIVO PORTAL JARDIM SUL LTDA",
          devicePort: 8080,
          deviceType: "------",
          countryCode: "BR",
          deviceModel: "SDM100",
          solutionType: "------",
          delegationCode: "BR085",
          maxCoinCapacity: 0,
          maxNoteCapacity: 4000,
          deviceManufacturer: "CIMA",
        },
        receiptNumber: "7943",
        strapSealCode: "",
        accountingDate: "2022-11-15T15:48:33",
        servicePoint: {
          id: "186e99f2-dc60-3766-9455-8fe51b9da9ed",
          isEarlyValue: false,
          name: "PLAZA OESTE II - SC ",
          centerCode: "001",
          centerName: "001",
        },
        servicePointId: "186e99f2-dc60-3766-9455-8fe51b9da9ed",
        systemEntryDate: "2022-11-15T15:48:33",
        transactionRef: "",
        transactionDate: "2022-11-15T15:48:33",
        transactionInfo: "",
        transactionType: "CASHIN",
        logicCashTodayId: "cc43cc35-e850-3aa9-830f-6a0e99088e70",
        servicePointCode: "70",
        origin: "CASH",
        hasAccreditation: false,
        hasNotification: false,
        amountDetails: [
          {
            total: 800,
            category: "CAT_4",
            currency: "BRL",
            barCodes: ["CA4145536", "CA4145537"],
            accreditation: undefined,
            notification: undefined,
          },
        ],
      },
    ],
  };
};

export const mockTransactions = (data: RawTransactions = mockRawTransactions()): Transactions => new Transactions(data);
