
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Country, Employee, Fleet } from "@/orders";
import Icon from "@/orders/components/Icon/index.vue";
import VueMethods from "@/vue-methods";
import Options from "@/orders/components/Options/index.vue";
import { OptionType } from "@/orders/components/Options/Options.types";
import PModal from "@/common/components/look/PModal/PModal.vue";

@Component({
  components: {
    Icon,
    Options,
    PModal,
  },
  mixins: [VueMethods],
})
export class EmployeesViewPage extends Vue {
  @Getter("getCountriesOrders") getCountries!: Country[];

  searchIcon = require("@/orders/assets/icons/icon-search.svg");
  excel = require("@/orders/assets/icons/icon-excel.svg");
  prosegur = require("@/orders/assets/icons/icon-prosegur.svg");
  photoDefault = require("@/orders/assets/icons/icon-icn-user.svg");
  signatureDefault = require("@/orders/assets/icons/icon-icn-edit-3.svg");

  get buttonDisabled(): boolean {
    return !(this.filterCountry != null && this.filterValue.length > 0);
  }
  openModal = false;
  filterCountry: OptionType | null = null;
  optionsFilterCountry: OptionType[] = [];
  filterSearchType: OptionType = {
    id: "1",
    name: this.$t("orders.employees.identify") as string,
  };
  optionsFilterSearchType: OptionType[] = [
    {
      id: "1",
      name: this.$t("orders.employees.identify") as string,
    },
    {
      id: "2",
      name: this.$t("orders.employees.license") as string,
    },
  ];
  filterValue = "";
  employees: Employee[] = [];
  selectedEmployee: Employee | null = null;
  fleet: Fleet | null = null;

  search(): void {
    if (this.filterSearchType.id === "1") {
      this.$services.crew.fetchByIdentify(this.filterValue, this.filterCountry!.id).then((value) => {
        this.employees = value;
        const employee = this.employees.find((value) => value.workBadge == this.filterValue);
        if (employee) {
          this.selectedEmployee = employee;
        } else {
          this.selectedEmployee = this.employees[0];
        }
      });
    } else {
      this.$services.crew.fetchByLicense(this.filterValue, this.filterCountry!.id).then((value) => {
        this.fleet = value;
      });
    }
  }

  changeEmployee(item: Employee): void {
    this.selectedEmployee = item;
  }

  resetTypeFilter(): void {
    this.fleet = null;
    this.selectedEmployee = null;
  }

  activeClass(item: Employee): string {
    return this.selectedEmployee && this.selectedEmployee.workBadge == item.workBadge ? "" : "c-button--outline";
  }

  signatureNotFound(event: any) {
    event.target.src = this.signatureDefault;
    event.target.style.opacity = 0.1;
    event.target.style.boxShadow = "1px 1px 3px 2px #000000";
  }
  photoNotFound(event: any) {
    event.target.src = this.photoDefault;
    event.target.style.opacity = 0.1;
    event.target.style.boxShadow = "1px 1px 3px 2px #000000";
  }

  mounted(): void {
    setTimeout(() => {
      this.getCountries.forEach((value) => this.optionsFilterCountry.push({ id: value.code, name: value.description }));
    }, 1000);
  }

  sendReport(): void {
    this.openModal = false;
  }
}
export default EmployeesViewPage;
