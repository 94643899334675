import {
  RawServicePoint,
  RawServicePointCenter,
  RawServicePointDeviceDetails,
  RawServicePointEntity,
  RawServicePoints,
} from "./servicePoint.types";

export class ServicePointEntity {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly imageLink: string;
  readonly countryCode: string;
  constructor(data: RawServicePointEntity) {
    this.id = data?.id || "";
    this.code = data?.code || "";
    this.name = data?.name || "";
    this.imageLink = data?.imageLink || "";
    this.countryCode = data?.countryCode || "";
  }
}

export class ServicePointCenter {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly entityId: string;
  constructor(data: RawServicePointCenter) {
    this.id = data?.id || "";
    this.code = data?.code || "";
    this.name = data?.name || "";
    this.entityId = data?.entityId || "";
  }
}

export class ServicePointDeviceDetails {
  readonly id: string;
  readonly deviceIp: string;
  readonly currencies: string[];
  readonly deviceCode: string;
  readonly deviceName: string;
  readonly devicePort: number;
  readonly deviceType: string;
  readonly countryCode: string;
  readonly deviceModel: string;
  readonly solutionType: string;
  readonly delegationCode: string;
  readonly maxCoinCapacity: number;
  readonly maxNoteCapacity: number;
  readonly deviceManufacturer: string;
  constructor(data: RawServicePointDeviceDetails) {
    this.id = data?.id || "";
    this.deviceIp = data?.deviceIp || "";
    this.currencies = data?.currencies || "";
    this.deviceCode = data?.deviceCode || "";
    this.deviceName = data?.deviceName || "";
    this.devicePort = data?.devicePort || 0;
    this.deviceType = data?.deviceType || "";
    this.countryCode = data?.countryCode || "";
    this.deviceModel = data?.deviceModel || "";
    this.solutionType = data?.solutionType || "";
    this.delegationCode = data?.delegationCode || "";
    this.maxCoinCapacity = data?.maxCoinCapacity || 0;
    this.maxNoteCapacity = data?.maxNoteCapacity || 0;
    this.deviceManufacturer = data?.deviceManufacturer || "";
  }
}

export class ServicePoint {
  readonly id: string;
  readonly name: string;
  readonly isEarlyValue: boolean;
  readonly servicePointCode: string;
  readonly entityId: string;
  readonly entity: ServicePointEntity;
  readonly centerId: string;
  readonly center: ServicePointCenter;
  readonly deviceDetailsId: string;
  readonly deviceDetails: ServicePointDeviceDetails;
  constructor(data: RawServicePoint) {
    this.id = data?.id || "";
    this.name = data?.name || "";
    this.isEarlyValue = data?.isEarlyValue || false;
    this.servicePointCode = data?.servicePointCode || "";
    this.entityId = data?.entityId || "";
    this.entity = new ServicePointEntity(data.entity);
    this.centerId = data?.centerId || "";
    this.center = new ServicePointCenter(data?.center);
    this.deviceDetailsId = data?.deviceDetailsId || "";
    this.deviceDetails = new ServicePointDeviceDetails(data.deviceDetails);
  }
}

export class ServicePoints {
  readonly servicePoints?: ServicePoint[];
  constructor(data: RawServicePoints) {
    const servicePoints: ServicePoint[] = [];
    data.servicePoints.forEach((sp) => {
      servicePoints.push(new ServicePoint(sp));
    });
    this.servicePoints = servicePoints;
  }
}
