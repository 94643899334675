
import { DenominationPackData, DenominationPackFromCurrencyCode, DenominationPackType } from "@/orders";
import { Component, Prop, Vue } from "vue-property-decorator";
import ComparatorInputPack from "@/orders/components/ComparatorInputPack/ComparatorInputPack.vue";
import Utils from "@/orders/components/Commons/utils";
import PDropdown from "@/orders/components/Dropdown/Dropdown.vue";
import Icon from "../Icon/index.vue";
import ToastPack from "@/orders/components/ToastPack/index.vue";

@Component({
  components: {
    PDropdown,
    ComparatorInputPack,
    Icon,
    ToastPack,
  },
})
export default class TableDeliveryPack extends Vue {
  @Prop({ required: true }) denominationsPacksFromCurrency!: DenominationPackFromCurrencyCode;
  @Prop({ required: true }) currenciesCode!: string[];
  @Prop({ required: true }) currentPack!: string;

  iconInfo: string = require("@/orders/assets/icons/icon-info.svg");
  currentPackTranslate = "";

  created(): void {
    this.currentPackTranslate = DenominationPackType.PACKS.description;
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  currencyFormat2Digits(amount: number): string {
    return amount.toLocaleString("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  addExpand(id: string): void {
    const denominationsPacksData = this.denominationsPacksFromCurrency.denominationsPacks;
    const found = denominationsPacksData.find((denominationPack) => denominationPack.id === id);
    if (found) {
      found.expand = false;
      this.$emit("updateDenominationsPacks", {
        ...this.denominationsPacksFromCurrency,
        currencyCode: found.currency.code,
      });
    }
  }

  get denominationsPacks(): DenominationPackData[] {
    return this.denominationsPacksFromCurrency.denominationsPacks;
  }
}
