import { TransactionCosmosDetail } from "./transactionCosmosDetail";
import { RawTransactionCosmosDetail } from "./transactionCosmosDetail.types";

export const mockRawTransactionsCosmosDetail = (): RawTransactionCosmosDetail => {
  return {
    currency: "string",
    origin: "string",
    isValid: true,
    currencyInfo: {
      isoCode: "string",
      minorUnits: 0,
      exponent: 0,
    },
    amount: {
      currency: "string",
      total: 0,
      numItems: 0,
      isValid: true,
      barCodes: ["string"],
    },
    denominations: [
      {
        barCode: "string",
        boxName: "string",
        boxDescription: "string",
        category: "string",
        cardNumber: "string",
        codeLine: "string",
        collectionId: "string",
        currency: "string",
        date: "string",
        description: "string",
        isValid: true,
        numItems: 0,
        origin: "string",
        subType: "string",
        total: 0,
        type: "string",
        value: 0,
      },
    ],
  };
};

export const mockTransactionsCosmosDetail = (
  data: RawTransactionCosmosDetail = mockRawTransactionsCosmosDetail()
): TransactionCosmosDetail => new TransactionCosmosDetail(data);
