import { LocalDateTime } from "@js-joda/core";

export const parseLocalDateTime = (datetime: string | undefined): LocalDateTime | undefined => {
  let returnTemp = undefined;
  if (datetime == undefined) return returnTemp;
  try {
    returnTemp = LocalDateTime.parse(datetime);
  } catch (e) {
    // nothing
  }
  return returnTemp;
};
