
import { Component, Vue } from "vue-property-decorator";
import { PRow, PCol } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "panel-widgets",
  components: {
    PRow,
    PCol,
  },
})
export class PanelWidgets extends Vue {
  public visible = false;

  constructor() {
    super();
  }
}
export default PanelWidgets;
