import { RawCancellationReason } from "@/orders/entities";

export class CancellationReason {
  readonly id: string;
  readonly code: string;
  readonly description: string;

  constructor(data: RawCancellationReason) {
    this.id = data.id;
    this.code = data.code;
    this.description = data.description;
  }
}
