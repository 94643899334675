import { CreateRequestedCurrency, RequestedCurrencyData } from "@/orders/entities/requestedCurrency";
import {
  Commodity,
  Country,
  CreateRequestDenomination,
  CreateRequestedDenominationPack,
  CreateRequestedSupply,
  OrderData,
  OrderProgrammingHourType,
  RequestedDenomination,
  RequestedDenominationPack,
  RequestedSupply,
  ServicePoint,
} from "@/orders/entities";

interface OrderFieldsBase {
  comments: string;
  serviceDate: string;
  requestInitialTime: string;
  requestFinalTime: string;
  servicePointId: string;
}

interface CreateOrder {
  comments: string;
  serviceDate: string;
  requestInitialTime: string;
  requestFinalTime: string;
  servicePointId: string;
}

interface UpdateOrder {
  orderId: string;
  comments: string;
  serviceDate: string;
  requestInitialTime: string;
  requestFinalTime: string;
}

export interface OrderDTO {
  commodity: Commodity;
  servicePoint: ServicePoint;
  country: Country;
  orderData?: OrderData;
  orderId?: string;
}

//Order-Data
export interface OrderDeliveryData {
  requestedDenominations: RequestedDenomination[];
  requestedDenominationPacks: RequestedDenominationPack[];
  totalPrice: number;
}

export interface OrderDeliveryChangeData {
  requestedDenominations: RequestedDenomination[];
  requestedDenominationPacks: RequestedDenominationPack[];
  totalPrice: number;
}

export interface OrderAssistanceData {
  assistanceFailureId: string;
}

export interface OrderPickupData {
  requestedCurrencies: RequestedCurrencyData[];
  undeclaredAmount: boolean;
}

export interface OrderSupplyData {
  requestedSupplies: RequestedSupply[];
}

//Create-Data
export interface CreateOrderAssistanceData extends OrderFieldsBase {
  assistanceFailureId: string;
}

export interface CreateOrderPickupData extends OrderFieldsBase {
  hasPickupDeclared: boolean;
  requestedCurrencies: RequestedCurrencyData[];
}

export interface CreateOrderDeliveryData extends OrderFieldsBase {
  requestedDenominations: RequestedDenomination[];
  requestedDenominationPacks: RequestedDenominationPack[];
}

export interface CreateOrderDeliveryChangeData extends OrderFieldsBase {
  requestedDenominations: RequestedDenomination[];
  requestedDenominationPacks: RequestedDenominationPack[];
}

export interface CreateOrderSupplyData extends OrderFieldsBase {
  requestedSupplies: RequestedSupply[];
}

//Create-Service
export interface CreateOrderPickupService extends CreateOrder {
  hasPickupDeclared?: boolean;
  requestedCurrencies: CreateRequestedCurrency[];
}

export interface CreateOrderDeliveryService extends CreateOrder {
  requestedDenominations: CreateRequestDenomination[];
  requestedDenominationPacks: CreateRequestedDenominationPack[];
}

export interface CreateOrderDeliveryChangeService extends CreateOrder {
  requestedDenominations: CreateRequestDenomination[];
  requestedDenominationPacks: CreateRequestedDenominationPack[];
}

export interface CreateOrderAssistanceService extends CreateOrder {
  assistanceFailureId: string;
}

export interface CreateOrderSupplyService extends CreateOrder {
  requestedSupplies: CreateRequestedSupply[];
}

//Update-Service
export interface UpdateOrderPickupService extends UpdateOrder {
  hasPickupDeclared?: boolean;
  requestedCurrencies: CreateRequestedCurrency[];
}

export interface UpdateOrderDeliveryService extends UpdateOrder {
  requestedDenominations: CreateRequestDenomination[];
  requestedDenominationPacks: CreateRequestedDenominationPack[];
}

export interface UpdateOrderDeliveryChangeService extends UpdateOrder {
  requestedDenominations: CreateRequestDenomination[];
  requestedDenominationPacks: CreateRequestedDenominationPack[];
}

export interface UpdateOrderAssistanceService extends UpdateOrder {
  assistanceFailureId: string;
}

export interface UpdateOrderSupplyService extends UpdateOrder {
  requestedSupplies: CreateRequestedSupply[];
}

export class OrderDateDTO {
  serviceDate: Date;
  requestInitialTime: Date;
  requestFinalTime: Date;
  typeDate: OrderProgrammingHourType;

  constructor(serviceDate: Date, requestInitialTime: Date, requestFinalTime: Date, typeDate: OrderProgrammingHourType) {
    this.serviceDate = serviceDate;
    this.requestInitialTime = requestInitialTime;
    this.requestFinalTime = requestFinalTime;
    this.typeDate = typeDate;
  }

  private formatDate(date: Date): string {
    return date.toISOString();
  }

  get serviceDateString(): string {
    return this.formatDate(this.serviceDate);
  }

  get requestInitialTimeString(): string {
    return this.formatDate(this.requestInitialTime);
  }

  get requestFinalTimeString(): string {
    return this.formatDate(this.requestFinalTime);
  }
}

export class FactoryCreateOrder {
  static buildCreateOrderDeliveryService(
    delivery: CreateOrderDeliveryData,
    servicePointId: string
  ): CreateOrderDeliveryService {
    const requestedDenominationPacks = delivery.requestedDenominationPacks.map((denominationPack) => {
      return {
        denominationPackId: denominationPack.denominationPack.id,
        quantity: denominationPack.quantity,
      };
    });
    const requestedDenominations = delivery.requestedDenominations.map((denomination) => {
      return {
        denominationId: denomination.denomination.id,
        quantity: denomination.quantity,
      };
    });
    return {
      comments: delivery.comments,
      serviceDate: delivery.serviceDate,
      requestInitialTime: delivery.requestInitialTime,
      requestFinalTime: delivery.requestFinalTime,
      servicePointId: servicePointId,
      requestedDenominationPacks: requestedDenominationPacks,
      requestedDenominations: requestedDenominations,
    };
  }

  static buildCreateOrderDeliveryChangeService(
    delivery: CreateOrderDeliveryChangeData,
    servicePointId: string
  ): CreateOrderDeliveryChangeService {
    const requestedDenominationPacks = delivery.requestedDenominationPacks.map((denominationPack) => {
      return {
        denominationPackId: denominationPack.denominationPack.id,
        quantity: denominationPack.quantity,
      };
    });
    const requestedDenominations = delivery.requestedDenominations.map((denomination) => {
      return {
        denominationId: denomination.denomination.id,
        quantity: denomination.quantity,
      };
    });
    return {
      comments: delivery.comments,
      serviceDate: delivery.serviceDate,
      requestInitialTime: delivery.requestInitialTime,
      requestFinalTime: delivery.requestFinalTime,
      servicePointId: servicePointId,
      requestedDenominationPacks: requestedDenominationPacks,
      requestedDenominations: requestedDenominations,
    };
  }

  static buildCreateOrderPickupService(
    pickup: CreateOrderPickupData,
    servicePointId: string
  ): CreateOrderPickupService {
    const requestedCurrencies = pickup.requestedCurrencies.map((currency) => {
      return {
        currencyId: currency.currency.id,
        total: currency.quantity,
      };
    });
    return {
      comments: pickup.comments,
      serviceDate: pickup.serviceDate,
      requestInitialTime: pickup.requestInitialTime,
      requestFinalTime: pickup.requestFinalTime,
      servicePointId: servicePointId,
      hasPickupDeclared: pickup.hasPickupDeclared,
      requestedCurrencies: requestedCurrencies,
    };
  }

  static buildCreateOrderAssistanceService(
    assistance: CreateOrderAssistanceData,
    servicePointId: string
  ): CreateOrderAssistanceService {
    return {
      comments: assistance.comments,
      serviceDate: assistance.serviceDate,
      requestInitialTime: assistance.requestInitialTime,
      requestFinalTime: assistance.requestFinalTime,
      servicePointId: servicePointId,
      assistanceFailureId: assistance.assistanceFailureId,
    };
  }

  static buildCreateOrderSupplyService(
    supply: CreateOrderSupplyData,
    servicePointId: string
  ): CreateOrderSupplyService {
    const requestedSupplies = supply.requestedSupplies.map((supply) => {
      return {
        supplyId: supply.supply.id,
        quantity: supply.quantity,
      };
    });
    return {
      comments: supply.comments,
      serviceDate: supply.serviceDate,
      requestInitialTime: supply.requestInitialTime,
      requestFinalTime: supply.requestFinalTime,
      servicePointId: servicePointId,
      requestedSupplies: requestedSupplies,
    };
  }
}

export class FactoryUpdateOrder {
  static buildUpdateOrderAssistanceService(
    assistance: CreateOrderAssistanceData,
    orderId: string
  ): UpdateOrderAssistanceService {
    return {
      orderId: orderId,
      comments: assistance.comments,
      serviceDate: assistance.serviceDate,
      requestInitialTime: assistance.requestInitialTime,
      requestFinalTime: assistance.requestFinalTime,
      assistanceFailureId: assistance.assistanceFailureId,
    };
  }

  static buildUpdateOrderSupplyService(supply: CreateOrderSupplyData, orderId: string): UpdateOrderSupplyService {
    const requestedSupplies = supply.requestedSupplies.map((supply) => {
      return {
        supplyId: supply.supply.id,
        quantity: supply.quantity,
      };
    });
    return {
      orderId: orderId,
      comments: supply.comments,
      serviceDate: supply.serviceDate,
      requestInitialTime: supply.requestInitialTime,
      requestFinalTime: supply.requestFinalTime,
      requestedSupplies: requestedSupplies,
    };
  }

  static buildUpdateOrderDeliveryService(
    delivery: CreateOrderDeliveryData,
    orderId: string
  ): UpdateOrderDeliveryService {
    const requestedDenominationPacks = delivery.requestedDenominationPacks.map((denominationPack) => {
      return {
        denominationPackId: denominationPack.denominationPack.id,
        quantity: denominationPack.quantity,
      };
    });
    const requestedDenominations = delivery.requestedDenominations.map((denomination) => {
      return {
        denominationId: denomination.denomination.id,
        quantity: denomination.quantity,
      };
    });
    return {
      orderId: orderId,
      comments: delivery.comments,
      serviceDate: delivery.serviceDate,
      requestInitialTime: delivery.requestInitialTime,
      requestFinalTime: delivery.requestFinalTime,
      requestedDenominationPacks: requestedDenominationPacks,
      requestedDenominations: requestedDenominations,
    };
  }

  static buildUpdateOrderDeliveryChangeService(
    delivery: CreateOrderDeliveryChangeData,
    orderId: string
  ): UpdateOrderDeliveryChangeService {
    const requestedDenominationPacks = delivery.requestedDenominationPacks.map((denominationPack) => {
      return {
        denominationPackId: denominationPack.denominationPack.id,
        quantity: denominationPack.quantity,
      };
    });
    const requestedDenominations = delivery.requestedDenominations.map((denomination) => {
      return {
        denominationId: denomination.denomination.id,
        quantity: denomination.quantity,
      };
    });
    return {
      orderId: orderId,
      comments: delivery.comments,
      serviceDate: delivery.serviceDate,
      requestInitialTime: delivery.requestInitialTime,
      requestFinalTime: delivery.requestFinalTime,
      requestedDenominationPacks: requestedDenominationPacks,
      requestedDenominations: requestedDenominations,
    };
  }

  static buildUpdateOrderPickupService(pickup: CreateOrderPickupData, orderId: string): UpdateOrderPickupService {
    const requestedCurrencies = pickup.requestedCurrencies.map((currency) => {
      return {
        currencyId: currency.currency.id,
        total: currency.quantity,
      };
    });
    return {
      orderId: orderId,
      comments: pickup.comments,
      serviceDate: pickup.serviceDate,
      requestInitialTime: pickup.requestInitialTime,
      requestFinalTime: pickup.requestFinalTime,
      hasPickupDeclared: pickup.hasPickupDeclared,
      requestedCurrencies: requestedCurrencies,
    };
  }
}
