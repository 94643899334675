import { Establishment } from "./establishment";
import { RawEstablishment } from "./establishment.types";

export const mockRawEstablishments = (): RawEstablishment[] => [
  {
    id: "1",
    name: "Tienda Las Palmas (Lanzarote)",
    numberOfMachines: 2,
  },
  {
    id: "2",
    name: "Tienda Santa Cruz de Tenerife",
    numberOfMachines: 1,
  },
];

export const mockEstablishment = (data: RawEstablishment[] = mockRawEstablishments()): Establishment[] =>
  data.map((item) => new Establishment(item));
