import { BACKEND_ORDERS_BASE_URL } from "@/services";
import Vue from "vue";
import { ServicePointOrdersService } from "@/orders";
import { RawServicePoint, ServicePoint } from "@/orders/entities/servicePoint";

export class ServicePointOrdersServiceImpl implements ServicePointOrdersService {
  async fetchServicePoints(userId: string): Promise<ServicePoint[]> {
    const { data } = await Vue.$axios.get<RawServicePoint[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/service-points/user/` + userId
    );
    return data.map((value) => new ServicePoint(value));
  }
}
