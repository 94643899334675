
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PFormCheckBox from "../PFormCheckBox/PFormCheckBox.vue";
import PDragAndDrop from "../PDragAndDrop/PDragAndDrop.vue";
import PButton from "../PButton/PButton.vue";
import { FieldPTable, FieldPTableType } from "./pTable.type";
import { iTranslationsPPersonalizeTable, translations } from "./pPersonalizeTable.translations";

interface OrderTuple {
  field: FieldPTable;
  index: number;
}

@Component({
  components: {
    PFormCheckBox,
    PDragAndDrop,
    PButton,
  },
})
export class PPersonalizeTable extends Vue {
  @Prop({ required: true }) value!: FieldPTable[];
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.updateLocalValues();
    this.value.forEach((fi, idx) => {
      if (fi.fixedOrder) this.fixedOrder.push({ field: fi, index: idx });
    });
    this.sortValues();
  }
  @Prop({ required: false }) groups!: string[];
  @Prop({ required: false }) defaultValue!: FieldPTable[];
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false }) translation!: iTranslationsPPersonalizeTable;

  fixedOrder: OrderTuple[] = [];
  valueLocal: FieldPTable[] = [];
  valueLocalSorted: FieldPTable[] = [];
  groupFields: (string | undefined)[] = [];
  groupTitleFields: (string | undefined)[] = [];
  translator: iTranslationsPPersonalizeTable = translations[0].translations;

  created(): void {
    const language = this.language.split("-").shift();
    const translation = translations.find((tran) => tran.language === language)?.translations;
    if (translation) {
      this.translator = translation;
    }
    if (this.translation) {
      this.translator = Object.assign({}, this.translator, this.translation);
    }
  }

  isHidden(fi: FieldPTable): boolean {
    return fi.type == FieldPTableType.HIDDEN;
  }
  isShowable(fi: FieldPTable): boolean {
    if (this.isHidden(fi)) return false;
    return fi.show && !fi.fixedOrder;
  }
  showTitle(index: number): boolean {
    if (index == 0 || this.groupFields[index] != this.groupFields[index - 1]) {
      return true;
    } else {
      return false;
    }
  }
  resetLocalValues(): void {
    this.valueLocal = [];
    this.valueLocalSorted = [];
    this.groupFields = [];
  }
  getFieldsByGroup(groupTitle: string): FieldPTable[] {
    return this.valueLocal.filter((field) => field.type != FieldPTableType.HIDDEN && field.groupField == groupTitle);
  }
  updateLocalValues(setDefaults?: boolean): void {
    this.resetLocalValues();
    // set to defaults, merge with customer selected
    let values: FieldPTable[] = Object.assign([], this.value);
    if (setDefaults) {
      values = [...new Map([...this.defaultValue, ...values].map((item) => [item["key"], item])).values()];
    }
    // let groupsField: string[] = [];
    // if (this.groups.length > 0) {
    //   groupsField = this.groups;
    // } else {
    //   groupsField = [...new Set(values.filter((fi) => fi.groupField).map((fi) => fi.groupField || ""))];
    // }
    values.map((fi: FieldPTable, idx: number) => {
      const localField = { ...fi };
      // fill actions label so customer can sort it
      if (this.isShowable(localField) && localField.type == FieldPTableType.ACTION && !localField.label)
        localField.label = this.translator.actions?.toString() || "";
      // values for check table
      this.valueLocal.push({ ...localField });
      // values for order column
      if (this.isShowable(localField)) {
        this.valueLocalSorted.push(localField);
      }
      // a similar array for group titles
      this.groupFields.splice(idx, 0, localField.groupField);
    });
    // uniques
    this.groupFields = this.groupFields.filter((v, i, a) => a.indexOf(v) === i);
    this.groupTitleFields = this.groupFields;
  }
  changeCheck(field: FieldPTable): void {
    const fieldOrder = this.valueLocalSorted.find((fi) => fi.key == field.key);
    if (fieldOrder) {
      fieldOrder.show = field.show;
      const idx = this.valueLocalSorted.indexOf(fieldOrder);
      this.valueLocalSorted.splice(idx, 1);
    } else {
      this.valueLocalSorted.push({ ...field });
    }
  }
  restore(): void {
    this.updateLocalValues(false);
    this.sortValues();
  }
  selectAll(): void{
    this.valueLocalSorted = [];
    this.groupTitleFields.forEach(
      (group) => this.getFieldsByGroup(group!).forEach(
        (checkfield) => {
          if(!checkfield.fixedOrder) {
            checkfield.show = true;
            this.valueLocalSorted.push({...checkfield})
          }
        })
    );
  }
  deselectAll(): void{
    this.valueLocalSorted = [];
    this.groupTitleFields.forEach(
      (group) => this.getFieldsByGroup(group!).forEach(
        (checkfield) => {
          if(!checkfield.fixedOrder) {
            checkfield.show = checkfield.alwaysShow;
            if(checkfield.show) {
              this.valueLocalSorted.push({...checkfield})
            }
          }
        })
    );
  }
  sortValues():void{
    this.valueLocal.sort((a:FieldPTable, b:FieldPTable) => {
      if(a.show) {
        if(b.show){
          return 0;
        }else{
          return -1
        }
        return 1;
      } else {
        if(b.show){
          return 1
        }else{
          if((a.label || "").toLowerCase() < (b.label || "").toLowerCase()){
            return -1;
          } else {
            return 1;
          }
        }
      };
    });
  }
  apply(): void {
    let valueTemp: FieldPTable[] = [];
    let sortedIdx = 0;
    this.valueLocal
      .filter((fi) => !fi.fixedOrder)
      .forEach((fi) => {
        if (this.isShowable(fi)) {
          valueTemp.push(this.valueLocalSorted[sortedIdx]);
          sortedIdx++;
        } else {
          valueTemp.push(fi);
        }
      });
    this.fixedOrder.forEach((fi) => {
      valueTemp.splice(fi.index, 0, fi.field);
    });
    this.$emit("input", valueTemp);
    this.$emit("change", valueTemp);
  }
}
export default PPersonalizeTable;
