
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import Popper from "popper.js";

@Component
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PTableData extends Vue {
  @Prop({ required: false, default: false }) tooltip!: boolean;
  @Prop({ required: false, default: false }) overflow!: boolean;
  @Ref("data_container") readonly dataContainer!: any;
  @Ref("tooltip_container") readonly tooltipContainer!: any;

  timer: any;
  millisForClose = 250;
  popper: any;
  isOpen = false;

  beforeDestroy(): void {
    if (this.popper) this.popper.destroy();
  }

  mouseOverData(): void {
    if (this.timer) this.keepTooltipOpen();
    const isOverflowing =
      this.dataContainer.offsetHeight < this.dataContainer.scrollHeight ||
      this.dataContainer.offsetWidth < this.dataContainer.scrollWidth;
    if (isOverflowing && this.tooltip) this.open();
  }
  mouseOverTooltip(): void {
    this.keepTooltipOpen();
  }
  mouseLeaveData(): void {
    this.scheduleCloseTooltip();
  }
  mouseLeaveTooltip(): void {
    this.scheduleCloseTooltip();
  }
  scheduleCloseTooltip() {
    this.timer = setTimeout(() => {
      this.isOpen = false;
      if (this.popper) {
        this.popper.destroy();
        this.popper = undefined;
      }
    }, this.millisForClose);
  }
  keepTooltipOpen(): void {
    clearTimeout(this.timer);
    this.timer = undefined;
  }
  setupPopper(): void {
    // this.popper var is not reactive because it is not into data()
    // we use for save the instanciate new Popper once and avoid memory leaks using always this.popper
    if (this.popper === undefined) {
      this.popper = new Popper(this.dataContainer, this.tooltipContainer, {
        placement: "bottom-start",
      });
    } else {
      // recalculate
      this.popper.scheduleUpdate();
    }
  }
  open(): void {
    this.isOpen = true;
    // this.$emit("input", this.isOpen);
    // Wait to nextTick due to searchInput focus() it's not already mounted ( v-show )
    if (this.popper) this.setupPopper();
    this.$nextTick(() => {
      this.setupPopper();
    });
  }
}
export default PTableData;
