
import { Component, Prop, Model, Vue, Watch } from "vue-property-decorator";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PFormSwitch extends Vue {
  @Model("input") readonly checked!: any;
  @Watch("checked")
  onChangeChecked(): void {
    this.isCheckedFun();
  }

  @Prop({ required: false, default: true }) value!: any;
  @Prop({ required: false, default: false }) uncheckedValue!: any;
  @Prop({ required: false, default: false }) readOnly!: boolean;

  public isChecked: boolean;
  public defaultCheked: boolean;

  constructor() {
    super();
    this.isChecked = false;
    this.defaultCheked = false;
  }
  mounted(): void {
    this.isCheckedFun();
  }

  get giveMeClassSwitch(): string {
    let returnTemp = "";
    if (this.isChecked) {
      returnTemp = "checkTrue_PFormSwitch";
    } else {
      returnTemp = "checkFalse_PFormSwitch";
    }
    if (this.readOnly) {
      returnTemp = returnTemp + " readonly_PFormSwitch";
    }
    return returnTemp;
  }
  get giveMeClassReadOnly(): string {
    if (this.readOnly) {
      return "readonly_PFormSwitch";
    } else {
      return "";
    }
  }

  change(): void {
    if (!this.readOnly) {
      this.isChecked = !this.isChecked;
      if (this.isChecked) {
        this.$emit("input", this.value);
        this.$emit("change", this.value);
      } else {
        this.$emit("input", this.uncheckedValue);
        this.$emit("change", this.uncheckedValue);
      }
    }
  }
  isCheckedFun(): void {
    if (this.value === this.checked) {
      this.defaultCheked = true;
      this.isChecked = true;
    } else {
      this.defaultCheked = false;
      this.isChecked = false;
    }
  }
}
export default PFormSwitch;
