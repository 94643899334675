import { RawRequestedSupply, Supply } from "@/orders/entities";

export class RequestedSupply {
  readonly quantity: number;
  readonly supply: Supply;

  constructor(data: RawRequestedSupply) {
    this.quantity = data.quantity;
    this.supply = new Supply(data.supply);
  }
}

export interface CreateRequestedSupply {
  supplyId: string;
  quantity: number;
}
