
import { Component, Vue, Prop } from "vue-property-decorator";
import DateTimeMixin from "@/mixins/datetime";
import { i18n } from "@/i18n";
import { Transaction } from "@/entities";
import { PFormSelectNarrow } from "@/common/components";
import { PRow, PCol } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import LogicCashTodayDetailLastTransactions from "@/pages/logic-cash-today-detail/components/LogicCashTodayDetailLastTransactions.vue";

@Component({
  mixins: [DateTimeMixin],
  components: {
    LogicCashTodayDetailLastTransactions,
    PRow,
    PCol,
    PFormSelectNarrow,
  },
})
export class DetailTransaction extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true, default: {} }) transaction!: Transaction;

  language = i18n.locale;
  options = [
    { text: this.$t("transactions.filter.showAllFields"), value: "showAllFields" },
    { text: this.$t("transactions.filter.dontShowAllFields"), value: "dontShowAllFields" },
  ];
  valueSelect = "";
  origin = this.$t("transactions.transactionOrigin.DECLARED");
  destroyed(): void {
    this.$emit("input", !this.value);
  }
  created() {
    this.getOrigin();
  }
  //TODO this logic should be on the backend
  getOrigin(): void {
    if (this.isOrginDeclared(this.transaction?.denominations[0]?.boxName))
      this.origin = this.$t("transactions.transactionOrigin.DECLARED");
    else this.origin = this.$t("transactions.transactionOrigin.CASH");
  }

  isOrginDeclared(boxName: string): boolean {
    return boxName.toUpperCase() == "DROPSAFE" || boxName.toUpperCase() == "DECLARED";
  }
}
export default DetailTransaction;
