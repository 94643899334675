
import { Component, Vue, Prop } from "vue-property-decorator";
import { PMiniLabel, Panel } from "@/common/components";
import { LogicCashTodayBalance, LogicCashTodayLastTransaction } from "@/entities";
import DateTimeMixin from "@/mixins/datetime";
import { LocalDateTime } from "@js-joda/core";

@Component({
  mixins: [DateTimeMixin],
  components: {
    PMiniLabel,
    Panel,
  },
})
export default class LogicCashTodayDetailLastTransactions extends Vue {
  @Prop() public logicCashTodayBalance!: LogicCashTodayBalance;
  @Prop() public logicCashTodayLastTransactions!: LogicCashTodayLastTransaction[];
  @Prop({ required: true }) logicCashTodayId!: string;

  numberOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    useGrouping: true,
  };

  get info(): string {
    let returnTemp = "";
    if (this.hasData && this.logicCashTodayBalance) {
      if (this.logicCashTodayBalance.lastCertificationDate) {
        const date =
          this.formatDateTimeTodayYerterday(this.logicCashTodayBalance.lastTransactionDate as LocalDateTime) || "";
        returnTemp += this.$t("logicCashTodayDetail.updated") + date;
      }
    } else {
      returnTemp += this.$t("logicCashTodayDetail.noTransactions");
    }
    return returnTemp;
  }

  get hasData(): boolean {
    return this.logicCashTodayLastTransactions.length > 0;
  }
}
