import {
  LogicCashTodayBalance,
  LogicCashTodayBalances,
  RawLogicCashTodayBalance,
  RawLogicCashTodayBalances,
} from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { LogicCashTodayBalanceService } from "./logicCashTodayBalance.types";

export class LogicCashTodayBalanceServiceImpl implements LogicCashTodayBalanceService {
  async fetchLogicCashTodayBalances(): Promise<LogicCashTodayBalances> {
    const { data } = await Vue.$axios.get<RawLogicCashTodayBalances>(
      `${BACKEND_BASE_URL}/api/v1/me/logicCashTodayBalances`
    );
    return new LogicCashTodayBalances(data);
  }
  async fetchLogicCashTodayBalanceById(id: string): Promise<LogicCashTodayBalance> {
    const { data } = await Vue.$axios.get<RawLogicCashTodayBalance>(
      `${BACKEND_BASE_URL}/api/v1/logicCashTodayBalances/${id}`
    );
    return new LogicCashTodayBalance(data);
  }
}
