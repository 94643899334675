import { RawFailureType } from "@/orders/entities";

export class FailureType {
  readonly id: string;
  readonly code: string;
  readonly description: string;

  constructor(data: RawFailureType) {
    this.id = data.id;
    this.code = data.code;
    this.description = data.description;
  }
}
