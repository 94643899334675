import { RawTransactionCosmosDetail } from "./transactionCosmosDetail.types";
import { AmountCosmos } from "@/entities";
import { CurrencyInfo } from "@/entities/transactionCosmos/currencyInfo";
import { DenominationCosmos } from "@/entities/transactionCosmos/denominationCosmos";

export class TransactionCosmosDetail {
  readonly currency: string;
  readonly origin: string;
  readonly isValid: boolean;
  readonly currencyInfo: CurrencyInfo;
  readonly amount: AmountCosmos;
  readonly denominations: DenominationCosmos[];
  constructor(data: RawTransactionCosmosDetail) {
    this.currency = data.currency;
    this.origin = data.origin;
    this.isValid = data.isValid;
    this.currencyInfo = new CurrencyInfo(data.currencyInfo);
    this.amount = new AmountCosmos(data.amount);
    this.denominations = data.denominations.map((value) => new DenominationCosmos(value));
  }
}
