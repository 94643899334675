import { render, staticRenderFns } from "./stepTwo.vue?vue&type=template&id=f754e550&scoped=true&"
import script from "./stepTwo.vue?vue&type=script&lang=ts&"
export * from "./stepTwo.vue?vue&type=script&lang=ts&"
import style0 from "./stepTwo.vue?vue&type=style&index=0&id=f754e550&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f754e550",
  null
  
)

export default component.exports