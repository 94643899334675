import { FilterOperator } from "./operators";

export const BACKEND_BASE_URL = process.env.VUE_APP_SVC_BACKEND_BASE_URL as string;
export const BACKEND_BASE_URL_READS = process.env.VUE_APP_SVC_BACKEND_BASE_URL_READS as string;
export const BACKEND_ORDERS_BASE_URL = process.env.VUE_APP_SVC_BACKEND_BASE_URL_ORDERS as string;
export const BACKEND_ORDERS_WRITES_BASE_URL = process.env.VUE_APP_SVC_BACKEND_BASE_URL_ORDERS_WRITES as string;

export type PageRequest = {
  limit: number;
  offset: number;
  sortFields: OrderByFields[];
  filters: Filter[];
};
export type PageRequest2 = {
  limit: number;
  offset: number;
  sortFields: OrderByFields[];
  filters: Record<string, unknown> | undefined;
};

// export type Pageable = {
//   limit: number;
//   offset: number;
// };

// export type Sort = {
//   sortField: string;
//   sortWay: string;
// };

export type OrderByFields = {
  field: string;
  direction: string;
};

export type Filter = {
  field: string;
  operator: FilterOperator;
  value: string;
};

export type ApiError = {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
};
/*
export type PageRequest = {
  page: number;
  size: number;
  sort?: string;
};

export type Page<T> = {
  content: T[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export type Pageable = {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
};

export type Sort = {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
};


*/
