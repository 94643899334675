import { RawCurrencyInfo } from "@/entities/transactionCosmos/currencyInfo/currencyInfo.types";
import { CurrencyInfo } from "@/entities/transactionCosmos/currencyInfo/currencyInfo";

export const mockRawCurrencyInfo = (): RawCurrencyInfo => {
  return {
    isoCode: "string",
    minorUnits: 0,
    exponent: 0,
  };
};

export const mockCurrencyInfo = (data: RawCurrencyInfo = mockRawCurrencyInfo()): CurrencyInfo => new CurrencyInfo(data);
