import { ServicePoint } from "./servicePoint";
import { RawServicePoint } from "./servicePoint.types";

export const mockRawServicePoints = (): RawServicePoint[] => [
  {
    id: "16375e59-eb1f-31e9-954a-f1b66c924ef5",
    name: "AUTO POSTO DACAR",
    center: {
      id: "a02531f5-da8f-3e5f-b2d0-4548f11e6165",
      code: "0001",
      name: "0001",
      entityId: "8b501981-616c-3d01-9588-e07542397fcd",
    },
    entity: {
      id: "8b501981-616c-3d01-9588-e07542397fcd",
      code: "005938",
      name: "BR002055G - SONDA SUPERMERCADO EXP E IMP",
      imageLink: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/037ee455-66d0-40f9-8269-3eb75f5e0066.png",
      countryCode: "BR",
    },
    centerId: "a02531f5-da8f-3e5f-b2d0-4548f11e6165",
    deviceDetailsId: "9b194bf7-8458-38af-9355-0bc8542af36c",
    entityId: "8b501981-616c-3d01-9588-e07542397fcd",
    isEarlyValue: false,
    deviceDetails: {
      id: "9b194bf7-8458-38af-9355-0bc8542af36c",
      deviceIp: "190.90.90.90",
      currencies: ["BRL"],
      deviceCode: "005938000170",
      deviceName: "DEVICE - AUTO POSTO DACAR",
      devicePort: 8080,
      deviceType: "------",
      countryCode: "BR",
      deviceModel: "SDM100",
      solutionType: "------",
      delegationCode: "85",
      maxCoinCapacity: 0,
      maxNoteCapacity: 4000,
      deviceManufacturer: "CIMA",
    },
    servicePointCode: "",
  },
];

export const mockServicePoints = (data: RawServicePoint[] = mockRawServicePoints()): ServicePoint[] =>
  data.map((item) => new ServicePoint(item));
