import { LogicCashTodayBalances } from "./serviceLogicCashTodayBalance";
import { RawLogicCashTodayBalances } from "./serviceLogicCashTodayBalance.types";

export const mockRawLogicCashTodayBalances = (): RawLogicCashTodayBalances => {
  return {
    logicCashTodayBalances: [
      {
        id: "8155dcca-5f3b-4539-96f5-8def62242048",
        logicCashTodayId: "cddebedf-6ada-484e-88db-7fbebd440330",
        logicCashTodayName: "ZARA Lanzarote Man",
        servicePointId: "cddebedf-6ada-484e-88db-7fbebd440330",
        servicePointName: "ZARA Lanzarote",
        cashTodayType: "Acceptor",
        centerName: "Tienda Santa Cruz de Tenerife",
        maxNoteCapacity: 1000,
        maxCoinCapacity: 1000,
        deviceFillPercentage: 83,
        capacityEnabled: true,
        deviceType: "Multicenter",
        isEarlyValue: false,
        lastCertificationDate: "2021-12-09T14:41:52.472",
        lastTransactionDate: "2021-12-09T14:41:52.472",
        solutionType: "Depósito fácil",
        balances: [
          {
            id: "8155dcca-5f3b-4539-96f5-8def62242548",
            noteCapacity: 750,
            coinCapacity: 900,
            currency: "EUR",
            todayDepositAmount: 3501.99,
          },
          {
            id: "8155dcca-5f3b-4539-96f5-8def62242549",
            noteCapacity: 54,
            coinCapacity: 840,
            currency: "USD",
            todayDepositAmount: 3471.79,
          },
        ],
      },
    ],
  };
};

export const mockServiceLogicCashTodayBalances = (
  data: RawLogicCashTodayBalances = mockRawLogicCashTodayBalances()
): LogicCashTodayBalances => new LogicCashTodayBalances(data);
