declare global {
  interface Window {
    debounce: number;
  }
}

export const customDebounce = (func: () => void, time: number): void => {
  if (typeof window.debounce !== "undefined") {
    clearTimeout(window.debounce);
  }
  window.debounce = setTimeout(
    () => {
      func();
    },
    time,
    null
  );
};
