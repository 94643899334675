import { RawFieldPTable } from "@/common/components";
import { RawUserPreferences } from "@/entities";

export class UserPreferences {
  transactionsPage: {
    fields: RawFieldPTable[];
    pagination: { pageSize: number };
  };
  constructor(data: RawUserPreferences) {
    this.transactionsPage = data.transactionsPage;
    if (!this.transactionsPage) {
      this.transactionsPage = {
        fields: [],
        pagination: {
          pageSize: 0,
        },
      };
    }
  }
}
