import { RawEntity } from "@/orders/entities/entity/entity.types";

export class Entity {
  id: string;
  code: string;
  name: string;
  imageLink: string;

  constructor(data: RawEntity) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.imageLink = data.imageLink;
  }
}
