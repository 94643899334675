
import { Component, Vue, Prop } from "vue-property-decorator";
import { DeviceDetails } from "@/entities";
import { Panel } from "@/common/components";

@Component({
  components: {
    Panel,
  },
})
export default class ServicePointDetailDeviceDetails extends Vue {
  @Prop() public deviceDetails!: DeviceDetails;

  getCountryName(countryCode: string): string {
    const translationSearch = "country." + countryCode.toLowerCase();
    const countryName = this.$t(translationSearch).toString();
    return countryName !== translationSearch ? countryName : countryCode;
  }
}
