
import { Component, Ref, Vue, Prop, Watch } from "vue-property-decorator";
import Popper from "popper.js";

@Component
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PDropDownContainer extends Vue {
  @Prop({ required: false, default: false }) fullWidth!: boolean;
  @Prop({ required: false, default: false }) value!: boolean;
  @Watch("value", { immediate: true })
  onChangeShow(): void {
    this.isOpen = this.value;
  }
  @Prop({ required: false, default: "bottom-start" }) readonly placement!: any;
  @Ref("button") readonly button!: any;
  @Ref("dropdown") readonly dropdown!: any;

  popper: any = undefined;
  isOpen = false;

  beforeDestroy(): void {
    if (this.popper) this.popper.destroy();
  }
  mouseleave(): void {
    this.isOpen = false;
    this.$emit("input", this.isOpen);
    if (this.popper) {
      this.popper.destroy();
      this.popper = undefined;
    }
  }
  setupPopper(): void {
    // this.popper var is not reactive because it is not into data()
    // we use for save the instanciate new Popper once and avoid memory leaks using always this.popper
    if (this.popper === undefined) {
      this.popper = new Popper(this.button, this.dropdown, {
        placement: this.getPlacement(),
      });
    } else {
      // recalculate
      this.popper.scheduleUpdate();
    }
  }
  open(): void {
    this.isOpen = true;
    this.$emit("input", this.isOpen);
    // Wait to nextTick due to searchInput focus() it's not already mounted ( v-show )
    if (this.popper) this.setupPopper();
    this.$nextTick(() => {
      this.setupPopper();
    });
  }
  getOptionsClass(): string {
    if (this.fullWidth) return "optionsSelect_PDropDownContainer_fullWidth";
    return "optionsSelect_PDropDownContainer";
  }
  getPlacement(): Popper.Placement {
    if (this.isPlacement(this.placement)) {
      return this.placement;
    }
    return "bottom-start";
  }
  isPlacement(value: any): value is Popper.Placement {
    return (
      typeof value === "string" &&
      (value === "auto-start" ||
        value === "auto" ||
        value === "auto-end" ||
        value === "top-start" ||
        value === "top" ||
        value === "top-end" ||
        value === "right-start" ||
        value === "right" ||
        value === "right-end" ||
        value === "bottom-end" ||
        value === "bottom" ||
        value === "bottom-start" ||
        value === "left-end" ||
        value === "left" ||
        value === "left-start")
    );
  }
}

export default PDropDownContainer;
