import { OrderServiceMock } from "@/orders";

export const mockOrderService = (): OrderServiceMock => ({
  fetchOrders: jest.fn(),
  fetchOrderCurrencies: jest.fn(),
  fetchSupplies: jest.fn(),
  saveOrderCreateAssistance: jest.fn(),
  saveOrderCreateSupply: jest.fn(),
  saveOrderCreatePickup: jest.fn(),
  saveOrderCreateDelivery: jest.fn(),
  saveOrderCreateDeliveryChange: jest.fn(),
  fetchOrderDenominations: jest.fn(),
  fetchOrderDenominationPacks: jest.fn(),
  fetchFrequencies: jest.fn(),
  fetchCancellationReasons: jest.fn(),
  patchOrderCancellation: jest.fn(),
  patchOrderAssistance: jest.fn(),
  patchOrderSupply: jest.fn(),
  patchOrderPickup: jest.fn(),
  patchOrderDelivery: jest.fn(),
  patchOrderDeliveryChange: jest.fn(),
});
