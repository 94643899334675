import { RawEntity } from "@/orders/entities/entity/entity.types";
import { Entity } from "@/orders/entities/entity/entity";

export const mockRawEntities = (): RawEntity[] => [
  {
    id: "1",
    code: "1",
    name: "Entity1",
    imageLink: "",
  },
  {
    id: "2",
    code: "2",
    name: "Entity2",
    imageLink: "",
  },
];

export const mockEntities = (data: RawEntity[] = mockRawEntities()): Entity[] => data.map((item) => new Entity(item));
