
import Icon from "@/orders/components/Icon/index.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    Icon,
  },
})
export class NavigationWeek extends Vue {
  @Prop({ required: true }) value!: Date;
  @Prop({ required: true }) type!: "day" | "week" | "month";
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = new Date(this.value.getTime());
  }

  valueLocal = new Date();
  calendar = require("@/orders/assets/icons/icon-calendar.svg");
  left = require("@/orders/assets/icons/icon-chevron-left-calendar.svg");
  right = require("@/orders/assets/icons/icon-chevron-right-calendar.svg");

  previousWeek(): void {
    if (this.type === "week") {
      this.valueLocal.setDate(this.valueLocal.getDate() - 7);
    } else if (this.type === "day") {
      this.valueLocal.setDate(this.valueLocal.getDate() - 1);
    } else if (this.type === "month") {
      this.valueLocal.setMonth(this.valueLocal.getMonth() - 1);
    }
    this.$emit("input", this.valueLocal);
  }

  nextWeek(): void {
    if (this.type === "week") {
      this.valueLocal.setDate(this.valueLocal.getDate() + 7);
    } else if (this.type === "day") {
      this.valueLocal.setDate(this.valueLocal.getDate() + 1);
    } else if (this.type === "month") {
      this.valueLocal.setMonth(this.valueLocal.getMonth() + 1);
    }
    this.$emit("input", this.valueLocal);
  }

  actualWeek(): void {
    this.valueLocal = new Date();
    this.$emit("input", this.valueLocal);
  }

  get actualMonth(): string {
    return this.valueLocal.toLocaleDateString("es-ES", { month: "long" });
  }
  get actualYear(): string {
    return this.valueLocal.getFullYear() + "";
  }
}
export default NavigationWeek;
