
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export class WeekConfig extends Vue {
  @Prop({ required: true, default: "week" }) view!: "day" | "week" | "month";
  @Prop({ required: true }) value!: {
    startDay: string;
    sortBy: string;
  };
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.daySelected = this.value.startDay;
    this.ordered = this.value.sortBy;
  }
  daySelected = "sunday";
  dayExpand = false;
  ordered = "code";

  @Watch("daySelected", { immediate: true, deep: true })
  onChangeDaySelected(): void {
    localStorage.configWeek = JSON.stringify({ startDay: this.daySelected, sortBy: this.ordered });
    this.$emit("input", { startDay: this.daySelected, sortBy: this.ordered });
  }
  @Watch("ordered", { immediate: true, deep: true })
  onChangeOrdered(): void {
    localStorage.configWeek = JSON.stringify({ startDay: this.daySelected, sortBy: this.ordered });
    this.$emit("input", { startDay: this.daySelected, sortBy: this.ordered });
  }

  closeModal(): void {
    this.$emit("closeModal");
  }
}
export default WeekConfig;
