
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import { ServicePoint } from "@/orders/entities";
import Dropdown from "@/orders/components/Dropdown/Dropdown.vue";
import { i18n } from "@/i18n";

@Component({
  components: {
    Icon,
    Dropdown,
  },
})
export default class StepOne extends Vue {
  // Data
  @Prop({ required: true }) servicePointsOrders!: ServicePoint[];
  @Prop({ required: false }) selectedDefault!: ServicePoint;
  iconSearch: string = require("@/orders/assets/icons/icon-search.svg");
  filteredServicePoints: ServicePoint[] = [];
  searchServicePoint = "";
  selectedPoint = {} as ServicePoint;
  typeServicePointSelect = i18n.t("orders.creation.typeSelected") as string;
  typeServicePoints: string[] = [];

  extractUniqueTypes(items: ServicePoint[]): string[] {
    const uniqueTypesSet: Set<string> = new Set();
    items.forEach((item: ServicePoint) => {
      uniqueTypesSet.add(item.type);
    });
    return Array.from(uniqueTypesSet);
  }

  nextPage(): void {
    if (this.enabledNext()) {
      this.$emit("selectPoint", this.selectedPoint);
    }
  }

  changeFilterServicePoints(type: string): void {
    this.filteredServicePoints = this.servicePointsOrders.filter(
      (servicePoint: ServicePoint) => servicePoint.type === type
    );
    if (this.searchServicePoint) {
      this.searchServicePoints();
    }
  }

  enabledNext(): boolean {
    return this.selectedPoint.id != null;
  }

  searchServicePoints(): void {
    this.filteredServicePoints = this.servicePointsOrders.filter((servicePoint) => {
      const searchTermLowerCase: string = this.searchServicePoint.toLowerCase();
      const filterServicePoint =
        servicePoint.name.toLowerCase().includes(searchTermLowerCase) ||
        servicePoint.code.toLowerCase().includes(searchTermLowerCase);
      if (this.typeServicePoints.includes(this.typeServicePointSelect)) {
        return filterServicePoint && servicePoint.type == this.typeServicePointSelect;
      } else {
        return filterServicePoint;
      }
    });
  }

  selectPoint(item: ServicePoint): void {
    this.selectedPoint = item;
  }

  created() {
    this.filteredServicePoints = this.servicePointsOrders;
    this.typeServicePoints = this.extractUniqueTypes(this.servicePointsOrders);
    if (this.selectedDefault) {
      this.selectPoint(this.selectedDefault);
    }
  }
}
