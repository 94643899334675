export interface PFormSelectTranslations {
  [key: string]: string;
  textSelectAnOption: string;
  textAll: string;
  textNoFilter: string;
  textMore: string;
  textButtonClear: string;
  textButtonApply: string;
  textFilterOn: string;
  textSelectWhatFilterBy: string;
}
export interface LanguageDatepicker {
  language: string;
  translations: PFormSelectTranslations;
}
export const translations: LanguageDatepicker[] = [
  {
    language: "es",
    translations: {
      textSelectAnOption: "Seleccione una opción",
      textAll: "Todos",
      textNoFilter: "Sin filtros",
      textMore: "más",
      textButtonClear: "Limpiar",
      textButtonApply: "Aplicar",
      textFilterOn: "Buscar por",
      textSelectWhatFilterBy: "Seleccione porqué buscar",
    },
  },
  {
    language: "en",
    translations: {
      textSelectAnOption: "Select an option",
      textAll: "All",
      textNoFilter: "No filters",
      textMore: "more",
      textButtonClear: "Clear",
      textButtonApply: "Apply",
      textFilterOn: "Search by",
      textSelectWhatFilterBy: "Select what to search by",
    },
  },
  {
    language: "pt",
    translations: {
      textSelectAnOption: "Selecione uma opção",
      textAll: "Tudo",
      textNoFilter: "Sem filtros",
      textMore: "mais",
      textButtonClear: "Limpar",
      textButtonApply: "Aplicar",
      textFilterOn: "Procurar por",
      textSelectWhatFilterBy: "selecione o que pesquisar",
    },
  },
  {
    language: "de",
    translations: {
      textSelectAnOption: "Wähle eine Option",
      textAll: "Alles",
      textNoFilter: "Ohne Filter",
      textMore: "plus",
      textButtonClear: "Löschen",
      textButtonApply: "Anwenden",
      textFilterOn: "Suche nach",
      textSelectWhatFilterBy: "Wählen Sie aus, wonach gesucht werden soll",
    },
  },
];
