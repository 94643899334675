import { ServicePointCosmos } from "./servicePointCosmos";
import { RawServicePointCosmos } from "./servicePointCosmos.types";

export const mockRawServicePointCosmos = (): RawServicePointCosmos => {
  return {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "string",
    code: "string",
    isEarlyValue: true,
    deviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    centerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  };
};

export const mockServicePointCosmos = (data: RawServicePointCosmos = mockRawServicePointCosmos()): ServicePointCosmos =>
  new ServicePointCosmos(data);
