
import Icon from "@/orders/components/Icon/index.vue";
import ServicePoints from "./ServicePoints.vue";
import Centers from "./Centers.vue";
import Entities from "./Entities.vue";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Center, Entity, ServicePoint } from "@/orders";
import { FilterOrder, FilterOrderTab } from "@/orders/entities/filterOrder";
import { Getter } from "vuex-class";

@Component({
  components: {
    Icon,
    ServicePoints,
    Centers,
    Entities,
  },
})
export class FilterOrders extends Vue {
  @Getter("getServicePointsOrders") getServicePoints!: ServicePoint[];
  @Getter("getCenters") getCenters!: Center[];
  @Getter("getEntities") getEntities!: Entity[];

  @Prop({ required: true }) value!: FilterOrder;

  eyes = require("@/orders/assets/icons/icon-eye-off.svg");
  buttonDisabled = true;
  valueLocal: FilterOrder = { servicePoints: [], centers: [], entities: [] };
  filterOrdersByPosition: FilterOrder = { servicePoints: [], centers: [], entities: [] };
  tabPosition: FilterOrderTab = FilterOrderTab.SERVICE_POINTS;

  created(): void {
    const servicePointsLength = this.valueLocal.servicePoints.length | 0;
    const centersLength = this.valueLocal.centers.length | 0;
    const entitiesLength = this.valueLocal.entities.length | 0;

    if (servicePointsLength > 0) {
      this.tabPosition = FilterOrderTab.SERVICE_POINTS;
      this.filterOrdersByPosition.servicePoints = [...this.valueLocal.servicePoints];
    } else {
      this.filterOrdersByPosition.servicePoints = [...this.getServicePoints];
    }

    if (centersLength > 0) {
      this.tabPosition = FilterOrderTab.CENTERS;
      this.filterOrdersByPosition.centers = [...this.valueLocal.centers];
    } else {
      this.filterOrdersByPosition.centers = [...this.getCenters];
    }

    if (entitiesLength > 0) {
      this.tabPosition = FilterOrderTab.ENTITIES;
      this.filterOrdersByPosition.entities = [...this.valueLocal.entities];
    } else {
      this.filterOrdersByPosition.entities = [...this.getEntities];
    }
  }

  get filterOrderTabs() {
    return FilterOrderTab;
  }

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Watch("tabPosition")
  onChangeTabPosition(_newVal: number, oldVal: number) {
    const filteredServicePointsByServicePoints = this.getServicePoints.filter((servicePoint) =>
      this.filterOrdersByPosition.servicePoints.map((servicePoint) => servicePoint.id).includes(servicePoint.id)
    );

    const filteredServicePointsByEntities = this.getServicePoints.filter((servicePoint) =>
      this.filterOrdersByPosition.entities.map((entity) => entity.code).includes(servicePoint.entity.code)
    );

    const filteredServicePointsByCenters = this.getServicePoints.filter((servicePoint) =>
      this.filterOrdersByPosition.centers.map((center) => center.code).includes(servicePoint.center.code)
    );

    switch (this.tabPosition) {
      case FilterOrderTab.SERVICE_POINTS: {
        switch (oldVal) {
          case FilterOrderTab.CENTERS: {
            this.filterOrdersByPosition.servicePoints = [...filteredServicePointsByCenters];
            this.filterOrdersByPosition.entities = [
              ...new Map(
                filteredServicePointsByCenters.map((value) => value.entity).map((item) => [item["code"], item])
              ).values(),
            ];
            break;
          }
          case FilterOrderTab.ENTITIES: {
            this.filterOrdersByPosition.servicePoints = [...filteredServicePointsByEntities];
            this.filterOrdersByPosition.centers = [
              ...new Map(
                filteredServicePointsByEntities.map((value) => value.center).map((item) => [item["code"], item])
              ).values(),
            ];
            break;
          }
        }

        break;
      }
      case FilterOrderTab.CENTERS: {
        switch (oldVal) {
          case FilterOrderTab.SERVICE_POINTS: {
            this.filterOrdersByPosition.centers = [
              ...new Map(
                filteredServicePointsByServicePoints.map((value) => value.center).map((item) => [item["code"], item])
              ).values(),
            ];
            this.filterOrdersByPosition.entities = [
              ...new Map(
                filteredServicePointsByServicePoints.map((value) => value.entity).map((item) => [item["code"], item])
              ).values(),
            ];

            break;
          }
          case FilterOrderTab.ENTITIES: {
            this.filterOrdersByPosition.servicePoints = [...filteredServicePointsByEntities];
            this.filterOrdersByPosition.centers = [
              ...new Map(
                filteredServicePointsByEntities.map((value) => value.center).map((item) => [item["code"], item])
              ).values(),
            ];
            break;
          }
        }
        break;
      }
      case FilterOrderTab.ENTITIES: {
        switch (oldVal) {
          case FilterOrderTab.SERVICE_POINTS: {
            this.filterOrdersByPosition.centers = [
              ...new Map(
                filteredServicePointsByServicePoints.map((value) => value.center).map((item) => [item["code"], item])
              ).values(),
            ];
            this.filterOrdersByPosition.entities = [
              ...new Map(
                filteredServicePointsByServicePoints.map((value) => value.entity).map((item) => [item["code"], item])
              ).values(),
            ];
            break;
          }
          case FilterOrderTab.CENTERS: {
            this.filterOrdersByPosition.servicePoints = [...filteredServicePointsByCenters];
            this.filterOrdersByPosition.entities = [
              ...new Map(
                filteredServicePointsByCenters.map((value) => value.entity).map((item) => [item["code"], item])
              ).values(),
            ];
            break;
          }
        }
        break;
      }
      default:
        console.error("tabPosition not supported");
    }
  }

  @Watch("filterOrdersByPosition.servicePoints")
  onChangeFilteredPoints() {
    const filteredServicePoints: ServicePoint[] = this.filterOrdersByPosition.servicePoints;
    this.buttonDisabled = filteredServicePoints.length == 0;
    this.valueLocal = {
      servicePoints: [...filteredServicePoints],
      centers: [],
      entities: [],
    } as FilterOrder;
  }

  @Watch("filterOrdersByPosition.centers")
  onChangeFilteredCenters() {
    const filteredCenters: Center[] = this.filterOrdersByPosition.centers;
    this.buttonDisabled = filteredCenters.length == 0;
    this.valueLocal = {
      servicePoints: [],
      centers: [...filteredCenters],
      entities: [],
    } as FilterOrder;
  }

  @Watch("filterOrdersByPosition.entities")
  onChangeFilteredEntities() {
    const filteredEntities: Entity[] = this.filterOrdersByPosition.entities;
    this.buttonDisabled = filteredEntities.length == 0;
    this.valueLocal = {
      servicePoints: [],
      centers: [],
      entities: [...filteredEntities],
    } as FilterOrder;
  }

  filterOrders(): void {
    this.$emit("input", this.valueLocal);
    localStorage.setItem("filteredOrders", JSON.stringify(this.valueLocal));
    this.closeModal();
  }

  closeModal() {
    this.$emit("closeModal");
  }
}
export default FilterOrders;
