
import { Component, Vue } from "vue-property-decorator";
import { PButton } from "@/common/components";

@Component({
  name: "button-search",
  components: {
    PButton,
  },
})
export class ButtonSearch extends Vue {
  constructor() {
    super();
  }
}
export default ButtonSearch;
