import format from "date-fns/format";
import { enUS, es } from "date-fns/locale";
import { Component, Vue } from "vue-property-decorator";
import { i18n } from "./i18n";

declare module "vue/types/vue" {
  interface Vue {
    filterDate(dateStr: string): string;
    filterTime(dateStr: string): string;
  }
}

@Component({
  name: "vue-filters",
})
export default class VueFilters extends Vue {
  public filterDate(dateStr: string): string {
    let locale = enUS;

    if (i18n.locale === "es") {
      locale = es;
    }

    return dateStr ? format(new Date(dateStr), "dd/MM/yyyy", { locale }) : "";
  }

  public filterTime(dateStr: string): string {
    let locale = enUS;

    if (i18n.locale === "es") {
      locale = es;
    }

    return dateStr ? format(new Date(dateStr), "HH:mm", { locale }) : "";
  }
}
