import { RawRole } from "@/entities";

export class Role {
  readonly id?: number;
  readonly code?: string;
  readonly externalCode?: string;
  readonly module?: string;

  constructor(data: RawRole) {
    this.id = data.id;
    this.code = data.code;
    this.externalCode = data.externalCode;
    this.module = data.module;
  }
}
