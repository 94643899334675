
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "callback_page",
  components: {},
})
export default class CallbackPage extends Vue {
  @Getter("getUrl") currentLocation!: string;
  hasError = false;
  accessDenied = false;

  constructor() {
    super();
  }

  get currentPath(): string {
    return new URL(this.currentLocation).pathname;
  }

  mounted(): void {
    Vue.$auth
      .signinRedirectCallback()
      .then(() => {
        if (this.currentLocation) {
          this.$router.push({
            path: this.currentPath,
          });
        } else {
          this.$router.push({
            name: "dashboard",
          });
        }
      })
      .catch(() => {
        if (this.$route.query.error === "access_denied") {
          this.accessDenied = true;
        } else {
          this.hasError = true;
        }
      });
  }

  silentLogout(): void {
    Vue.$auth.signoutRedirect();
  }
}
